import React from "react";
import { AuthWrapper } from "../components/_common/AuthWrapper";
import { Theme, useMediaQuery } from "@mui/material";
import { PasswordResetEnterEmailStep } from "../components/auth/PasswordResetEnterEmailStep";
import { PasswordResetNewPasswordStep } from "../components/auth/PasswordResetNewPasswordStep";
import { PasswordResetDoneStep } from "../components/auth/PasswordResetDoneStep";
import { PasswordResetEmailConfirmStep } from "../components/auth/PasswordResetEmailConfirmStep";

export enum ForgotPasswordStep {
  enterEmail = "enterEmail",
  confirmEmail = "confirmEmail",
  newPassword = "newPassword",
  done = "done",
}

export const ForgotPassword = () => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [currentStep, setCurrentStep] = React.useState<ForgotPasswordStep>(
    ForgotPasswordStep.enterEmail
  );
  const [email, setEmail] = React.useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");

  React.useEffect(() => {
    if (code) {
      setCurrentStep(ForgotPasswordStep.newPassword);
    }
  }, []);

  return (
    <AuthWrapper>
      <div
        className="forgot-p-section"
        style={{
          height: downMd ? "100vh" : "",
        }}
      >
        {currentStep === ForgotPasswordStep.enterEmail && (
          <PasswordResetEnterEmailStep
            onChangeStep={() => setCurrentStep(ForgotPasswordStep.confirmEmail)}
            email={email}
            setEmail={setEmail}
          />
        )}

        {currentStep === ForgotPasswordStep.confirmEmail && (
          <PasswordResetEmailConfirmStep email={email} />
        )}

        {currentStep === ForgotPasswordStep.newPassword && (
          <PasswordResetNewPasswordStep
            onChangeStep={() => setCurrentStep(ForgotPasswordStep.done)}
          />
        )}

        {currentStep === ForgotPasswordStep.done && (
          <PasswordResetDoneStep
            // TODO ?
            onChangeStep={() => setCurrentStep(ForgotPasswordStep.confirmEmail)}
          />
        )}

        <div className="step-indicator">
          {Object.values(ForgotPasswordStep).map((step, index) => (
            <div
              key={step}
              className={`step ${currentStep === step ? "active" : ""}`}
              style={{
                width: currentStep === step ? "68px" : "40px",
                height: "5px",
                backgroundColor:
                  currentStep === step
                    ? "rgba(3, 74, 115, 1)"
                    : "rgba(3, 74, 115, 0.5)",
                borderRadius: "5px",
              }}
            />
          ))}
        </div>
      </div>
    </AuthWrapper>
  );
};
