import React from "react";
import { useAppDispatch } from "../../store/store";
import CloseIcon from "../../assets/icons/builderClose.svg";
import { setCampaingBuilderSideBarClose } from "../../store/features/common";

export const CampaignBuilderSideNavCloseBtn = () => {
  const dispatch = useAppDispatch();

  return (
    <div
      onClick={() => dispatch(setCampaingBuilderSideBarClose())}
      className="campaign-builder-sidenav-close"
    >
      <img src={CloseIcon} />
    </div>
  );
};
