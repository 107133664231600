export const IconSelect = ({
  selectedIcon,
  setSelectedIcon,
  options,
  iconMap,
}: {
  selectedIcon: any;
  setSelectedIcon: (icon: any) => void;
  options: any;
  iconMap: any;
}) => {
  return (
    <div className="campaign-builder-side-nav-icon-select">
      {options.map((option: any) => {
        const IconComponent = iconMap[option.icon_key];

        return (
          <div
            key={option.labelName}
            onClick={() => setSelectedIcon(option.icon_key)}
            style={{
              cursor: "pointer",
            }}
          >
            <IconComponent
              isSelected={selectedIcon === option.icon_key}
              width={32}
              height={32}
              backgroundColor={option.backgroundColor}
              iconSelect={true}
            />
          </div>
        );
      })}
    </div>
  );
};
