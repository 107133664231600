import {
  InputAdornment,
  Popover,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SearchIconBlue from "../../assets/icons/search_icon_blue.svg";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchTab } from "../portfolio/PortfolioFilterUpMd";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

interface AskAIPopoverProps {
  styles: {
    backgroundColor: string;
    textFieldBackgroundColor: string;
    hintBorderColor: string;
    hintSelectedBorderColor: string;
    hintTextColor: string;
    width: string;
    wrapperWidth: string;
  };
  searchTab: string;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  onSearchChange: (search: string) => void;
}
export const AskAIPopoverBase: React.FC<AskAIPopoverProps> = ({
  styles,
  searchTab,
  searchValue,
  setSearchValue,
  onSearchChange,
}) => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();

  const [selectedHints, setSelectedHints] = useState<string[]>([]);

  const hints = [
    t("apartments_in_tel_aviv"),
    t("sea_view"),
    t("villas_with_garden"),
  ];

  const handleHintClick = (hint: string) => {
    setSearchValue(hint);

    setSelectedHints([hint]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);

    const newSelectedHints = selectedHints.filter((hint) =>
      newValue.includes(hint)
    );
    setSelectedHints(newSelectedHints);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const inputValue = (e.target as HTMLInputElement).value;
      onSearchChange(inputValue);
    }
  };

  return (
    <div
      className="ask-ai-popover-container"
      style={{
        display: searchTab === SearchTab.search ? "flex" : "none",
        maxWidth: styles.width,
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: styles.backgroundColor,
          padding: "12px 20px 13px 20px",
          borderRadius: "20px",
          width: styles.wrapperWidth,
        }}
      >
        <TextField
          fullWidth
          value={searchValue}
          onChange={handleInputChange}
          placeholder={t("ask_ai_placeholder")}
          onKeyDown={handleKeyDown}
          inputProps={{
            maxLength: 100,
          }}
          sx={{
            backgroundColor: styles.textFieldBackgroundColor,
            borderRadius: "10px",
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(0, 0, 0, 0.33)",
              opacity: 1,
              fontFamily: isRTL ? "Alef" : "DM Sans",
              fontWeight: isRTL ? 400 : 500,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() => onSearchChange(searchValue)}
                sx={{
                  backgroundColor: styles.textFieldBackgroundColor,
                  height: "100%",
                  ml: 0,
                  maxHeight: "none",
                  borderRadius: "0 4px 4px 0",
                  marginRight: "-14px",
                  pl: "23px",
                  pr: "23px",
                  cursor: "pointer",
                }}
                position="end"
              >
                <img src={SearchIconBlue} alt="search" />
              </InputAdornment>
            ),
          }}
        />

        <div
          className="ask-ai-hints-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            marginTop: "12px",
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {hints.map((hint) => (
            <Typography
              key={hint}
              onClick={() => handleHintClick(hint)}
              sx={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                padding: "8px 12px",
                borderRadius: "20px",
                cursor: "pointer",
                border: selectedHints.includes(hint)
                  ? `1px solid ${styles.hintSelectedBorderColor}`
                  : `1px solid ${styles.hintBorderColor}`,
                color: styles.hintTextColor,
                fontWeight: 500,
                "&:hover": {
                  border: `1px solid ${styles.hintSelectedBorderColor}`,
                },
              }}
            >
              {hint}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
};

export const AskAIPopoverWhite = (props: Omit<AskAIPopoverProps, "styles">) => (
  <AskAIPopoverBase
    {...props}
    styles={{
      backgroundColor: "rgba(255, 255, 255, 0.82)",
      textFieldBackgroundColor: "rgba(255, 255, 255, 1)",
      hintBorderColor: "rgba(184, 194, 198, 1)",
      hintSelectedBorderColor: "#6f8087",
      hintTextColor: "rgba(0, 0, 0, 1)",
      width: "870px",
      wrapperWidth: "100%",
    }}
  />
);

export const AskAIPopoverBlack = (props: Omit<AskAIPopoverProps, "styles">) => (
  <AskAIPopoverBase
    {...props}
    styles={{
      backgroundColor: "#000000D6",
      textFieldBackgroundColor: "rgba(232, 239, 242, 1)",
      hintBorderColor: "#CBCBCB80",
      hintSelectedBorderColor: "#fff",
      hintTextColor: "#fff",
      width: "754px",
      wrapperWidth: "754px",
    }}
  />
);
