import React from "react";
import AddMediaIcon from "../../assets/campaignBuilder/add_media.svg";
import { LabelStampComponent } from "./LabelStampComponent";
import { useSelector } from "react-redux";
import { MediaUploadPanel } from "./MediaUploadPanel";
import { PreviewMode } from "./PreviewModeSwiter";
import { Theme, useMediaQuery } from "@mui/material";

export const CampaignBuilderMediaSection = ({
  imgFiles,
  floorplanFiles,
  brochureFiles,
  videoFiles,
}: {
  imgFiles: any[];
  floorplanFiles: any;
  brochureFiles: any;
  videoFiles: any;
}) => {
  const { labelBack } = useSelector((state: any) => state.campaignBuilder);
  const { mainLabelOpen, previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div className="campaign-builder-media-wrapper">
      <div
        className="campaign-builder-media-main-container"
        style={{
          minHeight:
            previewMode === PreviewMode.MOBILE || downMd ? "325px" : "700px",
        }}
      >
        {mainLabelOpen && <LabelStampComponent option={labelBack} />}

        {previewMode === PreviewMode.MOBILE || downMd ? (
          imgFiles[0]?.preview ? (
            <div
              style={{
                background: `url(${imgFiles[0]?.preview}) center center / cover no-repeat`,
              }}
              className="campaign-builder-media-img-one"
            ></div>
          ) : (
            <div className="campaign-builder-media-img-one">
              <img src={AddMediaIcon} alt="Add Media" />
            </div>
          )
        ) : (
          <>
            {imgFiles[0]?.preview ? (
              <div
                style={{
                  background: `url(${imgFiles[0]?.preview}) center center / cover no-repeat`,
                }}
                className="campaign-builder-media-img-left"
              ></div>
            ) : (
              <div className="campaign-builder-media-img-left">
                <img src={AddMediaIcon} alt="Add Media" />
              </div>
            )}

            <div className="campaign-builder-media-img-right">
              {imgFiles[1]?.preview ? (
                <div
                  style={{
                    background: `url(${imgFiles[1]?.preview}) center center / cover no-repeat`,
                    borderTopRightRadius: "20px 20px",
                  }}
                  className="campaign-builder-media-img-item"
                ></div>
              ) : (
                <div
                  className="campaign-builder-media-img-item"
                  style={{
                    borderTopRightRadius: "20px 20px",
                  }}
                >
                  <img src={AddMediaIcon} alt="Add Media" />
                </div>
              )}

              {imgFiles[2]?.preview ? (
                <div
                  style={{
                    background: `url(${imgFiles[2]?.preview}) center center / cover no-repeat`,
                    borderBottomRightRadius: "20px 20px",
                  }}
                  className="campaign-builder-media-img-item"
                ></div>
              ) : (
                <div
                  className="campaign-builder-media-img-item"
                  style={{
                    borderBottomRightRadius: "20px 20px",
                  }}
                >
                  <img src={AddMediaIcon} alt="Add Media" />
                </div>
              )}
            </div>
          </>
        )}

        <MediaUploadPanel
          imgFiles={imgFiles}
          floorplanFiles={floorplanFiles}
          brochureFiles={brochureFiles}
          videoFiles={videoFiles}
        />
      </div>
    </div>
  );
};
