import React from "react";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import { CircularProgress, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDropZone } from "./FileDropZone";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { ExtractedMediaData } from "../../helpers/campaignConvert";
import ReplaceBlack from "../../assets/campaignBuilder/replace.svg";
import { truncateString } from "../../helpers/formatter";
import {
  removeBrochureFileById,
  removeImgFileById,
} from "../../store/features/campaignBuilderSlice";
import Paperclips from "../../assets/campaignBuilder/paperclips.svg";
import ReplaceCrossWhite from "../../assets/campaignBuilder/white_replace_cross.svg";
import { FilesLoader } from "./FilesLoader";

export type ImageFormatMap = {
  [key: string]: string[];
};
export const propertyImgAcceptFormat = {
  "image/png": [],
  "image/jpeg": [],
};

const brochureAcceptFormat = {
  "application/pdf": [],
  "image/png": [],
  "image/jpeg": [],
};

export const videoAcceptFormat = {
  "video/mp4": [],
  "video/quicktime": [],
};

export const MAXIMUM_NUMBER_OF_PROPERTY_IMG = 10;
export const MAXIMUM_NUMBER_OF_BROCHURE = 1;
export const MAXIMUM_NUMBER_OF_FLOOR_PLAN = 1;
export const MAXIMUM_NUMBER_OF_AGENT_AVATAR = 1;

export const CampaignMediaStep = ({
  setCurrentStep,
  setImgFiles,
  setBrochureFiles,
  isImgsLoading,
  isBrochureLoading,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  setImgFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setBrochureFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isImgsLoading: boolean;
  isBrochureLoading: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleNext = (step: CampaignSideNavStep) => {
    //TODO add validations
    setCurrentStep(step);
  };

  const { imgFilesSlice, brochureFilesSlice } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const handleRemoveImgFile = (fileId: number) => {
    dispatch(removeImgFileById(fileId));
  };

  const handleRemoveBrochureFile = (fileId: number) => {
    dispatch(removeBrochureFileById(fileId));
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="media"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.mainInformation}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.labels}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name-main">
              {t("property_images")}
            </div>

            <FileDropZone
              fileAcceptFormat={propertyImgAcceptFormat}
              limit={MAXIMUM_NUMBER_OF_PROPERTY_IMG}
              setFiles={setImgFiles}
              placeholder={"placeholder_upload_property_img"}
            />

            {imgFilesSlice.length > 0 && (
              <div
                style={{
                  display: "flex",
                  gap: "23px",
                  flexWrap: "wrap",
                  padding: "25px 0 0 0",
                }}
              >
                {imgFilesSlice.map(
                  (item: ExtractedMediaData, index: number) => (
                    <div
                      key={item.name + index}
                      className="drag-and-drop-file-container"
                    >
                      <div className="drag-and-drop-file-container-img-container">
                        <div
                          style={{
                            background: `url(${item.url}) center center / cover no-repeat`,
                            width: "190px",
                            height: "110px",
                          }}
                        ></div>
                        <div
                          style={{
                            right: isRTL ? "auto" : "-8px",
                            left: isRTL ? "-8px" : "auto",
                          }}
                          className="replace-cross-wrapper"
                          onClick={() => handleRemoveImgFile(item.id)}
                        >
                          <img src={ReplaceBlack} />
                        </div>
                      </div>
                      <div className="drag-and-drop-file-name">
                        {truncateString(item.name, 25)}
                      </div>
                    </div>
                  )
                )}

              </div>
            )}
            <FilesLoader isLoading={isImgsLoading} />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name-main">
              {t("attachments")}
            </div>

            <FileDropZone
              fileAcceptFormat={brochureAcceptFormat}
              limit={MAXIMUM_NUMBER_OF_BROCHURE}
              setFiles={setBrochureFiles}
              placeholder={"placeholder_upload_property_brochure"}
            />
            <div
              style={{
                paddingTop: "20px",
              }}
            >
              {brochureFilesSlice.map((file: any, index: number) => (
                <div key={file.name + index}>
                  <div className="drag-and-drop-file-container-pdf-container">
                    <img src={Paperclips} />
                    <div
                      className="drag-and-drop-pdf-name"
                      style={{
                        margin: isRTL ? "0 5px 0 15px" : "0 15px 0 5px",
                      }}
                    >
                      {truncateString(file.name, 50)}
                    </div>
                    <img
                      src={ReplaceCrossWhite}
                      onClick={() => handleRemoveBrochureFile(file.id)}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <FilesLoader isLoading={isBrochureLoading} />
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
