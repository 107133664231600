import {
  Box,
  Grid,
  Grid2,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftBlue from "../../assets/icons/arrow_left_blue.svg";
import ArrowRightWhite from "../../assets/icons/arrow_right_white.svg";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { Property } from "../../helpers/convert";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import "swiper/css";
import { FeaturedPropertiesSlide } from "./FeaturedPropertiesSlide";

export const FeaturedProperties = ({
  properties,
}: {
  properties: Property[];
}) => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [currSlide, setCurrSlide] = useState(0);

  const onSlideChange = useCallback(() => {
    if (swiperRef) {
      setCurrSlide(swiperRef.activeIndex);
    }
  }, [swiperRef]);

  const handlePrevious = useCallback(() => {
    if (swiperRef) {
      swiperRef.slidePrev();
      setCurrSlide(swiperRef.activeIndex);
    }
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    if (swiperRef) {
      swiperRef.slideNext();
      setCurrSlide(swiperRef.activeIndex);
    }
  }, [swiperRef]);

  return (
    <div
      className="featured-properties-container xl-width"
      style={{
        marginBottom: downMd ? "50px" : "130px",
      }}
    >
      <div
        className="featured-properties-title-container"
        style={{
          direction: i18n.dir(),
          marginTop: downMd ? "35px" : "130px",
          paddingRight: "20px",
          paddingLeft: "20px",
          marginBottom: "30px",
          flexDirection: downMd ? "column" : "row",
        }}
      >
        <div
          className="featured-properties-titles"
          style={{
            maxWidth: "486px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: langTheme.mainFont(),
              color: "rgba(79, 129, 157, 1)",
              mb: downMd ? "9px" : "0",
              fontWeight: 600,
            }}
          >
            {t("featured_properties")}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              textTransform: "uppercase",
              mb: downMd ? "15px" : "0",
              lineHeight: downMd ? "28px" : "46px",
            }}
          >
            {t("spotlight_on_exceptional_properties")}
          </Typography>
        </div>

        <div
          className="featured-properties-arrows-container"
          style={{
            direction: "ltr",
            justifyContent: isRTL ? "flex-end" : "flex-start",
          }}
        >
          <div
            className="featured-properties-arrow-circle"
            onClick={handlePrevious}
          >
            <img src={ArrowLeftBlue} />
          </div>
          <span
            style={{
              fontFamily: isRTL ? "DM Sans" : "Frank Ruhl Libre",
            }}
          >
            {currSlide + 1}/{properties.length}
          </span>
          <div className="featured-properties-arrow-round" onClick={handleNext}>
            <img src={ArrowRightWhite} />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "calc(100vw - 20px)",
          maxWidth: "1440px",
          padding: "0 20px",
          boxSizing: "border-box",
        }}
        className={"featured-properties-swiper-wrapper"}
      >
        <Swiper
          onSwiper={setSwiperRef}
          slidesPerView={1}
          onSlideChange={onSlideChange}
          spaceBetween={20}
          speed={1000}
        >
          {properties.map((property) => {
            return (
              <SwiperSlide key={property.id}>
                <FeaturedPropertiesSlide property={property} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
