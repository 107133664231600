import { useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import { useFormattedSocialProof } from "../../../hooks/useFormattedSocialProof";

export const HeroImgStatisticsUpMd = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();
  const { activeInvestors, successfulProjects, formattedSalesAmount } =
    useFormattedSocialProof();

  return (
    <div
      className="hero-img-statistics-container"
      style={{
        direction: i18n.dir(),
      }}
    >
      <div
        className="xl-width"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="hero-img-statistics-item"
          style={{
            paddingLeft: isRTL ? "0" : "20px",
            paddingRight: isRTL ? "20px" : " 0",
          }}
        >
          <div className="hero-img-statistics-quantity">{activeInvestors}+</div>
          <p
            className="hero-img-statistics-name"
            style={{
              textAlign: "left",
              fontFamily: langTheme.mainFont(),
              lineHeight: "28px",
            }}
          >
            {t("active")}
            <br />
            {t("investors")}
          </p>
        </div>

        <div className="hero-img-statistics-item">
          <div className="hero-img-statistics-quantity">
            {successfulProjects}+
          </div>
          <p
            className="hero-img-statistics-name"
            style={{
              textAlign: "left",
              fontFamily: langTheme.mainFont(),
              lineHeight: "28px",
            }}
          >
            {t("successful")}
            <br />
            {t("projects")}
          </p>
        </div>

        <div
          className="hero-img-statistics-item"
          style={{
            paddingLeft: isRTL ? "20px" : "0",
            paddingRight: isRTL ? "0" : " 20px",
          }}
        >
          <div
            className="hero-img-statistics-quantity"
            style={{
              direction: "ltr",
            }}
          >
            {isRTL ? "₪" : "$"} {formattedSalesAmount}
          </div>
          <p
            className="hero-img-statistics-name"
            style={{
              textAlign: "left",
              fontFamily: langTheme.mainFont(),
              lineHeight: "28px",
            }}
          >
            {t("in_re")}
            <br />
            {t("sales")}
          </p>
        </div>
      </div>
    </div>
  );
};
