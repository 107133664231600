import { Divider, Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { useAppDispatch } from '../../../store/store';
import {
  mapSideBarOpenSelector,
  setMapSideBarClose,
} from '../../../store/features/portfolioSlice';
import { useSelector } from 'react-redux';
import CrossGrey from '../../../assets/icons/cross_grey.svg';
import { Property } from '../../../helpers/convert';
import Map, { MapRef, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MappedPropertyPoint, TooltipElement } from './MapUpMd';
import { convertToPoints, formatPrice } from '../../../helpers/formatter';
import { MapPropertyPopup } from './MapPropertyPopup';
import { ControlMapPanel } from './ControlMapPanel';
import mapboxgl from 'mapbox-gl';
import { REACT_APP_MAP_ACCESS_TOKEN } from '../../../helpers/config';
import HouseIcon from '../../../assets/icons/house.svg';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

if (REACT_APP_MAP_ACCESS_TOKEN) {
  mapboxgl.accessToken = REACT_APP_MAP_ACCESS_TOKEN;
}
if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
  mapboxgl.setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    null,
    true,
  );
}

export const MapSideBar = ({
  properties,
  setVisibleMarkerIds,
}: {
  properties: Property[];
  setVisibleMarkerIds: Dispatch<SetStateAction<number[]>>;
}) => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === 'he';
  const mapSideBarOpen = useSelector(mapSideBarOpenSelector);
  const mapContainerRef = React.useRef<HTMLDivElement | null>(null);
  const mapRef = React.useRef<MapRef | null>(null);
  const isAuthenticated = useAuth();
  const navigate = useNavigate();

  const [viewState, setViewState] = React.useState({
    latitude: 0,
    longitude: 0,
    zoom: 10,
  });
  const [points, setPoints] = React.useState<MappedPropertyPoint[]>([]);
  const [clickedPointId, setClickedPointId] = React.useState<number | null>(
    null,
  );
  const [selectedPoint, setSelectedPoint] =
    React.useState<MappedPropertyPoint | null>(null);

  React.useEffect(() => {
    const points = convertToPoints(properties);

    setPoints(points);

    if (points.length > 0) {
      setViewState({
        latitude: points[0].latitude,
        longitude: points[0].longitude,
        zoom: 8,
      });
    }
  }, [properties]);

  const handleZoomIn = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.min(prev.zoom + 1, 22),
    }));
  };

  const handleZoomOut = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.max(prev.zoom - 1, 0),
    }));
  };

  const [mapStyle, setMapStyle] = React.useState(
    'mapbox://styles/mapbox/streets-v11',
  );

  const handleMarkerClick = (point: MappedPropertyPoint) => {
    if (!isAuthenticated) {
      navigate('/signin');
    } else {
      setSelectedPoint(point);
      setClickedPointId(point.id);
    }
  };

  const updateVisibleMarkers = React.useCallback(
    (map: mapboxgl.Map) => {
      const bounds = map.getBounds();

      if (!bounds) {
        return;
      }
      const visibleIds = points
        .filter((point) => bounds.contains([point.longitude, point.latitude]))
        .map((point) => point.id);

      setVisibleMarkerIds(visibleIds);
    },
    [points],
  );

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mapContainerRef.current &&
        !mapContainerRef.current.contains(event.target as Node)
      ) {
        setSelectedPoint(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onMapLoad = (event: mapboxgl.MapEvent) => {
    const map: mapboxgl.Map = event.target;
    updateVisibleMarkers(map);
    const language = currentLanguage;

    const languageField = 'name_' + language;

    const style = map.getStyle();
    if (!style || !style.layers) {
      console.error('Map style or layers are not available');
      return;
    }

    style.layers.forEach(function (layer: mapboxgl.LayerSpecification) {
      if (
        layer.type === 'symbol' &&
        layer.layout &&
        layer.layout['text-field']
      ) {
        map.setLayoutProperty(layer.id, 'text-field', [
          'coalesce',
          ['get', languageField],
          ['get', 'name'],
        ]);
      }
    });
  };

  return (
    <Drawer
      sx={{
        '& .MuiPaper-root': {
          height: '100%',
          position: 'relative',
        },
      }}
      anchor={'top'}
      open={mapSideBarOpen}
      onClose={() => dispatch(setMapSideBarClose())}
      ref={mapContainerRef}
    >
      <div
        onClick={() => {
          dispatch(setMapSideBarClose());
          setClickedPointId(null);
        }}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
          position: 'absolute',
          top: '18px',
          left: '18px',
          width: '31px',
          height: '31px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid rgba(225, 228, 241, 1)',
          zIndex: 300,
          cursor: 'pointer',
        }}
      >
        <img src={CrossGrey} />
      </div>
      <ControlMapPanel
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
      />
      <Map
        {...viewState}
        onMove={(evt) => {
          setViewState(evt.viewState);
          updateVisibleMarkers(evt.target);
        }}
        onLoad={onMapLoad}
        ref={mapRef}
        style={{ width: '100%', height: '100%' }}
        mapStyle={mapStyle}
        mapboxAccessToken={REACT_APP_MAP_ACCESS_TOKEN}
      >
        {points.map((point) => (
          <Marker
            key={point.id}
            latitude={point.latitude}
            longitude={point.longitude}
            anchor="bottom"
            style={{
              cursor: 'pointer',
            }}
          >
            <TooltipElement
              onClick={() => handleMarkerClick(point)}
              isClicked={clickedPointId === point.id}
            >
              {isAuthenticated ? (
                isRTL ? (
                  '₪'
                ) : (
                  '$' + formatPrice(point.basePrice)
                )
              ) : (
                <img src={HouseIcon} alt="house" />
              )}
            </TooltipElement>
          </Marker>
        ))}
        {selectedPoint && (
          <MapPropertyPopup
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
          />
        )}
      </Map>
    </Drawer>
  );
};
