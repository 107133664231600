import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const PasswordCheck = ({ password }: { password: string }) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const isAtLeast8Chars = React.useMemo(() => password.length >= 8, [password]);
  const hasSpecialChar = React.useMemo(
    () => /[!@#$%^&*(),.?":{}|<>]/.test(password),
    [password]
  );

  const hasNumber = React.useMemo(() => /\d/.test(password), [password]);

  return (
    <div
      className="password-checks-container"
      style={{
        fontFamily: langTheme.mainFont(),
        fontWeight: isRTL ? 400 : 500,
      }}
    >
      <div
        className="password-checks-item-container"
        style={{
          color: isAtLeast8Chars
            ? "rgba(43, 54, 116, 1)"
            : "rgba(230, 61, 61, 1)",
          marginRight: isRTL ? "0" : "37px",
          marginLeft: isRTL ? "37px" : "0",
        }}
      >
        <span style={{ fontSize: isRTL ? "38px" : "25px" }}>•</span>
        <div className="">{t("at_least_8_characters")}</div>
      </div>

      <div
        className="password-checks-item-container"
        style={{
          color: hasNumber ? "rgba(43, 54, 116, 1)" : "rgba(230, 61, 61, 1)",
          marginRight: isRTL ? "0" : "37px",
          marginLeft: isRTL ? "37px" : "0",
        }}
      >
        <span style={{ fontSize: isRTL ? "38px" : "25px" }}>•</span>
        <div>{t("at_least_1_number")}</div>
      </div>

      <div
        className="password-checks-item-container"
        style={{
          color: hasSpecialChar
            ? "rgba(43, 54, 116, 1)"
            : "rgba(230, 61, 61, 1)",
          marginRight: isRTL ? "0" : "37px",
          marginLeft: isRTL ? "37px" : "0",
        }}
      >
        <span style={{ fontSize: isRTL ? "38px" : "25px" }}>•</span>
        <div>{t("at_least_1_special_character")}</div>
      </div>
    </div>
  );
};
