import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PreviewMode } from "../../components/campaignBuilder/PreviewModeSwiter";

export interface ICampaingBuilderSliceState {
  mainLabelOpen: boolean;
  floorPlanSectionOpen: boolean;
  mapSectionOpen: boolean;
  videoSectionOpen: boolean;
  scheduleVewingOpen: boolean;
  agentPhotOpen: boolean;
  previewMode: PreviewMode;
  galleryOpen: boolean;
}

const initialState: ICampaingBuilderSliceState = {
  mainLabelOpen: true,
  floorPlanSectionOpen: true,
  mapSectionOpen: true,
  videoSectionOpen: true,
  scheduleVewingOpen: true,
  agentPhotOpen: true,
  previewMode: PreviewMode.DESKTOP,
  galleryOpen: false
};

const campaignBuilderSetupSlice = createSlice({
  name: "campaignBuilderSetup",
  initialState,
  reducers: {
    setMainLabelOpen(state, action) {
      state.mainLabelOpen = action.payload;
    },
    setFloorPlanSectionOpen(state, action) {
      state.floorPlanSectionOpen = action.payload;
    },
    setVideoSectionOpen(state, action) {
      state.videoSectionOpen = action.payload;
    },
    setMapSectionOpen(state, action) {
      state.mapSectionOpen = action.payload;
    },
    setScheduleVewingOpen(state, action) {
      state.scheduleVewingOpen = action.payload;
    },
    setAgentPhotoOpen(state, action) {
      state.agentPhotOpen = action.payload;
    },
    setPreviewMode(state, action) {
      state.previewMode = action.payload;
    },
    setImgGalleryOpen(state, action){
      state.galleryOpen = action.payload;
    }
  },
});

export const {
  setMainLabelOpen,
  setFloorPlanSectionOpen,
  setMapSectionOpen,
  setVideoSectionOpen,
  setScheduleVewingOpen,
  setAgentPhotoOpen,
  setPreviewMode,
  setImgGalleryOpen
} = campaignBuilderSetupSlice.actions;

export default campaignBuilderSetupSlice.reducer;
