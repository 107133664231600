import { Header } from '../components/_common/Header';
import { Navbar } from '../components/_common/Navbar';
import { Overview } from '../components/home/Overview';
import { HeroImg } from '../components/home/HeroImg/HeroImg';
import { FeaturedProperties } from '../components/home/FeaturedProperties';
import { PropertyTypesBlock } from '../components/home/PropertyTypesBlock';
import { Footer } from '../components/_common/Footer';
import {
  useGetHomepagePropertiesQuery,
  useGetPropertiesQuery,
} from '../store/api/propertyApiSlice';
import { AriaAttributes, useCallback, useEffect, useState } from 'react';
import { randomMediaGenerator } from '../helpers/fakeMedia';
import { FAKE_PROPERTIES, getRandomProperty } from '../helpers/fakeProperties';
import { convertToProperties, Property } from '../helpers/convert';
import { GetInTouchBlock } from '../components/home/GetInTouchBlock';
import { Testimonials } from '../components/home/Testimonials/Testimonials';
import { FooterSocialMediaLine } from '../components/_common/FooterSocialMediaLine';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { ForBuyers } from '../components/home/ForBuyers/ForBuyers';
import { ExploreTopListing } from '../components/home/ExploreBlock/ExploreTopListing';
import { IInitialProperty } from '../types/initialProperty';
import {
  buildSearchParamsFromGetPropertiesParams,
  DEFAULT_FILTER,
  DEFAULT_PAGE,
  DEFAULT_SORT,
  IFilterParams,
} from './Portfolio';
import { useNavigate } from 'react-router-dom';
import { useLanguageTheme } from '../hooks/useLanguageTheme';
import { AuthPopup } from '../components/_common/AuthPopup';

export const PREFERED_PROPERTY_NUM = 5;

export const Home = () => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'he';
  const langTheme = useLanguageTheme();
  const currentLanguage = i18n.language;

  const navigate = useNavigate();

  const navigateToPortfolioWithFilters = (filters: IFilterParams) => {
    const params = buildSearchParamsFromGetPropertiesParams({
      filters: filters,
      page: DEFAULT_PAGE,
      sort: DEFAULT_SORT,
      search: '',
    });
    navigate(`/portfolio?${params.toString()}`);
  };

  const navigateToPortfolioWithSearch = (search: string) => {
    if (search === '') {
      return;
    }

    const params = buildSearchParamsFromGetPropertiesParams({
      filters: DEFAULT_FILTER,
      page: DEFAULT_PAGE,
      sort: DEFAULT_SORT,
      search: search,
    });
    navigate(`/portfolio?${params.toString()}`);
  };

  const [featuredProperties, setFeaturedProperties] = useState<Property[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<Property[]>([]);
  const [topProperties, setTopProperties] = useState<Property[]>([]);
  const { data: fetchedProperties } = useGetHomepagePropertiesQuery({
    locale: currentLanguage,
  });

  useEffect(() => {
    if (fetchedProperties?.data) {
      const { exceptionalResults, differentlResults, exploreResults } =
        fetchedProperties.data;
      const exceptionalProps = convertToProperties(
        randomMediaGenerator,
        exceptionalResults,
      );
      const differentProps = convertToProperties(
        randomMediaGenerator,
        differentlResults,
      );
      const exploreProps = convertToProperties(randomMediaGenerator, exploreResults);

      setFeaturedProperties(exceptionalProps);
      setPropertyTypes(differentProps);
      setTopProperties(exploreProps);
      // TODO fallback when api failed
      // FALLBACK on fetch number
      // while (props.length < PREFERED_PROPERTY_NUM) {
      //   props.push(getRandomProperty());
      // }
    }
  }, [fetchedProperties]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <>
        <div className="portfolio-header-sticky-wrapper">
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
          >
            <Header>
              <Navbar />
            </Header>
          </div>
        </div>

        <Overview />
        <HeroImg
          filters={DEFAULT_FILTER}
          onChangeFilters={navigateToPortfolioWithFilters}
          search={''}
          onChangeSearch={navigateToPortfolioWithSearch}
        />
        <AuthPopup />
        <FeaturedProperties properties={featuredProperties} />
        <PropertyTypesBlock properties={propertyTypes} />
        <ForBuyers />
        <Testimonials/>
        <ExploreTopListing properties={topProperties} />
        <GetInTouchBlock />
        <Footer />
        <FooterSocialMediaLine />
      </>
    </div>
  );
};
