import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { setCampaingBuilderSideBarOpen } from "../../store/features/common";
import { useAppDispatch } from "../../store/store";
import { LangToggle } from "../_common/LangToggle";
import { CampaignBuilderHeader } from "./CampaignBuilderHeader";
import { CampaignBuilderMainInfoSection } from "./CampaignBuilderMainInfoSection";
import { CampaignBuilderMediaSection } from "./CampaignBuilderMediaSection";
import { PreviewMode } from "./PreviewModeSwiter";

export const CampainBuilderMainContainer = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();

  return (
    <div
      style={{
        fontFamily: langTheme.mainFont(),
        width: "100%",
        display: "flex",
        justifyContent: "center",
        direction: i18n.dir(),
      }}
    >
      <div
        style={{
          padding: downMd ? "35px 20px" : "36px 40px",
          maxWidth: previewMode === PreviewMode.DESKTOP ? "1300px" : "325px",
          width: "100%",
        }}
        onClick={() => dispatch(setCampaingBuilderSideBarOpen())}
      >
        <LangToggle />
        <CampaignBuilderHeader />
        <CampaignBuilderMediaSection />
        <CampaignBuilderMainInfoSection />
      </div>
    </div>
  );
};
