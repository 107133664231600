import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Header } from "../components/_common/Header";
import { Navbar } from "../components/_common/Navbar";
import {
  campaignBuilderDrawerWidth,
  CampaignBuilderSideNav,
  CampaignSideNavStep,
} from "../components/campaignBuilder/CampaignBuilderSideNav";
import { CampainBuilderMainContainer } from "../components/campaignBuilder/CampainBuilderMainContainer";
import {
  PreviewMode,
  PreviewModeSwiter,
} from "../components/campaignBuilder/PreviewModeSwiter";
import {
  extractMediaProperties,
  handleUploadFiles,
} from "../helpers/campaignConvert";
import { convertDateToTimePeriods } from "../helpers/formatter";
import {
  useGetAgentsQuery,
  useUploadImgMutation,
} from "../store/api/agentApiSlice";
import { useLazyGetPropertyQuery } from "../store/api/propertyApiSlice";
import {
  setAboutDeveloper,
  setAboutPropertyDesc,
  setAvatarFileSlice,
  setBrochureFileSlice,
  setCountOfTimePeriods,
  setCurrency,
  setFloorPlanSlice,
  setHightlightsBack,
  setImgFilesSlice,
  setKeyFeatureBack,
  setLabelBack,
  setPropertyDescription,
  setPropertyLocation,
  setPropertyPrice,
  setPropertyStatus,
  setPropertyTitle,
  setPropertyType,
  setSelectedAgent,
  setSelectedUserId,
  setTimePeriod,
  setVideoFileSlice,
} from "../store/features/campaignBuilderSlice";
import { campaignBuilderOpenSelector } from "../store/features/common";
import { useAppDispatch } from "../store/store";

export enum BuilderMode {
  CREATE = 0,
  EDIT = 1,
}

export const CampaignBuilder = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const [currentStep, setCurrentStep] = React.useState<CampaignSideNavStep>(
    () => {
      const savedStep = localStorage.getItem("currentStep");
      return savedStep &&
        Object.values(CampaignSideNavStep).includes(
          savedStep as CampaignSideNavStep
        )
        ? (savedStep as CampaignSideNavStep)
        : CampaignSideNavStep.campaignSetUp;
    }
  );

  React.useEffect(() => {
    localStorage.setItem("currentStep", currentStep);
  }, [currentStep]);

  const { documentId } = useParams();
  const builderMode = useMemo(() => {
    return documentId ? BuilderMode.EDIT : BuilderMode.CREATE;
  }, [documentId]);

  const [triggerGetProperty, { data: propertyData }] =
    useLazyGetPropertyQuery();
  const { data: agentsData } = useGetAgentsQuery();

  React.useEffect(() => {
    if (documentId) {
      triggerGetProperty(documentId);
    } else {
    }
  }, []);

  const [initFromBackend, setInitFromBackend] = useState(false);

  useEffect(() => {
    if (builderMode === BuilderMode.EDIT && propertyData) {
      setInitFromBackend(true);
    }
  }, [builderMode, propertyData]);

  useEffect(() => {
    if (propertyData) {
      console.log("log:propertyData ", propertyData);
      const { countOfTimePeriodsValue, timePeriodValue } =
        convertDateToTimePeriods(propertyData.campaignCloseDate);

      dispatch(setCountOfTimePeriods(countOfTimePeriodsValue));
      dispatch(setTimePeriod(timePeriodValue));
      dispatch(setPropertyTitle(propertyData.name));
      dispatch(setPropertyDescription(propertyData.description));
      dispatch(setPropertyPrice(String(propertyData.pricing.basePrice)));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setPropertyStatus(propertyData.propertyStatus));
      dispatch(setPropertyType(propertyData.propertyType));
      dispatch(setAboutPropertyDesc(propertyData.aboutProperty));
      dispatch(setAboutDeveloper(propertyData.developer));
      dispatch(setKeyFeatureBack(propertyData.keyFeatures));
      dispatch(setHightlightsBack(propertyData.investmentHighlights));
      dispatch(setSelectedUserId(propertyData.agent.id));
      dispatch(setLabelBack(propertyData.labels));
      dispatch(
        setPropertyLocation({
          city: propertyData.location.city,
          address: propertyData.location.address,
          coordinates: {
            latitude: propertyData.location.coordinates.latitude,
            longitude: propertyData.location.coordinates.longitude,
          },
        })
      );

      const extractedImgFiles = extractMediaProperties(
        propertyData.mediaContent
      );
      dispatch(setImgFilesSlice(extractedImgFiles));

      const extractedFloorPlan = extractMediaProperties(propertyData.floorplan);
      dispatch(setFloorPlanSlice(extractedFloorPlan));

      const extractedVideo = extractMediaProperties(propertyData.video);
      dispatch(setVideoFileSlice(extractedVideo));

      const extractedBrocure = extractMediaProperties(propertyData.brochure);
      dispatch(setBrochureFileSlice(extractedBrocure));
    }
  }, [propertyData]);

  const { selectedUserId } = useSelector((state: any) => state.campaignBuilder);

  React.useEffect(() => {
    if (agentsData && agentsData.length > 0) {
      const selectedAgent = agentsData.find(
        (agent: any) => agent.id === selectedUserId
      );

      if (selectedAgent) {
        dispatch(setSelectedAgent(selectedAgent));
      } else {
        if (selectedUserId === "create-new") {
          dispatch(setSelectedUserId("create-new"));
        } else {
          dispatch(setSelectedAgent(agentsData[0]));
          dispatch(setSelectedUserId(agentsData[0].id));
        }
      }
    }
  }, [agentsData, selectedUserId, dispatch]);

  const [imgFiles, setImgFiles] = React.useState<File[]>([]);
  const [floorplanFiles, setFloorplanFiles] = React.useState<File[]>([]);
  const [brochureFiles, setBrochureFiles] = React.useState<File[]>([]);
  const [videoFiles, setVideoFiles] = React.useState<File[]>([]);
  const [newAgentAvatar, setNewAgentAvatar] = React.useState<File[]>([]);

  const [uploadImg, { isLoading: isImgsLoading }] = useUploadImgMutation();
  const [uploadFloorPlan, { isLoading: isFloorPlanLoading }] =
    useUploadImgMutation();
  const [uploadBrochure, { isLoading: isBrochureLoading }] =
    useUploadImgMutation();
  const [uploadVideo, { isLoading: isVideoLoading }] = useUploadImgMutation();
  const [uploadAgentAvatar, { isLoading: isAvatarLoading }] =
    useUploadImgMutation();

  React.useEffect(() => {
    const uploadImages = async () => {
      if (imgFiles.length > 0) {
        try {
          const formData = handleUploadFiles(imgFiles);
          const filesResponse = await uploadImg(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);

          dispatch(setImgFilesSlice(extractedFiles));
          setImgFiles([]);
        } catch (error) {
          console.error("Error uploading images:", error);
        }
      }
    };

    uploadImages();
  }, [imgFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (floorplanFiles.length > 0) {
        try {
          const formData = handleUploadFiles(floorplanFiles);
          const filesResponse = await uploadFloorPlan(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setFloorPlanSlice(extractedFiles));
          setFloorplanFiles([]);
        } catch (error) {
          console.error("Error uploading floorplan:", error);
        }
      }
    };

    upoadFiles();
  }, [floorplanFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (brochureFiles.length > 0) {
        try {
          const formData = handleUploadFiles(brochureFiles);
          const filesResponse = await uploadBrochure(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setBrochureFileSlice(extractedFiles));
          setBrochureFiles([]);
        } catch (error) {
          console.error("Error uploading brochureFiles:", error);
        }
      }
    };

    upoadFiles();
  }, [brochureFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (videoFiles.length > 0) {
        try {
          const formData = handleUploadFiles(videoFiles);
          const filesResponse = await uploadVideo(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setVideoFileSlice(extractedFiles));
          setVideoFiles([]);
        } catch (error) {
          console.error("Error uploading videoFiles:", error);
        }
      }
    };

    upoadFiles();
  }, [videoFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (newAgentAvatar.length > 0) {
        try {
          const formData = handleUploadFiles(newAgentAvatar);
          const filesResponse = await uploadAgentAvatar(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setAvatarFileSlice(extractedFiles));
          setNewAgentAvatar([]);
        } catch (error) {
          console.error("Error uploading agentAvatar:", error);
        }
      }
    };

    upoadFiles();
  }, [newAgentAvatar]);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          marginRight:
            campaignBuilderOpen && !isRTL ? campaignBuilderDrawerWidth : 0,
          marginLeft:
            campaignBuilderOpen && isRTL ? campaignBuilderDrawerWidth : 0,
          transition: "margin-right 0.3s",
          overflow: "auto",
        }}
      >
        {!campaignBuilderOpen && (
          <div className="portfolio-header-sticky-wrapper">
            <div
              style={{
                paddingTop: "50px",
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            >
              <PreviewModeSwiter />

              {PreviewMode.DESKTOP === previewMode && (
                <Header>
                  <Navbar />
                </Header>
              )}
            </div>
          </div>
        )}

        <CampainBuilderMainContainer />
      </div>

      <CampaignBuilderSideNav
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setImgFiles={setImgFiles}
        newAgentAvatar={newAgentAvatar}
        setNewAgentAvatar={setNewAgentAvatar}
        setBrochureFiles={setBrochureFiles}
        setFloorplanFiles={setFloorplanFiles}
        setVideoFiles={setVideoFiles}
        builderMode={builderMode}
        initFromBackend={initFromBackend}
        setInitFromBackend={setInitFromBackend}
        isImgsLoading={isImgsLoading}
        isBrochureLoading={isBrochureLoading}
        isVideoLoading={isVideoLoading}
        isFloorPlanLoading={isFloorPlanLoading}
        isAvatarLoading={isAvatarLoading}
      />
    </div>
  );
};
