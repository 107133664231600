import React, { useEffect } from "react";
import EmailChecked from "../../assets/icons/email_checked.svg";
import { useTranslation } from "react-i18next";
import YellowArrow from "../../assets/icons/yellow_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Theme, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import {
  useResendSignupEmailMutation,
  useSignupMutation,
} from "../../store/api/authApiSlice";

export interface IResendSignupData {
  email: string;
}

export const EmailConfirmationContent = ({
  headerText,
}: {
  headerText: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { email } = useParams();

  const [resendEmailMutation] = useResendSignupEmailMutation();

  const resendEmail = async () => {
    if (!email) {
      return;
    }

    try {
      const response = await resendEmailMutation({
        email: email,
      }).unwrap();
    } catch (error) {
      console.log("log: ", error);
    }
  };

  return (
    <div
      className="email-confirmation-section"
      style={{
        direction: i18n.dir(),
        height: downMd ? "100vh" : "",
      }}
    >
      <div className="email-confirmation-email-checked-container">
        <img src={EmailChecked} />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
        }}
      >
        {t(`${headerText}`)}
      </div>

      <div className="email-confirmation-please-confirm-container">
        <div className="email-confirmation-we-sent-container">
          <div
            className="email-confirmation-grey"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("we_sent_a_link_to")}
          </div>
          <span>&nbsp;</span>
          <div
            className="email-confirmation-blue"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {email}
          </div>
        </div>
        <div
          className="email-confirmation-grey"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("please_confirm_you_account")}
        </div>
      </div>

      <div className="email-confirmation-dont-receive-container">
        <div
          style={{
            fontFamily: langTheme.mainFont(),
          }}
          className="email-confirmation-blue"
        >
          {t("dont_receive_the_email")}
        </div>
        <div
          className="email-confirmation-yellow"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
          onClick={() => resendEmail()}
        >
          {t("click_to_resend")}
        </div>
      </div>

      <div
        className="email-confirmation-yellow email-confirmation-back-to-signup"
        style={{
          fontFamily: langTheme.mainFont(),
          direction: "ltr",
        }}
        onClick={() => navigate("/signup")}
      >
        <img src={YellowArrow} />
        <div>{t("back_to_sign_up")}</div>
      </div>
    </div>
  );
};
