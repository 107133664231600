import { IInitialProperty } from "../types/initialProperty";
import { getImageUrl } from "./api";

import { randomMediaGenerator, RandomMediaGenerator } from "./fakeMedia";

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Location {
  city: string;
  address: string;
  coordinates: Coordinates;
}

export interface Pricing {
  basePrice: number;
}

export interface Detail {
  [key: string]: number | string | Array<number | string>;
  bathrooms: number;
  bedrooms: number;
  livingArea: number;
}

export interface Media {
  url: string;
}

export interface Property {
  id: number;
  name: string;
  desc: string;
  type: string;

  location: Location;
  pricing: Pricing;
  details: Detail;

  mainImage: Media;
  otherMedia: Media[];

  video: Media;
  videoPreview: Media;
}

export function convertMediaContent(
  mediaContent: Media[] | undefined
): Media[] {
  if (!mediaContent || mediaContent.length == 0) {
    return [];
  }

  const allMedia: Media[] = [];

  for (const media of mediaContent) {
    const url = media === null ? "" : media.url;

    allMedia.push({
      url: getImageUrl(url),
    });
  }

  return allMedia;
}

const PREFERED_OTHER_NUM = 3;

export function convertToProperty(
  gen: RandomMediaGenerator,
  obj: IInitialProperty,
  index: number
): Property {
  let allMedia = convertMediaContent(obj?.mediaContent);

  let mainMedia: Media;
  if (allMedia.length > 0) {
    mainMedia = allMedia[0];
  } else {
    if (index < gen.getImageLen()) {
      mainMedia = gen.getMainByIndex(index);
    } else {
      mainMedia = gen.getRandomMain();
    }
  }

  let otherMedia: Media[] = [];
  if (allMedia.length > 1) {
    otherMedia = allMedia.slice(1);
  }

  for (let i = 1; i < allMedia.length; i++) {
    otherMedia.push(allMedia[i]);
  }

  // FAKE IMAGES ONLY
  // if (index < FAKE_MEDIA_MAIN.length) {
  //   mainMedia = FAKE_MEDIA_MAIN[index];
  // } else {
  // mainMedia = gen.getRandomMain();
  // }
  // for (let media of otherMedia) {
  //   media.url = gen.getRandomOther().url;
  // }

  // files-dev.sentom-re.com/medium_house5_b09a61392f.jpg
  //api-dev.sentom-re.comfiles-dev.sentom-re.com/house2_36f010232f.jpg
  // FAKE IMAGES ONLY

  // const pricing =
  //   Object.keys(obj.pricing).length === 0 ? { basePrice: 0 } : obj.pricing;

  const pricing =
    obj && obj.pricing && Object.keys(obj.pricing).length === 0
      ? { basePrice: 0 }
      : obj.pricing || { basePrice: 0 };

  const details =
    !obj.details || Object.keys(obj.details).length === 0
      ? {
          parking: 1,
          bedrooms: 3,
          features: ["historical", "view", "luxury"],
          bathrooms: 3,
          totalArea: 200,
          floorLevel: 10,
          livingArea: 160,
          outdoorArea: 40,
          totalFloors: 10,
        }
      : obj.details;

  const location =
    Object.keys(obj.location).length === 0
      ? {
          city: "Tel Aviv",
          address: "100 Hayarkon St",
          amenities: [
            {
              name: "Gordon Beach",
              type: "recreation",
              distance: 200,
            },
          ],
          coordinates: {
            latitude: 32.0853,
            longitude: 34.781768,
          },
          neighborhood: "Beach Area",
        }
      : obj.location;

  return {
    id: obj.id,
    name: obj.name,
    desc: obj.description,
    type: obj.propertyType,
    location: location,
    pricing: pricing,
    details: details,
    mainImage: mainMedia,
    otherMedia: otherMedia,
    video: gen.getFakeVideo(),
    videoPreview: gen.getRandomMain(),
  };
}

export function convertToProperties(
  gen: RandomMediaGenerator,
  data: IInitialProperty[] | null | undefined
): Property[] {
  // TODO handle bad input
  const properties: Property[] = [];
  if (!data) {
    return [];
  }
  return data.map((rawMedia, index) => convertToProperty(gen, rawMedia, index));
}
