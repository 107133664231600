import { Theme } from "@mui/material";
import IsraelFlag from "../assets/flags/israel.svg";
import { AuthWrapper } from "../components/_common/AuthWrapper";
import { SignupContent } from "../components/auth/SignupContent";

export const authTextFieldStyles = {
  textField: (theme: Theme, isRTL: boolean, isError: boolean) => ({
    "& .MuiOutlinedInput-root": {
      border: `1px solid ${isError ? "red" : "rgba(224, 229, 242, 1)"}`,
      borderRadius: "16px",
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      border: "1px solid rgba(129, 154, 221, 1)",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      color: "rgba(43, 54, 116, 1)",
      fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
      fontWeight: isRTL ? 400 : 500,
      lineHeight: "14px",
      letterSpacing: "-0.32px",
      padding: "13.5px 17px",
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: "rgba(163, 174, 208, 1)",
      opacity: 1,
    },
    "& .MuiOutlinedInput-input:focus::placeholder": {
      opacity: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px rgba(255, 255, 255, 1) inset",
      WebkitTextFillColor: "rgba(43, 54, 116, 1)",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "& .MuiFormHelperText-root ": {
      marginTop: "0px",
    },
  }),
};

export interface ISignupError {
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  password?: string;
  termPolicyChecked?: string;
  confirmPassword?: string;
}

export const Signup = () => {
  return (
    <AuthWrapper>
      <SignupContent additionalDescription={""} isPopup={false} />
    </AuthWrapper>
  );
};
