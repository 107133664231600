import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "../../assets/campaignBuilder/upload_files.svg";
import { useTranslation } from "react-i18next";
import ReplaceCrossBlack from "../../assets/campaignBuilder/remove_cross.svg";
import ReplaceCrossWhite from "../../assets/campaignBuilder/white_replace_cross.svg";
import Paperclips from "../../assets/campaignBuilder/paperclips.svg";
import { FileType, ImageFormatMap } from "./CampaignMediaStep";
import ReplaceCross from "../../assets/campaignBuilder/agent_replace_cross.svg";
import { truncateString } from "../../helpers/formatter";
import ReplaceBlack from "../../assets/campaignBuilder/replace.svg";
import { useAppDispatch } from "../../store/store";
import { setImgFilesTest } from "../../store/features/campaignBuilderSlice";

export const FileDropZone = ({
  fileAcceptFormat,
  limit,
  files,
  setFiles,
  type,
  placeholder,
}: {
  fileAcceptFormat: ImageFormatMap;
  limit: number;
  files?: any;
  setFiles?: any;
  type?: FileType;
  placeholder: string;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const dispatch = useAppDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileAcceptFormat,
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles: any[]) => {
        const newFiles = acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: `${file.name}-${index}-${Date.now()}`,
          })
        );

        return [...prevFiles, ...newFiles].slice(0, limit);
      });
    dispatch(setImgFilesTest(acceptedFiles));
     
    },
  });

  const handleRemoveFile = (fileId: string) => {
    setFiles((prevFiles: any[]) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
  };

  const thumbsImg = files.map((file: any, index: number) => (
    <div key={file.name + index} className="drag-and-drop-file-container">
      <div className="drag-and-drop-file-container-img-container">
        <div
          style={{
            background: `url(${file.preview}) center center / cover no-repeat`,
            width: "190px",
            height: "110px",
          }}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        ></div>
        <div
          style={{
            right: isRTL ? "auto" : "-8px",
            left: isRTL ? "-8px" : "auto",
          }}
          className="replace-cross-wrapper"
          onClick={() => handleRemoveFile(file.id)}
        >
          <img src={ReplaceBlack} />
        </div>
      </div>
      <div className="drag-and-drop-file-name">
        {truncateString(file.name, 25)}
      </div>
    </div>
  ));

  const thumbsPdf = files.map((file: any, index: number) => (
    <div key={file.name + index}>
      <div className="drag-and-drop-file-container-pdf-container">
        <img src={Paperclips} />
        <div
          className="drag-and-drop-pdf-name"
          style={{
            margin: isRTL ? "0 5px 0 15px" : "0 15px 0 5px",
          }}
        >
          {truncateString(file.name, 50)}
        </div>
        <img
          src={ReplaceCrossWhite}
          onClick={() => handleRemoveFile(file.id)}
          style={{
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  ));

  const thumbsVideo = files.map((file: any, index: number) => (
    <div key={file.name + index} className="drag-and-drop-file-container">
      <div className="drag-and-drop-file-container-img-container">
        <video
          src={file.preview}
          controls
          style={{
            width: "190px",
            height: "110px",
          }}
        />

        <div
          style={{
            right: isRTL ? "auto" : "-8px",
            left: isRTL ? "-8px" : "auto",
          }}
          className="replace-cross-wrapper"
          onClick={() => handleRemoveFile(file.id)}
        >
          <img src={ReplaceBlack} />
        </div>
      </div>
      <div className="drag-and-drop-file-name">
        {truncateString(file.name, 25)}
      </div>
    </div>
  ));

  const thumbsUserAvatar = files.map((file: any, index: number) => (
    <div key={file.name + index} className="drag-and-drop-file-container">
      <div
        style={{
          position: "relative",
          width: "fit-content",
        }}
      >
        <img
          onClick={() => handleRemoveFile(file.id)}
          src={ReplaceCross}
          style={{
            right: isRTL ? "auto" : "-17px",
            left: isRTL ? "-17px" : "auto",
            position: "absolute",
          }}
          className="campaign-builder-sidenav-agent-photo-cross "
        />
        <div
          style={{
            background: `url(${file?.preview}) center center / cover no-repeat`,
            height: "111px",
            width: "111px",
            borderRadius: "55px",
          }}
          className="campaign-builder-sidenav-agent-photo img "
        ></div>
      </div>
    </div>
  ));

  // useEffect(() => {
  //   return () =>
  //     files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  // }, [files]);

  return (
    <div>
      <div className="drag-and-drop-area-wrapper">
        <div
          {...getRootProps({ className: "dropzone" })}
          className="drag-and-drop-area-container"
        >
          <img src={UploadFileIcon} className="drag-and-drop-area-upload-img" />
          <div className="drag-and-drop-area-upload-img-text">
            {t(placeholder)}
          </div>
          <input {...getInputProps()} />
        </div>
      </div>

      {files.length !== 0 && (
        <section>
          <aside
            className="container"
            style={{
              display: "flex",
              gap: "23px",
              flexWrap: "wrap",
              padding: "25px 0",
            }}
          >
            {type === FileType.IMG && thumbsImg}
            {type === FileType.PDF && thumbsPdf}
            {type === FileType.VIDEO && thumbsVideo}
            {type === FileType.AGET_AVATAR && thumbsUserAvatar}
          </aside>
        </section>
      )}
    </div>
  );
};
