import { Dialog, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { SignupContent } from "../auth/SignupContent";
import BlackCross from "../../assets/icons/blackCross.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  isLoginPopupContentSelector,
  setSignupPopupClose,
  authPopupOpenSelector,
} from "../../store/features/common";
import { useAppDispatch } from "../../store/store";
import { SigninContent } from "./SigninContent";

export const AuthPopup = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation(["signin"]);
  const isRTL = i18n.dir() === "rtl";
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const signupPopupOpen = useSelector(authPopupOpenSelector);
  const isLoginPopupContent = useSelector(isLoginPopupContentSelector);

  return (
    <Dialog
      open={signupPopupOpen}
      onClose={() => dispatch(setSignupPopupClose())}
      sx={{
        ".MuiPaper-root": {
          padding: downSm ? "10px" : "35px 25px",
          maxHeight: "calc(100% - 140px);",
          minWidth: "400px",
        },
      }}
    >
      <div
        style={{
          direction: isRTL ? "ltr" : "rtl",
        }}
      >
        <div
          onClick={() => dispatch(setSignupPopupClose())}
          className="signup-close-cross"
        >
          <img src={BlackCross} />
        </div>
      </div>

      {isLoginPopupContent ? (
        <SigninContent isPopup={true} />
      ) : (
        <SignupContent
          additionalDescription={"additionalDescription"}
          isPopup={true}
        />
      )}
    </Dialog>
  );
};
