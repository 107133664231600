import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { ReactComponent as SelectArrow } from "../../assets/campaignBuilder/select_arror.svg";
import { selectStyles } from "./CampaingBuilderSelect";
import { User } from "./ContactInformation";

export const AgentSelect = ({
  value,
  onChangeValue,
  options,
}: {
  value: any;
  onChangeValue: (value: any) => void;
  options: User[];
}) => {
  const { t } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <Select
      value={value || ""}
      onChange={(event) => onChangeValue(event.target.value as string)}
      sx={selectStyles(langTheme.mainFont(), isRTL)}
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(232, 239, 242, 1)",
            },
          },
        },
      }}
      IconComponent={SelectArrow}
    >
      <MenuItem
        value="create-new"
        sx={{
          color: "rgba(0, 0, 0, 1)",
          fontFamily: langTheme.mainFont(),
          fontWeight: 500,
          fontSize: "14px",
        }}
      >
      {t("create_new")}
      </MenuItem>
      {options.map((option: any) => (
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};
