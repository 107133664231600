import React from "react";
import { useTranslation } from "react-i18next";

export const CampaignSetupItem = ({
  title,
  icon,
  handleOpenStep
}: {
  title: string;
  icon: string;
  handleOpenStep?: any
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);

  return (
    <div
      className="campaign-builder-sidenav-setup-item"
      style={{
        direction: i18n.dir(),
      }}
      onClick={handleOpenStep}
    >
      <img src={icon} />
      <div>{t(title)}</div>
    </div>
  );
};
