import { Grid, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ForBuyersInfo } from "./ForBuyersInfo";
import { ForBuyersDownMdImgBlock } from "./ForBuyersDownMdImgBlock";
import { ForBuyersUpMdImgBlock } from "./ForBuyersUpMdImgBlock";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";

export const ForBuyers = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
const langTheme = useLanguageTheme()

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <div
      className="for-buyers-container"
      style={{
        marginBottom: downSm ? "0" : downMd ? "50px" : "130px",
        marginTop: downMd ? "50px" : "130px",
      }}
    >
      <Grid
        container
        spacing={4}
        className="xl-width"
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          flexDirection: isRTL ? "row-reverse" : "row",
        }}
      >
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          {downMd ? <ForBuyersDownMdImgBlock /> : <ForBuyersUpMdImgBlock />}
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          display={"flex"}
          alignItems={"center"}
          order={{ xs: 1, md: 2 }}
        >
          <ForBuyersInfo />
        </Grid>
      </Grid>
    </div>
  );
};
