import React from "react";
import PhoneIcon from "../../assets/campaignBuilder/phone.svg";
import { useTranslation } from "react-i18next";

export const SpeakToAnAgentBtn = () => {
  const { t } = useTranslation(["campaignBuilder"]);

  return (
    <div className="speak-to-agent-btn">
      <img src={PhoneIcon} />
      <div>{t("speak_to_an_agent")}</div>
    </div>
  );
};
