import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Property } from "../../helpers/convert";
import { Button, Theme, useMediaQuery } from "@mui/material";
import { PropertiesSortSelect } from "./PropertiesSortSelect";
import { PropertyCard } from "./PropertyCard";
import { useSelector } from "react-redux";
import {
  mapCheckedSelector,
  propertiesPaginationParamnsSelector,
  propertyErrorSelector,
  setMapChecked,
} from "../../store/features/portfolioSlice";
import { MapUpMd } from "./map/MapUpMd";
import { PropertiesPagination } from "./PropertiesPagination";
import CircularProgress from "@mui/material/CircularProgress";
import { sortOptions } from "../../constants/propertyData";
import { MapSideBar } from "./map/MapSideBar";
import { filterPropertiesByIds } from "../../helpers/dataConverts";
import { IPropertiesPaginationMeta, ISortParams } from "../../pages/Portfolio";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../store/store";
import HousePlusIcon from "../../assets/icons/house_plus.svg";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const PropertiesContainer = ({
  allProperties,
  onPageChange,
  initSort,
  onSortChange,
  paginationMeta,
  isLoading,
  headerHeight,
  search,
}: {
  allProperties: Property[];
  onPageChange: (pageNum: number) => void;
  initSort: ISortParams;
  onSortChange: (sortParams: ISortParams) => void;
  paginationMeta: IPropertiesPaginationMeta | null;
  isLoading: boolean;
  headerHeight: number;
  search: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["portfolio"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const isAuthenticated = useAuth();

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isFilterDesktop = useMediaQuery("(max-width: 1090px)");

  const [visibleMarkerIds, setVisibleMarkerIds] = React.useState<number[]>([]);

  const mapChecked = useSelector(mapCheckedSelector);
  const propertyError = useSelector(propertyErrorSelector);

  const visibleProperties = useMemo(() => {
    if (!mapChecked && !isFilterDesktop) {
      return allProperties;
    }

    if (visibleMarkerIds.length === 0 && isFilterDesktop) {
      return allProperties;
    }

    return filterPropertiesByIds(allProperties, visibleMarkerIds);
  }, [
    allProperties,
    visibleMarkerIds,
    mapChecked,
    currentLanguage,
    search,
    isFilterDesktop,
  ]);

  return (
    <div
      style={{
        boxSizing: "border-box",
        paddingRight: "20px",
        paddingLeft: "20px",
        maxWidth: "2360px",
        width: "100%",
      }}
    >
      <div>
        {propertyError && (
          <div
            className="portfolio-error-container xl-width"
            style={{
              marginTop: downMd ? "30px" : "40px",
              marginBottom: downMd ? "30px" : "40px",
              fontFamily: langTheme.mainFont(),
            }}
          >
            Something went wrong
          </div>
        )}

        {isLoading ? (
          <div className="portfolio-error-container">
            <CircularProgress sx={{ color: "rgb(3, 74, 115)" }} />
          </div>
        ) : (
          <div
            className={`layout-container ${
              mapChecked && !isFilterDesktop ? "map-open" : ""
            }`}
            style={{
              direction: i18n.dir(),
            }}
          >
            <div
              style={{
                paddingTop: downMd ? "30px" : "40px",
              }}
            >
              <div
                className="properties-top-info-container"
                style={{
                  direction: i18n.dir(),
                  flexDirection: downMd ? "column" : "row",
                  alignItems: downMd ? "flex-start" : "center",
                  gap: downMd ? "20px" : "",
                  marginBottom: downMd ? "35px" : "40px",
                }}
              >
                <div
                  style={{
                    fontFamily: langTheme.mainFont(),
                    fontWeight: isRTL ? 700 : 600,
                    fontSize: downMd ? "16px" : "22px",
                  }}
                >
                  {visibleProperties.length}
                  <span>&nbsp;</span>
                  {t("properties_for_sale")}
                </div>
                <Button
                  href={isAuthenticated ? "/campain-page-builder" : "/signin"}
                  variant="contained"
                  startIcon={
                    <img
                      src={HousePlusIcon}
                      alt="New Campaign Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        margin: isRTL ? "10px" : "0px",
                      }}
                    />
                  }
                  sx={{
                    width: "228px",
                    height: "42px",
                    background:
                      "linear-gradient(90deg, #F0F4F7 45%, #FFE2B7 100%)",
                    borderRadius: "21px",
                    color: "black",
                    textTransform: "none",
                    padding: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1rem",
                    position: "relative",
                    fontFamily: langTheme.mainFont(),
                  }}
                >
                  {t("new_campaign")}
                </Button>
                <PropertiesSortSelect
                  initSort={initSort}
                  onSortChange={onSortChange}
                  options={sortOptions}
                />
              </div>
              <div
                className="cards-container"
                style={{
                  direction: i18n.dir(),
                }}
              >
                {visibleProperties.map((property, index) => (
                  <div key={index} className="property-card">
                    <PropertyCard property={property} />
                  </div>
                ))}
              </div>
            </div>
            {!isFilterDesktop && mapChecked && (
              <div
                className="map-container"
                style={{
                  top: `${headerHeight}px`,
                  height: `calc(100vh - ${headerHeight}px)`,
                }}
              >
                <MapUpMd
                  properties={allProperties}
                  setVisibleMarkerIds={setVisibleMarkerIds}
                  search={search}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <MapSideBar
        properties={allProperties}
        setVisibleMarkerIds={setVisibleMarkerIds}
      />
      <div
        style={{
          marginTop: "65px",
          marginBottom: "81px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PropertiesPagination
          paginationMeta={paginationMeta}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};
