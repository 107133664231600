import { REACT_APP_MAP_ACCESS_TOKEN } from "../../helpers/config";
import { useTranslation } from "react-i18next";
import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { TextField } from "@mui/material";
import { textFieldStyles } from "./MainInformationStep";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../store/store";
import { setPropertyLocation } from "../../store/features/campaignBuilderSlice";
import { useSelector } from "react-redux";

if (REACT_APP_MAP_ACCESS_TOKEN) {
  mapboxgl.accessToken = REACT_APP_MAP_ACCESS_TOKEN;
}

interface Feature {
  id: string;
  place_name: string;
  center: [number, number];
  context?: { id: string; text: string }[];
}

export interface IPropertyLocation {
  city: string;
  address: string;
  coordinates: {
    latitude: number | null;
    longitude: number | null;
  };
}

export const CampaignBuilderLocationSearch = ({
  errors,
  setErrors,
}: {
  errors: any;
  setErrors: any;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["campaignBuilder"]);
  const { propertyLocation } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const [query, setQuery] = useState(propertyLocation?.address || "");
  const [results, setResults] = useState<Feature[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const langTheme = useLanguageTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const fetchSearchResults = async (searchText: string) => {
    if (!searchText) {
      setResults([]);
      return;
    }

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        searchText
      )}.json?types=address&country=il&language=en,he&access_token=${REACT_APP_MAP_ACCESS_TOKEN}`;

      const response = await fetch(endpoint);
      const data = await response.json();

      if (data && data.features) {
        setResults(data.features);
      }
    } catch (error) {
      console.error("Error fetching data from Mapbox:", error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);

    const newErrors = { ...errors };
    if (!value.trim()) {
      newErrors.location = t("errors.location_is_required");
      dispatch(
        setPropertyLocation({
          city: "",
          address: "",
          coordinates: { latitude: null, longitude: null },
        })
      );
    } else {
      delete newErrors.location;
    }
    setErrors(newErrors);

    setHighlightedIndex(-1);

    fetchSearchResults(value);
  };

  const handleSelect = (feature: Feature) => {
    const cityFromContext =
      feature.context?.find((ctx) => ctx.id.includes("place"))?.text || "";
    dispatch(
      setPropertyLocation({
        city: cityFromContext,
        address: feature.place_name,
        coordinates: {
          longitude: feature.center[0],
          latitude: feature.center[1],
        },
      })
    );
    setQuery(feature.place_name);
    setResults([]);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (results.length > 0) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setHighlightedIndex((prev) =>
            prev < results.length - 1 ? prev + 1 : 0
          );
          break;

        case "ArrowUp":
          e.preventDefault();
          setHighlightedIndex((prev) =>
            prev > 0 ? prev - 1 : results.length - 1
          );
          break;

        case "PageDown":
          e.preventDefault();
          setHighlightedIndex((prev) => {
            const nextIndex = prev + 5;
            return nextIndex < results.length ? nextIndex : results.length - 1;
          });
          break;

        case "PageUp":
          e.preventDefault();
          setHighlightedIndex((prev) => {
            const nextIndex = prev - 5;
            return nextIndex >= 0 ? nextIndex : 0;
          });
          break;

        case "Enter":
          e.preventDefault();
          if (highlightedIndex >= 0 && highlightedIndex < results.length) {
            handleSelect(results[highlightedIndex]);
          }
          break;

        default:
          break;
      }
    }
  };

  const renderSuggestions = () => {
    if (results.length === 0) return null;
    return (
      <ul className="suggestions-list">
        {results.map((feature, index) => (
          <li
            key={feature.id}
            onClick={() => handleSelect(feature)}
            className={`suggestion-item ${
              index === highlightedIndex ? "highlighted" : ""
            }`}
            onMouseEnter={() => setHighlightedIndex(index)}
          >
            {feature.place_name}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      if (containerRef.current && !containerRef.current.contains(target)) {
        setResults([]);
        if (!propertyLocation?.address) {
          setQuery("");
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [propertyLocation?.address]);

  useEffect(() => {
    if (propertyLocation?.address) {
      setQuery(propertyLocation.address);
    }
  }, [propertyLocation?.address]);

  return (
    <div
      className="mapbox-search-container"
      style={{ width: "100%" }}
      ref={containerRef}
    >
      <TextField
        sx={textFieldStyles(langTheme.mainFont())}
        id="search-input"
        autoComplete="off"
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={t("address")}
        style={{ width: "100%" }}
        inputProps={{ maxLength: 80 }}
      />
      {renderSuggestions()}
    </div>
  );
};
