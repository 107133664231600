import { Slider, Theme, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../assets/icons/search_icon.svg";
import YellowSparkes from "../../assets/icons/YellowSparkels.svg";
import { useAppDispatch } from "../../store/store";
import {
  activePropertyFiltersSelector,
  propertiesPaginationParamnsSelector,
  setFetchedProperties,
  setPropertyError,
  setPropertiesPaginationParams,
  propertyErrorSelector,
  defaultActiveFilters,
  setActivePropertiesFilter,
} from "../../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertToProperties } from "../../helpers/convert";
import { IPropertyActiveFilters } from "../../types/properties";
import { IInitialProperty } from "../../types/initialProperty";
import { randomMediaGenerator } from "../../helpers/fakeMedia";
import { AskAIPopoverWhite } from "../home/AskAIPopover";
import { MapSwither } from "./map/MapSwither";
import {
  CustomFilterSelect,
  portfolioStyles,
} from "../_common/CustomFilterSelect";
import { PriceRangeSlider } from "../_common/PriceRangeSlider";
import { formatPrice } from "../../helpers/formatter";
import SelectArrowBlack from "../../assets/icons/selectArrowBlack.svg";
import { DEFAULT_PRICE_RANGE } from "../../pages/Portfolio";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export enum SearchTab {
  filter = "filter",
  search = "search",
}
export const PortfolioFilterUpMd = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation(["portfolio"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const mainContainerRef = React.useRef<HTMLDivElement | null>(null);

  const activePropertyFilters = useSelector(activePropertyFiltersSelector);
  const paginationParams = useSelector(propertiesPaginationParamnsSelector);

  const handleOpenAskAIPopover = (event: React.MouseEvent<HTMLElement>) => {
    setSearchTab(SearchTab.search);
  };

  const [lActive, setLActive] =
    useState<IPropertyActiveFilters>(defaultActiveFilters);

  const handleFilterChange = (key: string, selectedOptions: string[]) => {
    setLActive((prev: IPropertyActiveFilters) => {
      return {
        ...prev,
        [key]: selectedOptions,
      };
    });
  };

  useEffect(() => {
    setLActive(activePropertyFilters);
  }, [activePropertyFilters]);

  const handleSearchProperties = useCallback(() => {
    const params = new URLSearchParams();

    Object.entries(lActive).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((value) => params.append(key, value));
      }
    });

    params.delete("name");

    params.set("page", paginationParams.pagination.page.toString());

    navigate(`/portfolio?${params.toString()}`, { replace: true });
  }, [lActive]);

  const propertyError = useSelector(propertyErrorSelector);

  const handleClearFilters = () => {};

  const [searchTab, setSearchTab] = React.useState(SearchTab.filter);

  const handleOpenFilter = () => {
    setSearchTab(SearchTab.filter);
  };

  const [priceRangeValue, setPriceRangeValue] = React.useState<number[]>([
    0, 100_000_000,
  ]);

  const handleChangePriceRange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setPriceRangeValue(newValue as number[]);
    setLActive((prev: IPropertyActiveFilters) => {
      return {
        ...prev,
        ["priceRange"]: newValue as number[],
      };
    });
  };

  const [openPriceRangePanel, setOpenPriceRangePanel] = React.useState(false);
  const panelRef = React.useRef<HTMLDivElement>(null);

  const handleOpenPriceRangePanel = () => {
    setOpenPriceRangePanel(!openPriceRangePanel);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setOpenPriceRangePanel(false);
    }
  };

  useEffect(() => {
    if (openPriceRangePanel) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPriceRangePanel]);

  return (
    <div className="xl-width">
      <div className="porfolio-filter-container">
        <div
          className="porfolio-filter-main-filter-container"
          style={{ position: "relative" }}
          ref={mainContainerRef}
        >
          {searchTab === "filter" && (
            <div
              className="portfolio-filter-ai-sparkles-container"
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                handleOpenAskAIPopover(e)
              }
            >
              <img
                src={YellowSparkes}
                alt="sparkles"
                style={{ width: "22px", height: "22px" }}
              />
              <p
                style={{
                  marginRight: isRTL ? "6px" : "0px",
                  marginLeft: isRTL ? "0" : "6px",
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                }}
              >
                {t("search")}
              </p>
            </div>
          )}

          {searchTab === SearchTab.search && (
            <div
              className="portfolio-filter-ai-sparkles-container"
              onClick={handleOpenFilter}
            >
              <p
                style={{
                  marginRight: isRTL ? "6px" : "0px",
                  marginLeft: isRTL ? "0" : "6px",
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                }}
              >
                {t("filters")}
              </p>
            </div>
          )}
        </div>

        {/* <AskAIPopoverWhite searchTab={searchTab} /> */}

        <div
          className="porfolio-filter-second-filter-container"
          style={{
            paddingRight: isRTL ? "35px" : "14px",
            paddingLeft: isRTL ? "14px" : "35px",
            maxWidth: "979px",
            display: searchTab === SearchTab.filter ? "flex" : "none",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={`portfolio-filter-location-container ${
                isRTL ? "borderLeft" : "borderRight"
              } `}
              style={{
                borderRight: isRTL
                  ? "none"
                  : "1px solid rgba(176, 155, 155, 0.52)",
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "138px" : "85px",
              }}
            >
              <span>{t("location")}</span>
              {/* <CustomFilterSelect
                options={locations}
                fontFamily={langTheme.mainFont()}
                truncateLength={upLg ? 15 : downLg ? 8 : 12}
                selectedOptions={lActive.location}
                onChange={(selected: string[]) =>
                  handleFilterChange("location", selected)
                }
                styles={portfolioStyles}
              /> */}
            </div>

            <div
              className="portfolio-filter-property-type-container"
              style={{
                borderRight: "1px solid rgba(176, 155, 155, 0.52)",
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "148px" : "130px",
              }}
            >
              <span>{t("property_type")}</span>
              {/* <CustomFilterSelect
                options={propertyTypes}
                fontFamily={langTheme.mainFont()}
                truncateLength={downLg ? 5 : 15}
                selectedOptions={lActive.propertyTypes}
                onChange={(selected: string[]) =>
                  handleFilterChange("propertyTypes", selected)
                }
                styles={portfolioStyles}
              /> */}
            </div>

            <div
              style={{
                marginRight: "15px",
                position: "relative",
              }}
              ref={panelRef}
            >
              <div
                onClick={handleOpenPriceRangePanel}
                style={{
                  width: "120px",
                  height: "46px",
                  borderRight: "1px solid rgba(176, 155, 155, 0.52)",
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: isRTL ? "flex-start" : "baseline",
                  gap: lActive.priceRange ? "0" : "14px",
                }}
              >
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.59)",
                    fontSize: "14px",
                    marginRight: "15px",
                  }}
                >
                  {t("price_range")}
                </span>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    width: "100%",
                    justifyContent: lActive.priceRange ? "left" : "center",
                    gap: "3px",
                  }}
                >
                  {lActive.priceRange && (
                    <>
                      {isRTL ? "₪" : "$"}
                      <div>{`${formatPrice(
                        lActive.priceRange[0]
                      )}-${formatPrice(lActive.priceRange[1])}`}</div>
                    </>
                  )}

                  <img src={SelectArrowBlack} />
                </div>
              </div>

              <div
                className="portfolio-filter-price-range-container-test"
                style={{
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  position: "absolute",
                  top: "80px",
                  borderRadius: "45px",
                  padding: "5px 35px",
                  backgroundColor: "rgba(255, 255, 255, 0.82)",
                  width: "320px",
                  display: openPriceRangePanel ? "block" : "none",
                }}
              >
                {/* <PriceRangeSlider
                  priceRangeValue={priceRangeValue}
                  handleChange={handleChangePriceRange}
                  styles={{ color: "rgba(0, 0, 0, 0.63)" }}
                /> */}
              </div>
            </div>

            <div
              className="portfolio-filter-status-container"
              style={{
                borderRight: "1px solid rgba(176, 155, 155, 0.52)",
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,

                width: upLg ? "178px" : "90px",
              }}
            >
              <span>{t("status")}</span>
              {/* <CustomFilterSelect
                options={statuses}
                fontFamily={langTheme.mainFont()}
                truncateLength={downLg ? 8 : 20}
                selectedOptions={lActive.statuses}
                onChange={(selected: string[]) =>
                  handleFilterChange("statuses", selected)
                }
                styles={portfolioStyles}
              /> */}
            </div>
            <div
              style={{
                borderRight: isRTL
                  ? "1px solid rgba(176, 155, 155, 0.52)"
                  : "none",
                marginRight: "15px",
                height: "46px",
              }}
            >
              <MapSwither />
            </div>

            <div
              onClick={handleClearFilters}
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                cursor: "pointer",
              }}
            >
              {t("clear")}
            </div>
          </div>

          <div
            className="portfolio-filter-second-search-btn-container"
            onClick={() => handleSearchProperties()}
          >
            <img src={SearchIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
