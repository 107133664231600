import { apiSlice } from "./apiSlice";

export const socialProofApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSocialProof: builder.query({
      query: () => {
        return "/social-proof";
      },
    }),
  }),
});

export const { useGetSocialProofQuery } = socialProofApiSlice;
