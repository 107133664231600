import { Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import LinkArrowBlue from "../../../assets/icons/link_arrow_blue.svg";

export const ExporeBlock = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
const langTheme = useLanguageTheme()
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div className="expore-block-container">
      <div className="expore-block-title-container">
        <div
          className="expore-block-title"
          style={{
            fontSize: downMd ? "20px" : "24px",
            maxWidth: downMd ? "189px" : "130px",
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 700 : 500,
          }}
        >
          {t("explore_title")}
        </div>
        <div className="expore-block-arrow">
          <img src={LinkArrowBlue} />
        </div>
      </div>

      <div
        className="expore-block-desc"
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: 400,
        }}
      >
        {t("explore_desc")}
      </div>
    </div>
  );
};
