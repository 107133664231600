import React from "react";
import {
  CampaignSideNavStep,
  Currency,
  PropertyStatus,
  PropertyType,
} from "./CampaignBuilderSideNav";
import { useTranslation } from "react-i18next";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import {
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { CampaingBuilderSelect } from "./CampaingBuilderSelect";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { CampaignBuilderLocationSearch } from "./CampaignBuilderLocationSearch";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import {
  setPropertyDescription,
  setPropertyPrice,
  setPropertyStatus,
  setPropertyTitle,
  setPropertyType,
} from "../../store/features/campaignBuilderSlice";
import { formatPrice } from "../../helpers/dataConverts";
import { BackToMenuBtn } from "./BackToMenuBtn";

export const textFieldStyles = (fontFamily: string, error?: any) => ({
  backgroundColor: "rgba(232, 239, 242, 1)",
  borderRadius: "16px",
  border: error
    ? "1px solid rgba(250, 99, 99, 1)"
    : "1px solid rgba(5, 57, 87, 0.46)",
  fontSize: "14px",
  boxSizing: "border-box",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      boxSizing: "border-box",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "rgba(131, 138, 158, 1)",
    opacity: 1,
    fontWeight: 500,
    fontFamily: fontFamily,
  },
  "& .MuiInputBase-input": {
    color: "rgba(0, 0, 0, 1)",
    fontWeight: 500,
    fontFamily: fontFamily,
    padding: "15px 20px",
    fontSize: "14px",
  },
  "& .MuiInputBase-input.MuiInputBase-inputMultiline": {
    padding: "0",
  },
});

export interface Errors {
  title: string;
  description: string;
  price: string;
  location: string;
  aboutPropertyDesc: string;
  aboutDeveloper: string;
}

export const MainInformationStep = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();

  const {
    currency,
    propertyTitle,
    propertyDescription,
    propertyStatus,
    propertyPrice,
    propertyLocation,
    propertyType,
  } = useSelector((state: any) => state.campaignBuilder);

  const [errors, setErrors] = React.useState<any>({});

  const validateFields = () => {
    let errors: Partial<Errors> = {};

    if (propertyTitle.trim() === "") {
      errors.title = t("errors.title_is_required");
    }
    if (propertyDescription.trim() === "") {
      errors.description = t("errors.desc_is_required");
    }

    if (propertyPrice.trim() === "") {
      errors.price = t("errors.price_is_required");
    }

    if (propertyLocation.address == "") {
      errors.location = t("errors.location_is_required");
    }

    return errors;
  };

  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const handleChangePropertyStatus = (
    event: SelectChangeEvent<PropertyStatus>
  ) => {
    dispatch(setPropertyStatus(event.target.value as PropertyStatus));
  };

  const handleChangePropertyType = (
    event: SelectChangeEvent<PropertyStatus>
  ) => {
    dispatch(setPropertyType(event.target.value as PropertyType));
  };
  const handleChangePropertyTitle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newErrors = { ...errors };

    const value = event.target.value;
    dispatch(setPropertyTitle(value));

    if (!value.trim()) {
      newErrors.title = t("errors.title_is_required");
    } else {
      delete newErrors.title;
    }
    setErrors(newErrors);
  };

  const handleChangePropertyDesc = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newErrors = { ...errors };

    const value = event.target.value;
    dispatch(setPropertyDescription(value));

    if (!value.trim()) {
      newErrors.description = t("errors.desc_is_required");
    } else {
      delete newErrors.description;
    }
    setErrors(newErrors);
  };

  const handleChangePropertyPrice = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newErrors = { ...errors };

    const value = event.target.value.replace(/,/g, "");
    const isValidNumber = /^\d*$/.test(value);

    if (isValidNumber) {
      if (Number(value) <= 1000000000) {
        dispatch(setPropertyPrice(value));
        delete newErrors.price;
      } else {
        newErrors.price = t("errors.price_exceeds_limit");
      }
    } else {
      newErrors.price = t("errors.only_numbers");
    }

    if (!value.trim()) {
      newErrors.price = t("errors.price_is_required");
    }

    setErrors(newErrors);
  };

  const handleNext = (step: CampaignSideNavStep) => {
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setCurrentStep(step);
    }
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="main_information"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.settings}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.media}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("title_of_property_plug")}
            </div>

            <TextField
              fullWidth
              placeholder={t("title")}
              value={propertyTitle}
              onChange={handleChangePropertyTitle}
              sx={textFieldStyles(langTheme.mainFont(), errors.title)}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && (
              <div className="campaign-builder-errors">{errors.title}</div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("description_of_property_plug")}
            </div>

            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder={t("description")}
              sx={textFieldStyles(langTheme.mainFont(), errors.description)}
              autoComplete="off"
              value={t(propertyDescription)}
              onChange={handleChangePropertyDesc}
              //set limit
              inputProps={{ maxLength: 250 }}
            />

            {errors.description && (
              <div className="campaign-builder-errors">
                {errors.description}
              </div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("full_address")}
            </div>

            <CampaignBuilderLocationSearch
              errors={errors}
              setErrors={setErrors}
            />
            {errors.location && (
              <div className="campaign-builder-errors">{errors.location}</div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("price")}
            </div>
            <TextField
              value={formatPrice(propertyPrice)}
              onChange={handleChangePropertyPrice}
              fullWidth
              placeholder={t("price")}
              sx={textFieldStyles(langTheme.mainFont(), errors.price)}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    sx={{
                      height: "100%",
                      ml: 0,
                      maxHeight: "none",
                      marginRight: isRTL ? "0px" : "-14px",
                      marginLeft: isRTL ? "-14px" : "0",
                      pl: isRTL ? "0px" : "6px",
                      pr: isRTL ? "20px" : "0px",
                      color: "rgba(0, 0, 0, 1)",
                    }}
                    position="start"
                  >
                    <div>{t(currency)[0]}</div>
                  </InputAdornment>
                ),
              }}
            />
            {errors.price && (
              <div className="campaign-builder-errors">{errors.price}</div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("property_status")}
            </div>

            <CampaingBuilderSelect
              value={propertyStatus}
              onChangeValue={handleChangePropertyStatus}
              options={Object.values(PropertyStatus)}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("property_type")}
            </div>

            <CampaingBuilderSelect
              value={propertyType}
              onChangeValue={handleChangePropertyType}
              options={Object.values(PropertyType)}
            />
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
