import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const AboutDeveloperBlock = () => {
  const { t } = useTranslation(["campaignBuilder"]);
  const { aboutDeveloper } = useSelector((state: any) => state.campaignBuilder);
  return (
    <div className="campaign-builder-about-property-container">
      <div className="campaign-builder-main-header">{t("about_developer")}</div>
      <div className="campaign-builder-second-header">
        {aboutDeveloper ? aboutDeveloper : t("about_developer_desc")}
      </div>
    </div>
  );
};
