import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { ReactComponent as SelectArrowBlack } from "../../assets/icons/selectArrowBlack.svg";
import { ReactComponent as SelectArrow } from "../../assets/icons/select_arrow.svg";
import {
  FilterSelectProps,
  IOptionWithText,
  OptionType,
} from "../../types/properties";
import { renderValueWithTrancateLength } from "../../helpers/dataConverts";
import {
  FilterMap,
  getFiltersByIds,
  getFiltersFromMap,
} from "../../constants/propertyData";
import { IFilter } from "../../pages/Portfolio";
import { useMemo } from "react";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export interface CustomSelectStyles {
  select: {
    color: string;
    iconComponent: React.ElementType;
  };
  menuItem: {
    color: string;
    backgroundColor: string;
    hoverBackground: string;
  };
  menuPaperBackground: string;
}

export const ALL = "all";

export const CustomFilterSelect = <T extends OptionType>({
  filterMap = {},
  selectedFilters = [],
  onChange,
  truncateLength,
  styles,
}: {
  filterMap: FilterMap;
  selectedFilters: IFilter[];
  onChange: (filters: IFilter[]) => void;
  truncateLength: number;
  styles: CustomSelectStyles;
}) => {
  const { t } = useTranslation(["filters"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();
  const isRTL = currentLanguage === "he";

  const filters = useMemo(() => getFiltersFromMap(filterMap), [filterMap]);

  const handleChangeOption = (event: SelectChangeEvent<string[]>) => {
    // TODO
    const value = event.target.value as string[];

    if (value.includes(ALL)) {
      let eq = false;

      if (filters.length == selectedFilters.length) {
        eq = true;
        for (let i = 0; i < filters.length; i++) {
          if (filterMap[i].key != selectedFilters[i].key) {
            eq = false;
            break;
          }
        }
      }

      if (eq) {
        onChange([]);
      } else {
        onChange(filters);
      }
    } else {
      onChange(getFiltersByIds(filterMap, value));
    }
  };

  return (
    <Select
      multiple
      value={selectedFilters.map((filter) => filter.key)}
      onChange={handleChangeOption}
      renderValue={(selected) =>
        renderValueWithTrancateLength(selectedFilters, truncateLength)
      }
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: styles.menuPaperBackground,
            },
          },
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        marginThreshold: 0,
      }}
      sx={{
        color: styles.select.color,
        fontFamily: langTheme.mainFont(),
        fontWeight: isRTL ? 400 : 500,
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-select": {
          padding: 0,
          display: "flex",
          alignItems: "center",
          paddingRight: isRTL ? "0px" : "24px",
          paddingLeft: isRTL ? "24px" : "0px",
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "12px",
          left: isRTL ? "5px" : "auto",
        },
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
          {
            paddingRight: isRTL ? "14px" : "32px",
            paddingLeft: isRTL ? "24px" : "0",
            height: "20px",
          },
      }}
      IconComponent={styles.select.iconComponent}
    >
      <MenuItem
        value={ALL}
        sx={{
          color: styles.menuItem.color,
          backgroundColor: styles.menuItem.backgroundColor,
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 400 : 500,
          "&:hover": {
            backgroundColor: styles.menuItem.hoverBackground,
          },
        }}
      >
        {t("all")}
      </MenuItem>
      {filters.map((filter) => (
        <MenuItem
          value={filter.key}
          key={filter.key}
          sx={{
            color: styles.menuItem.color,
            backgroundColor: styles.menuItem.backgroundColor,
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 400 : 500,
            "&:hover": {
              backgroundColor: styles.menuItem.hoverBackground,
            },
          }}
        >
          {t(filter.translationKey)}
        </MenuItem>
      ))}
    </Select>
  );
};

export const portfolioStyles = {
  select: {
    color: "rgba(0, 0, 0, 1)",
    iconComponent: SelectArrowBlack,
  },
  menuItem: {
    color: "rgba(0, 0, 0, 1)",
    backgroundColor: "rgba(255, 255, 255, 0.82)",
    hoverBackground: "rgba(255, 255, 255, 1)",
  },
  menuPaperBackground: "rgba(255, 255, 255, 0.82)",
};

export const homeStyles = {
  select: {
    color: "#fff",
    iconComponent: SelectArrow,
  },
  menuItem: {
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.84)",
    hoverBackground: "rgba(0, 0, 0, 0.6)",
  },
  menuPaperBackground: "rgba(0, 0, 0, 0.84)",
};
