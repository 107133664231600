import React from "react";
import { useTranslation } from "react-i18next";
import GoogleIcon from "../../assets/icons/google.svg";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { BACKEND_HOST } from "../../helpers/api";

export const SignWithGoogleBtn = ({ type }: { type: string }) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const GoogleSignInButton = () => {
    window.location.href = `${BACKEND_HOST}/connect/google`;
  };

  return (
    <div
      onClick={GoogleSignInButton}
      className="signin-form-google-btn"
      style={{
        direction: i18n.dir(),
        gap: isRTL ? "17px" : "9px",
      }}
    >
      <img src={GoogleIcon} />
      <div
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 400 : 500,
        }}
      >
        {type === "signin"
          ? t("sign_in_with_google")
          : t("sign_up_with_google")}
      </div>
    </div>
  );
};
