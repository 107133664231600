import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { portfolioStyles } from "../_common/CustomFilterSelect";

import { Theme, useMediaQuery } from "@mui/material";
import SearchIcon from "../../assets/icons/search_icon.svg";
import SelectArrowBlack from "../../assets/icons/selectArrowBlack.svg";
import YellowSparkes from "../../assets/icons/YellowSparkels.svg";
import BlueSparkes from "../../assets/icons/blueSparkles.svg";
import {
  locationFilterMap,
  statusFilterMap,
  typeFilterMap,
} from "../../constants/propertyData";
import { formatPrice } from "../../helpers/formatter";
import {
  DEFAULT_PRICE_RANGE,
  DEFAULT_PRICE_RANGE_END,
  DEFAULT_PRICE_RANGE_START,
  IFilterParams,
} from "../../pages/Portfolio";
import { PriceRangeSlider } from "../_common/PriceRangeSlider";
import { MapSwither } from "./map/MapSwither";
import { PortfolioFilterItemWrapper } from "./PortfolioFilterItemWrapper";
import { PortfolioSearchContainer } from "./PortfolioSearchContainer";
import { PortfolioSelect } from "./PortfolioSelect";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { CustomLocationInput } from "../_common/CustomLocationInput";

export const PortfolioDesktopFilterContainer = ({
  filters,
  onChangeFilters,
  search,
  onChangeSearch,
  openSearchPanel,
  setOpenSearchPanel,
}: {
  filters: IFilterParams;
  onChangeFilters: React.Dispatch<React.SetStateAction<IFilterParams>>;
  search: string;
  onChangeSearch: (search: string) => void;
  openSearchPanel: boolean;
  setOpenSearchPanel: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t, i18n } = useTranslation(["portfolio"]);
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isFilterDesktop = useMediaQuery("(max-width: 1130px)");
  const isFilterDesktop2 = useMediaQuery("(max-width: 1157px)");

  const [searchValue, setSearchValue] = React.useState(search);
  const [priceRangeValue, setPriceRangeValue] = React.useState(
    filters.priceRange
  );
  const [locationValues, setLocationValues] = React.useState(filters.locations);
  const [statusValues, setStatusValues] = React.useState(filters.statuses);
  const [typeValues, setTypeValues] = React.useState(filters.types);

  const handleClearFilters = () => {
    // setLocationValues([]);
    // setTypeValues([]);
    // setStatusValues([]);
    // setPriceRangeValue(DEFAULT_PRICE_RANGE);

    onChangeFilters({
      locations: [],
      statuses: [],
      types: [],
      priceRange: DEFAULT_PRICE_RANGE,
    });
  };

  useEffect(() => {
    if (search) {
      setOpenSearchPanel(true);
    }
    setSearchValue(search);
    setLocationValues(filters.locations);
    setTypeValues(filters.types);
    setStatusValues(filters.statuses);
    setPriceRangeValue(filters.priceRange);
  }, [filters, search]);

  // PRICING
  const [openPriceRangePanel, setOpenPriceRangePanel] = React.useState(false);
  const panelRef = React.useRef<HTMLDivElement>(null);

  const handleOpenPriceRangePanel = () => {
    setOpenPriceRangePanel(!openPriceRangePanel);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setOpenPriceRangePanel(false);
    }
  };

  React.useEffect(() => {
    if (openPriceRangePanel) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPriceRangePanel]);
  // PRICING

  const toggleSearchPanel = () => {
    const newVal = !openSearchPanel;
    if (newVal == false) {
      onChangeSearch("");
    }
    setOpenSearchPanel(newVal);
  };

  const handleSubimt = useCallback(() => {
    if (openSearchPanel) {
      onChangeSearch(searchValue);
    } else {
      onChangeFilters({
        locations: locationValues,
        statuses: statusValues,
        types: typeValues,
        priceRange: priceRangeValue,
      });
    }
  }, [
    openSearchPanel,
    searchValue,
    priceRangeValue,
    locationValues,
    statusValues,
    typeValues,
  ]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        direction: i18n.dir(),
      }}
    >
      <div
        className="xxl-width"
        style={{
          display: "flex",
          width: "100%",
          padding: "0 20px",
          justifyContent: "space-between",
        }}
      >
        {openSearchPanel ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "20px",
            }}
          >
            <PortfolioSearchContainer
              openSearchPanel={openSearchPanel}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              onChangeSearch={onChangeSearch}
            />
            <div
              style={{
                marginTop: "4px",
                marginRight: isRTL ? "0px" : "20px",
                marginLeft: isRTL ? "20px" : "0px",
              }}
            >
              <MapSwither />
            </div>
          </div>
        ) : (
          <div
            className="portfolio-filter-desktop-wrapper xl-width"
            style={{
              direction: i18n.dir(),
              gap: downLg ? "6px" : "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                gap: downLg ? "6px" : "12px",
                alignItems: "center",
              }}
            >
              <PortfolioFilterItemWrapper name={t("location")}>
                <div
                  style={{
                    maxWidth: "210px",
                    width: "100%",
                  }}
                >
                  <PortfolioSelect
                    filterMap={locationFilterMap}
                    selectedFilters={locationValues}
                    onChange={setLocationValues}
                    truncateLength={isFilterDesktop ? 10 : 15}
                    styles={portfolioStyles}
                  />
                </div>
              </PortfolioFilterItemWrapper>

              {/* //new format */}
              {/* <CustomLocationInput
                selectedLocation={locationValues}
                onChange={setLocationValues}
              /> */}

              {/* //new format */}

              <PortfolioFilterItemWrapper name={t("property_type")}>
                <PortfolioSelect
                  filterMap={typeFilterMap}
                  selectedFilters={typeValues}
                  onChange={setTypeValues}
                  truncateLength={isFilterDesktop ? 12 : 15}
                  styles={portfolioStyles}
                />
              </PortfolioFilterItemWrapper>

              <div
                className="price-range-panel-wrapper"
                ref={panelRef}
                style={{
                  maxWidth: "210px",
                  width: "100%",
                  minWidth: "153px",
                }}
              >
                <div
                  className="price-range-panel-container"
                  onClick={handleOpenPriceRangePanel}
                  style={{
                    fontFamily: langTheme.mainFont(),
                    fontWeight: isRTL ? 400 : 500,
                  }}
                >
                  <span
                    style={{
                      color: "rgba(0, 0, 0, 0.59)",
                      fontSize: "14px",
                      marginRight: "15px",
                    }}
                  >
                    {t("price_range")}
                  </span>
                  <div
                    className="portfolio-price-range-container"
                    style={{
                      marginTop: "10px",
                      height: "20px",
                      justifyContent: priceRangeValue
                        ? "space-between"
                        : "flex-end",
                    }}
                  >
                    {priceRangeValue && (
                      <div
                        className="portfolio-price-range-container-price"
                        style={{
                          flexDirection: isRTL ? "row-reverse" : "row",
                        }}
                      >
                        <div>{isRTL ? "₪" : "$"}</div>
                        <div>{`${formatPrice(
                          priceRangeValue.start
                        )}-${formatPrice(priceRangeValue.end)}`}</div>
                      </div>
                    )}

                    {openPriceRangePanel ? (
                      <div>
                        <img
                          src={SelectArrowBlack}
                          style={{
                            transform: "rotate(-180deg)",
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img src={SelectArrowBlack} />
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="portfolio-filter-price-range-container-test"
                  style={{
                    fontFamily: langTheme.mainFont(),
                    fontWeight: isRTL ? 400 : 500,
                    backgroundColor: "rgba(255, 255, 255, 0.82)",
                    display: openPriceRangePanel ? "block" : "none",
                    zIndex: "21",
                    boxShadow: " 0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <PriceRangeSlider
                    priceRangeValue={priceRangeValue}
                    setPriceRangeValue={setPriceRangeValue}
                    styles={{ color: "rgba(0, 0, 0, 0.63)" }}
                  />
                </div>
              </div>

              <PortfolioFilterItemWrapper name={t("status")}>
                <PortfolioSelect
                  filterMap={statusFilterMap}
                  selectedFilters={statusValues}
                  onChange={setStatusValues}
                  truncateLength={
                    isFilterDesktop2 ? 13 : isFilterDesktop ? 12 : 18
                  }
                  styles={portfolioStyles}
                />
              </PortfolioFilterItemWrapper>

              <MapSwither />

              <div
                onClick={handleClearFilters}
                style={{
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  cursor: "pointer",
                  textWrap: "nowrap",
                  marginTop: "25px",

                  color:
                    locationValues.length === 0 &&
                    typeValues.length === 0 &&
                    statusValues.length === 0 &&
                    priceRangeValue.start === DEFAULT_PRICE_RANGE_START &&
                    priceRangeValue.end === DEFAULT_PRICE_RANGE_END
                      ? "rgba(0, 0, 0, 0.43)"
                      : "rgba(0, 0, 0, 1)",
                }}
              >
                {t("clean_all")}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                  marginTop: "27px",
                }}
              >
                <div
                  className="portfolio-filter-second-search-btn-container"
                  onClick={handleSubimt}
                >
                  <img src={SearchIcon} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: isFilterDesktop ? "10px" : "18px",
              }}
            ></div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {openSearchPanel ? (
            <div
              className="portfolio-filter-ai-sparkles-container"
              onClick={toggleSearchPanel}
              style={{
                gap: "5px",
                marginTop: "-42px",
                width: "116px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  color: "rgba(3, 74, 115, 1)",
                }}
              >
                {t("filters")}
              </p>
            </div>
          ) : (
            <div
              className="portfolio-filter-ai-sparkles-container"
              onClick={toggleSearchPanel}
              style={{
                gap: "5px",
                width: "116px",
                alignItems: "center",
              }}
            >
              <img
                src={YellowSparkes}
                alt="sparkles"
                style={{ width: "18px", height: "18px" }}
              />
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  color: "rgba(215, 161, 72, 1)",
                }}
              >
                {t("search")}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
