import React from "react";
import BackIcon from "../../assets/campaignBuilder/back.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const CampaingBuilderGoToPrevPageBtn = () => {
  const { t } = useTranslation(["campaignBuilder"]);
  const navigate = useNavigate();
  return (
    <div className="campaign-builder-sidenav-back" onClick={() => navigate(-1)}>
      <div>
        <img src={BackIcon} />
      </div>
      <div className="campaign-builder-sidenav-back-text">{t("back")}</div>
    </div>
  );
};
