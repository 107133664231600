import React from "react";
import {
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ReactComponent as SelectArrow } from "../../assets/icons/select_arrow_black.svg";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ISortParams } from "../../pages/Portfolio";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export interface ISortOption {
  id: number;
  valueEn: string;
  valueHe: string;
  sortDirection: string;
  nameSortParam: string;
  translationKey: string;
}

export const PropertiesSortSelect = ({
  options,
  initSort,
  onSortChange,
}: {
  options: ISortOption[];
  initSort: ISortParams;
  onSortChange: (sortParams: ISortParams) => void;
}) => {
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();
  const isRTL = currentLanguage === "he";
  const { t } = useTranslation(["filters"]);

  const initialSelectedOption = React.useMemo(() => {
    const matchedOption = options.find(
      (option) =>
        option.nameSortParam === initSort.field &&
        option.sortDirection === initSort.direction
    );

    return matchedOption || options[0];
  }, [initSort, options]);

  const [selectedOption, setSelectedOption] = React.useState<ISortOption>(
    initialSelectedOption
  );

  React.useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleChangeOption = (event: SelectChangeEvent<string>) => {
    // TODO - refactor to map lookup
    const selected = options.find(
      (option) => option.id.toString() === event.target.value
    );

    if (!selected) {
      return;
    }

    setSelectedOption(selected);
    onSortChange({
      field: selected.nameSortParam,
      direction: selected.sortDirection,
    });
  };

  return (
    <Select
      fullWidth
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(255, 255, 255, 1)",
            },
          },
        },
      }}
      sx={{
        direction: i18n.dir(),
        backgroundColor: "rgba(3, 74, 115, 0.05)",
        borderRadius: "30px",
        width: "231px",
        fontWeight: 500,
        color: "rgba(0, 0, 0, 1)",
        fontFamily: langTheme.mainFont(),
        height: "42px",
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
          {
            paddingRight: isRTL ? 0 : "",
            paddingLeft: isRTL ? "10px" : "0",
          },
        "& .MuiOutlinedInput-input": {
          paddingRight: 0,
        },
      }}
      input={
        <OutlinedInput
          sx={{
            padding: 0,
          }}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                mr: isRTL ? "0" : "5px",
                ml: isRTL ? "5px" : "0",
              }}
            >
              <div
                style={{
                  color: "rgba(0, 0, 0, 1)",
                  fontFamily: langTheme.mainFont(),
                  fontSize: "16px",
                  paddingLeft: isRTL ? 0 : "10px",
                  paddingRight: isRTL ? "20px" : "0px",
                  fontWeight: 500,
                }}
              >
                {t("sort")}:
              </div>
            </InputAdornment>
          }
        />
      }
      value={selectedOption.id.toString()}
      onChange={handleChangeOption}
      IconComponent={SelectArrow}
    >
      {options.map((option: ISortOption, index: number) => (
        <MenuItem
          value={option.id.toString()}
          key={option.id}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            fontSize: "14px",
            direction: i18n.dir(),
            "&:hover": {
              backgroundColor: "#acc3d954",
            },
          }}
        >
          {/* {isRTL ? option.valueHe : option.valueEn} */}
          {t(option.translationKey)}
        </MenuItem>
      ))}
    </Select>
  );
};
