import React, { useCallback, useEffect, useState } from "react";
import { FilterSideBarSelect } from "./FilterSideBarSelect";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import {
  activePropertyFiltersSelector,
  defaultActiveFilters,
  setActivePropertiesFilter,
  setFilterSideBarClose,
} from "../../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  IPropertyActiveFilters,
  IPropertySearchParamObj,
} from "../../types/properties";
import { PriceRangeSlider } from "../_common/PriceRangeSlider";
import {
  locationFilterMap,
  statusFilterMap,
  typeFilterMap,
} from "../../constants/propertyData";
import {
  DEFAULT_PRICE_RANGE,
  IFilter,
  IFilterParams,
  IPriceRange,
} from "../../pages/Portfolio";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const BuyTabPanelContent = ({
  filters,
  onChangeFilters,
  setSearchValue,
  priceRangeValue,
  setPriceRangeValue,
  locationValues,
  setLocationValues,
  statusValues,
  setStatusValues,
  typeValues,
  setTypeValues,
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  priceRangeValue: IPriceRange;
  setPriceRangeValue: React.Dispatch<React.SetStateAction<IPriceRange>>;
  locationValues: IFilter[];
  setLocationValues: React.Dispatch<React.SetStateAction<IFilter[]>>;
  statusValues: IFilter[];
  setStatusValues: React.Dispatch<React.SetStateAction<IFilter[]>>;
  typeValues: IFilter[];
  setTypeValues: React.Dispatch<React.SetStateAction<IFilter[]>>;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const submitFilters = useCallback(() => {
    onChangeFilters({
      locations: locationValues,
      statuses: statusValues,
      types: typeValues,
      priceRange: priceRangeValue,
    });
    dispatch(setFilterSideBarClose());
  }, [locationValues, statusValues, typeValues, priceRangeValue]);

  return (
    <div className="filter-side-bar-filters-container">
      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("location")}
        </span>
        <FilterSideBarSelect
          filterMap={locationFilterMap}
          selectedFilters={locationValues}
          onChange={setLocationValues}
          truncateLength={40}
        />
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("property_type")}
        </span>
        <FilterSideBarSelect
          filterMap={typeFilterMap}
          selectedFilters={typeValues}
          onChange={setTypeValues}
          truncateLength={40}
        />
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("price_range")}
        </span>
        <div
          style={{
            padding: "0 10px",
          }}
        >
          <PriceRangeSlider
            priceRangeValue={priceRangeValue}
            setPriceRangeValue={setPriceRangeValue}
            styles={{ color: "rgba(0, 0, 0, 0.63)" }}
          />
        </div>
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("status")}
        </span>
        <FilterSideBarSelect
          filterMap={statusFilterMap}
          selectedFilters={statusValues}
          onChange={setStatusValues}
          truncateLength={40}
        />
      </div>

      <button
        onClick={submitFilters}
        className="filter-side-bar-search-btn"
        style={{
          fontFamily: langTheme.mainFont(),
          cursor: "pointer",
        }}
      >
        {t("search")}
      </button>
    </div>
  );
};
