import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const LANG_HE = "he";
export const LANG_EN = "en";

export class LanguageTheme {
  constructor(private language: string, private dir: string) {
    this.language = language;
    this.dir = dir;
  }

  setNewLangAndDir(language: string, dir: string) {
    this.language = language;
    this.dir = dir;
  }

  getDirStyle(rtl: string, ltr: string): string {
    switch (this.dir) {
      case "rtl":
        return rtl;
      case "ltr":
        return ltr;
      default:
        return ltr;
    }
  }

  mainFont() {
    switch (this.language) {
      case LANG_HE:
        return "Noto Sans Hebrew";
      default: // LANG_EN
        return "DM Sans";
    }
  }

  getFlexDir(): string {
    switch (this.dir) {
      case "rtl":
        return "...";
      case "ltr":
        return "...";
      default:
        return "...";
    }
  }
}
// getDirStyle(i18n.dir(), "20px", "auto")

export function useLanguageTheme(): LanguageTheme {
  const { i18n } = useTranslation();

  const langTheme = new LanguageTheme(i18n.language, i18n.dir());

  useEffect(() => {
    langTheme.setNewLangAndDir(i18n.language, i18n.dir());
  }, [i18n.language]);

  return langTheme;
}

