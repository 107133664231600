import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  HighlightIconMap
} from "../../constants/campaignBuilderData";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import { HighlightSelect } from "./HighlightSelect";

export const InvestmentHighlights = ({
  setCurrentStep,
  hightlightOptionsState,
  selectedHightLightIds,
  setSelectedHightLightIds,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  hightlightOptionsState: any;
  selectedHightLightIds: any;
  setSelectedHightLightIds: any;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleNext = (step: CampaignSideNavStep) => {
    //TODO add validations
    setCurrentStep(step);
  };

  const handleChangeHightlightState = (index: number, hightlightid: string) => {
    setSelectedHightLightIds((prev: any) => {
      const newIds = [...prev];
      newIds[index] = hightlightid;
      return newIds;
    });
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="investment_highlights"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.labels}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.aboutProperty}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div>
          <div className="campaign-builder-sidenav-settings-fileds-container">
            {selectedHightLightIds.map((selectedId: string, index: number) => (
              <div
                key={index}
                className="campaign-builder-sidenav-settings-fileds-item"
              >
                <div className="select-highlight-container">
                  <div className="campaign-builder-sidenav-field-name">
                    {t("select")}
                  </div>
                  <div className="campaign-builder-sidenav-field-name highlight">
                    {t("highlight")}
                  </div>
                </div>
                <HighlightSelect
                  value={selectedId}
                  onChangeValue={(newId: any) =>
                    handleChangeHightlightState(index, newId)
                  }
                  options={hightlightOptionsState}
                  iconMap={HighlightIconMap}
                />
              </div>
            ))}
          </div>
          <div
            className="campaign-builder-sidenav-add-new-btn"
            onClick={() => setCurrentStep(CampaignSideNavStep.addaNewHighlight)}
          >
            {t("add_new")}
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
