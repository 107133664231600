import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "../../assets/campaignBuilder/upload_files.svg";
import { ImageFormatMap } from "./CampaignMediaStep";

export const FileDropZone = ({
  fileAcceptFormat,
  limit,
  setFiles,
  placeholder,
}: {
  fileAcceptFormat: ImageFormatMap;
  limit: number;
  setFiles?: any;
  placeholder: string;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileAcceptFormat,
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles: any[]) => {
        const newFiles = acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: `${file.name}-${index}-${Date.now()}`,
          })
        );

        return [...prevFiles, ...newFiles].slice(0, limit);
      });
    },
  });

  // useEffect(() => {
  //   return () =>
  //     files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  // }, [files]);

  return (
    <div>
      <div className="drag-and-drop-area-wrapper">
        <div
          {...getRootProps({ className: "dropzone" })}
          className="drag-and-drop-area-container"
        >
          <img src={UploadFileIcon} className="drag-and-drop-area-upload-img" />
          <div className="drag-and-drop-area-upload-img-text">
            {t(placeholder)}
          </div>
          <input {...getInputProps()} />
        </div>
      </div>
    </div>
  );
};
