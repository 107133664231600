import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowLeft from "../../assets/icons/arrow_left_blue.svg";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const BackBtnAuth = () => {
  const navigate = useNavigate();
  const langTheme = useLanguageTheme();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();

  const location = useLocation();

  const handleBackClick = () => {
    if (location.state?.fromForgotPassword) {
      navigate("/home");
    } else {
      navigate(-1);
    }
  };

  return (
    <div onClick={handleBackClick} className="back-btn-auth">
      <img
        src={ArrowLeft}
        style={{
          transform: i18n.dir() === "rtl" ? "scaleX(-1)" : "scaleX(1)",
        }}
      />
      <div
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: 500,
        }}
      >
        {t("back")}
      </div>
    </div>
  );
};
