import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FloorplanIcon from "../../assets/campaignBuilder/floorplan.svg";
import MapIcon from "../../assets/campaignBuilder/map.svg";
import PhotoIcon from "../../assets/campaignBuilder/photo.svg";
import VideoIcon from "../../assets/campaignBuilder/video.svg";
import { MediaDialog } from "./MediaDialog";
import { PreviewMode } from "./PreviewModeSwiter";

export type MediaType = "photos" | "floorplan" | "video" | "";

interface DialogState {
  open: boolean;
  type: MediaType | null;
}

export const MediaUploadPanel = () => {
  const { t } = useTranslation(["campaignBuilder"]);

  const {
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
    previewMode,
  } = useSelector((state: any) => state.campaignBuilderSetup);

  const { imgFilesSlice, floorPlanFilesSlice, videoFilesSlice } = useSelector(
    (state: any) => state.campaignBuilder
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [dialogState, setDialogState] = React.useState<DialogState>({
    open: false,
    type: null,
  });

  const handleOpenDialog = (e: any, type: MediaType) => {
    e.stopPropagation();

    const filesForType = getFilesForType(type);

    if (filesForType && filesForType.length > 0) {
      setDialogState({ open: true, type });
    }
  };

  const handleCloseDialog = () => {
    setDialogState({ open: false, type: null });
  };

  const getFilesForType = (type: MediaType | null) => {
    switch (type) {
      case "photos":
        return imgFilesSlice;
      case "floorplan":
        return floorPlanFilesSlice || [];
      case "video":
        return videoFilesSlice || [];
      default:
        return [];
    }
  };

  return (
    <div
      className="meadia-upload-panel"
      style={{
        gap: previewMode === PreviewMode.MOBILE || downMd ? "10px" : "15px",
      }}
    >
      <MediaDialog
        files={getFilesForType(dialogState.type)}
        open={dialogState.open}
        setOpen={handleCloseDialog}
        mediaType={dialogState.type}
      />

      <div
        className="meadia-upload-panel-item"
        onClick={(e) => handleOpenDialog(e, "photos")}
      >
        <img src={PhotoIcon} />
        {previewMode === PreviewMode.MOBILE || downMd ? (
          <div>{imgFilesSlice.length}</div>
        ) : (
          <div>
            {imgFilesSlice.length} {t("photos")}
          </div>
        )}
      </div>

      {floorPlanSectionOpen && (
        <div
          className="meadia-upload-panel-item"
          onClick={(e) => handleOpenDialog(e, "floorplan")}
        >
          <img src={FloorplanIcon} />
          {previewMode === PreviewMode.DESKTOP && <div>{t("floorplan")}</div>}
        </div>
      )}

      {videoSectionOpen && (
        <div
          className="meadia-upload-panel-item"
          onClick={(e) => handleOpenDialog(e, "video")}
        >
          <img src={VideoIcon} />
          {previewMode === PreviewMode.DESKTOP && <div>{t("video")}</div>}
        </div>
      )}

      {mapSectionOpen && (
        <div className="meadia-upload-panel-item">
          <img src={MapIcon} />
          {previewMode === PreviewMode.DESKTOP && <div>{t("map")}</div>}
        </div>
      )}
    </div>
  );
};
