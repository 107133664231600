import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLanguageTheme } from '../../hooks/useLanguageTheme';
import { useUserProfile } from '../../hooks/useUserProfile';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grow,
  InputLabel,
  Menu,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../assets/icons/select_arrow_black.svg';
import { Language } from './LangToggle';

export const ProfileSelect = () => {
  const { t } = useTranslation(['navbar']);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === 'he';
  const langTheme = useLanguageTheme();
  const navigate = useNavigate();
  const {
    profile,
    isLoading,
    error,
  }: { profile: any; isLoading: any; error: any } = useUserProfile();
  const profilePicture = profile?.profilePhoto?.formats?.thumbnail?.url;
  const [fullName, setFullName] = useState('');
  useEffect(() => {
    if (profile && profile.fullName) {
      setFullName(profile.fullName.split(' ')[0]);
    }
  }, [profile]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('user');
    window.location.reload();
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleCampaignClick = () => {
    navigate('/campaign');
  };

  const handleCrmClick = () => {
    navigate('/crm');
  };

  const [currency, setCurrency] = useState('usd');
  const [selectedLang, setSelectedLang] = useState<Language>(
    (localStorage.getItem('lang') as Language) || Language.ENGLISH,
  );

  const handleLanguageChange = (event: any) => {
    const newLang =
      selectedLang === Language.ENGLISH ? Language.HEBREW : Language.ENGLISH;
    setSelectedLang(newLang);
    localStorage.setItem('lang', newLang);
    i18n.changeLanguage(newLang);
  };

  const handleCurrencyChange = (event: any) => {
    setCurrency(event.target.value);
  };

  if (isLoading) {
    return <div>Loading user data...</div>;
  }

  if (error) {
    return <div>Error loading user data: {error.message}</div>;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      {profilePicture ? (
        <Avatar
          src={profilePicture}
          alt="picture"
          sx={{
            width: '50px',
            height: '50px',
          }}
          onClick={handleProfileClick}
        />
      ) : (
        <Avatar
          alt="picture"
          sx={{
            bgcolor: '#E8F4F8',
            color: '#034A736E',
            width: '50px',
            height: '50px',
          }}
          onClick={handleProfileClick}
        >
          {fullName.charAt(0).toUpperCase()}
        </Avatar>
      )}
      <div style={{ height: '100%' }}>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            fontFamily: langTheme.mainFont(),
            color: '#000000',
            textTransform: 'none',
          }}
        >
          {fullName}
          <img src={Arrow} alt="arrow" style={{ marginLeft: '5px' }} />
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Grow}
        >
          <MenuItem onClick={handleProfileClick}>{t('my_profile')}</MenuItem>
          <MenuItem onClick={handleCampaignClick}>{t('campaigns')}</MenuItem>
          <MenuItem onClick={handleCrmClick}>{t('crm')}</MenuItem>
          <MenuItem onClick={handleLogOut}>{t('log_out')}</MenuItem>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box mx={2} my={1}>
              <FormControl variant="standard" fullWidth>
                <Select
                  value={selectedLang}
                  onChange={handleLanguageChange}
                  label="Language"
                >
                  <MenuItem value="he">עב</MenuItem>
                  <MenuItem value="en">En</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* <Box mx={2} my={1}>
              <FormControl variant="standard" fullWidth>
                <Select value={currency} onChange={handleCurrencyChange}>
                  <MenuItem value="usd">USD</MenuItem>
                  <MenuItem value="nis">NIS</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
          </Box>
        </Menu>
      </div>
    </div>
  );
};
