import { InputAdornment, TextField, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { textFieldStyles } from "./MainInformationStep";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import React, { useState } from "react";
import { ColorDropDown, colors } from "./ColorDropDown";
import { IconSelect } from "./IconSelect";
import { PreviouslyAdded } from "./PreviouslyAdded";
import {
  HighlightIconMap,
  highlightOptions,
} from "../../constants/campaignBuilderData";

export const MAX_HIGHTLIGHT_LENGTH = 60;

export const AddNewHightlight = ({
  setCurrentStep,
  hightlightOptionsState,
  setHightlightOptionsState,
  addedHightlightOptions,
  setAddedHightlightOptions,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  hightlightOptionsState: any;
  setHightlightOptionsState: any;
  addedHightlightOptions: any;
  setAddedHightlightOptions: any;
}) => {
  const langTheme = useLanguageTheme();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [hightlightName, setHightlightName] = React.useState("");
  const [selectedIconKey, setSelectedIconKey] =
    React.useState<keyof typeof HighlightIconMap>("location");

  const handleChangeHightlightName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setHightlightName(value);
  };

  const handleAddOption = (event: any) => {
    const newOption = {
      id: crypto.randomUUID(),
      labelName: hightlightName,
      icon_key: selectedIconKey,
      selectId: crypto.randomUUID()
    };

    setAddedHightlightOptions([...addedHightlightOptions, newOption]);
    setHightlightOptionsState([...hightlightOptionsState, newOption]);
  };

  const handleDeleteOption = (id: string) => {
    const updatedAddedOptions = addedHightlightOptions.filter(
      (option: any) => option.id !== id
    );
    setAddedHightlightOptions(updatedAddedOptions);

    const updatedLabelOptionsState = hightlightOptionsState.filter(
      (option: any) => option.id !== id
    );
    setHightlightOptionsState(updatedLabelOptionsState);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="add_a_new_highlight"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.investmentHighlights}
          />
        }
        rightBtn={<div className="campaign-builder-sidenav-empty-btn"></div>}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("message")}
              </div>

              <TextField
                fullWidth
                placeholder={t("message_placeholder")}
                value={hightlightName}
                onChange={handleChangeHightlightName}
                sx={textFieldStyles(langTheme.mainFont())}
                autoComplete="off"
                inputProps={{
                  maxLength: MAX_HIGHTLIGHT_LENGTH,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        height: "100%",
                        ml: 0,
                        maxHeight: "none",
                        marginRight: isRTL ? "-14px" : "0",
                        pl: isRTL ? "20px" : "0px",
                        pr: isRTL ? "-14px" : "-14px",
                        color: "rgba(0, 0, 0, 1)",
                      }}
                      position="start"
                    >
                      <div
                        className="campaign-builder-sidenav-number-of-char"
                        style={{
                          fontFamily: langTheme.mainFont(),
                        }}
                      >
                        {hightlightName.length}/{MAX_HIGHTLIGHT_LENGTH}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("choose_icon")}
              </div>

              <IconSelect
                selectedIcon={selectedIconKey}
                setSelectedIcon={setSelectedIconKey}
                options={highlightOptions}
                iconMap={HighlightIconMap}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-line"></div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div
                className="campaign-builder-sidenav-field-name"
                style={{
                  fontSize: "16px",
                }}
              >
                {t("previously_added")}
              </div>
            </div>
          </div>

          {addedHightlightOptions.map((option: any, index: any) => (
            <div key={index}>
              <PreviouslyAdded
                option={option}
                handleDelete={handleDeleteOption}
                iconMap={HighlightIconMap}
              />
            </div>
          ))}
        </div>

        <div
          className="campaign-builder-sidenav-add-label-btn-big"
          onClick={handleAddOption}
        >
          {t("add_this_hightlight")}
        </div>
      </div>
    </div>
  );
};
