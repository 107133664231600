import { Drawer } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { CampaignSetUpStep } from "./CampaignSetUpStep";
import { CampaignSettitngsStep } from "./CampaignSettitngsStep";
import { MainInformationStep } from "./MainInformationStep";
import { CampaignMediaStep } from "./CampaignMediaStep";
import {
  campaignBuilderOpenSelector,
  setCampaingBuilderSideBarClose,
} from "../../store/features/common";
import { CampaignLabelsStep } from "./CampaignLabelsStep";
import { AddNewLabel } from "./AddNewLabel";
import { InvestmentHighlights } from "./InvestmentHighlights";
import { AddNewHightlight } from "./AddNewHightlight";
import { AboutProperty } from "./AboutProperty";
import { KeyFeature } from "./KeyFeature";
import { AboutDeveloper } from "./AboutDeveloper";
import { AddNewFeature } from "./AddNewFeature";
import {
  highlightOptions,
  keyFeatureOptions,
  labelOptions,
} from "../../constants/campaignBuilderData";
import { filterOptions } from "../../helpers/dataConverts";
import {
  setHightlightsBack,
  setKeyFeatureBack,
  setLabelBack,
} from "../../store/features/campaignBuilderSlice";
import { ContactInformation } from "./ContactInformation";
import { findOptionById } from "../../helpers/formatter";
import { useParams } from "react-router-dom";
import { BuilderMode } from "../../pages/CampaignBuilder";

export const campaignBuilderDrawerWidth = "443px";

export enum CampaignSideNavStep {
  campaignSetUp = "campaignSetUp",
  settings = "settings",
  mainInformation = "mainInformation",
  media = "media",
  labels = "labels",
  investmentHighlights = "investmentHighlights",
  aboutProperty = "aboutProperty",
  keyFeatures = "keyFeatures",
  aboutDeveloper = "aboutDeveloper",
  contactInformation = "contactInformation",
  addNewALabel = "addNewALabel",
  addaNewHighlight = "addaNewHighlight",
  addaNewFeature = "addaNewFeature",
}

export enum Language {
  ENGLISH = "english",
  HEBREW = "hebrew",
}

export enum Currency {
  NIS = "nis",
  USD = "usd",
}

export const CurrencyMap: Record<Currency, string> = {
  [Currency.NIS]: "₪",
  [Currency.USD]: "$",
};

export enum TimePeriod {
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
}

export enum PropertyStatus {
  UNDER_CONSTRUCTION = "under_construction",
  READY_FOR_OCCUPANCY = "ready_for_occupancy",
  PRE_SALE = "pre_sale_on_paper",
  AVAILABLE = "immediate_availability",
  COMING_SOON = "coming_soon",
}

export const propertyStatusToNumber: Record<PropertyStatus, number> = {
  [PropertyStatus.UNDER_CONSTRUCTION]: 1,
  [PropertyStatus.READY_FOR_OCCUPANCY]: 2,
  [PropertyStatus.PRE_SALE]: 3,
  [PropertyStatus.AVAILABLE]: 4,
  [PropertyStatus.COMING_SOON]: 5,
};

export enum PropertyType {
  PENTHOUSE = "penthouse",
  GARDEN_APARTMENT = "garden_apartment",
  DUPLEX = "duplex",
  TRIPLEX = "triplex",
  VILLA_HOUSE = "villa_house",
  TOWNHOUSE = "townhouse",
  ROOFTOP_APARTMENT = "rooftop_apartment",
  STUDIO_APARTMENT_PREMIUM = "studio_apartment_premium",
  OFFICE_SPACE = "office_space",
  RETAIL_SPACE = "retail_space",
  MIXED_USE_DEVELOPMENT = "mixed_use_development",
  COMMERCIAL_BUILDING = "commercial_building",
  BUSINESS_CENTER = "business_center",
  LUXURY_APARTMENT = "luxury_apartment",
  LUXURY_TOWER = "luxury_tower",
}

export const CampaignBuilderSideNav = ({
  currentStep,
  setCurrentStep,
  setImgFiles,
  newAgentAvatar,
  setNewAgentAvatar,
  setBrochureFiles,
  setFloorplanFiles,
  setVideoFiles,
  builderMode,
  initFromBackend,
  setInitFromBackend,
  isImgsLoading,
  isBrochureLoading,
  isVideoLoading,
  isFloorPlanLoading,
  isAvatarLoading,
}: {
  currentStep: CampaignSideNavStep;
  setCurrentStep: (step: CampaignSideNavStep) => void;
  setImgFiles: React.Dispatch<React.SetStateAction<File[]>>;
  newAgentAvatar: File[];
  setNewAgentAvatar: React.Dispatch<React.SetStateAction<File[]>>;
  setBrochureFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setFloorplanFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setVideoFiles: React.Dispatch<React.SetStateAction<File[]>>;
  builderMode: BuilderMode;
  initFromBackend: any;
  setInitFromBackend: any;
  isImgsLoading: boolean;
  isBrochureLoading: boolean;
  isVideoLoading: boolean;
  isFloorPlanLoading: boolean;
  isAvatarLoading: boolean;
}) => {
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);

  const [selectedLabelId, setSelectedLabelId] = React.useState(
    labelOptions[0].id
  );
  const [labelOptionsState, setLabelOptionsState] =
    React.useState(labelOptions);

  const [addedLabelOptions, setAddedLabelOptions] = React.useState<any>([]);

  const [addedHightlightOptions, setAddedHightlightOptions] =
    React.useState<any>([]);
  const [hightlightOptionsState, setHightlightOptionsState] =
    React.useState(highlightOptions);

  const [selectedHightLightIds, setSelectedHightLightIds] = React.useState<
    string[]
  >([
    highlightOptions[0].id,
    highlightOptions[1].id,
    highlightOptions[2].id,
    highlightOptions[3].id,
  ]);

  const [keyfeatureOptionsState, setKeyfeatureOptionsState] =
    React.useState(keyFeatureOptions);

  const { keyFeaturesBack, hightlightsBack, labelBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const [addedKeyFeatureOptions, setAddedKeyFeatureOptions] =
    React.useState<any>([]);

  const [selectedFeatureIds, setSelectedFeatureIds] = React.useState<string[]>([
    keyFeatureOptions[0].id,
    keyFeatureOptions[1].id,
    keyFeatureOptions[2].id,
    keyFeatureOptions[3].id,
    keyFeatureOptions[4].id,
    keyFeatureOptions[5].id,
  ]);

  useEffect(() => {
    setInitFromBackend(builderMode == BuilderMode.EDIT);
  }, [builderMode]);

  useEffect(() => {
    if (keyFeaturesBack.length < 1) {
      return;
    }
  }, [keyFeaturesBack]);

  React.useEffect(() => {
    if (!initFromBackend) {
      return;
    }

    if (keyFeaturesBack.length > 0) {
      const notDefaultKeyFeatures: any[] = [];
      const selectedKeyFeatureIds: string[] = [];
      for (const feature of keyFeaturesBack) {
        // if (keyFeatureOptions.every((el) => el.id !== feature.id)) {
        notDefaultKeyFeatures.push(feature);
        // }
        selectedKeyFeatureIds.push(feature.id);
      }

      // setAddedKeyFeatureOptions(notDefaultKeyFeatures);
      setSelectedFeatureIds(selectedKeyFeatureIds);

      setKeyfeatureOptionsState([...notDefaultKeyFeatures]);
    }
    if (hightlightsBack.length > 0) {
      const notDefaultHightlights: any[] = [];
      const selectedHightlightsIds: string[] = [];
      for (const hightlight of hightlightsBack) {
        if (
          highlightOptions.filter((el) => el.id == hightlight.id).length == 0
        ) {
          notDefaultHightlights.push(hightlight);
        }
        selectedHightlightsIds.push(hightlight.id);
      }
      setAddedHightlightOptions(notDefaultHightlights);
      setSelectedHightLightIds(selectedHightlightsIds);
      setHightlightOptionsState((prev) => {
        const dedup = [];
        for (const feat of notDefaultHightlights) {
          if (prev.filter((el) => el.id == feat.id).length == 0) {
            dedup.push(feat);
          }
        }

        return [...prev, ...dedup];
      });
    }

    if (labelBack) {
      const notDefaultLabels: any[] = [];

      const labelExists = labelOptions.some(
        (label) => label.id === labelBack.id
      );

      if (!labelExists) {
        notDefaultLabels.push(labelBack);
        setSelectedLabelId(labelBack.id);
      }

      setAddedLabelOptions(notDefaultLabels);
      setLabelOptionsState((prev) => {
        const dedup = [];
        for (const feat of notDefaultLabels) {
          if (prev.filter((el) => el.id == feat.id).length == 0) {
            dedup.push(feat);
          }
        }

        return [...prev, ...dedup];
      });
    }
    setInitFromBackend(false);
  }, [keyFeaturesBack, initFromBackend, hightlightsBack, labelBack]);

  React.useEffect(() => {
    if (initFromBackend) {
      return;
    }
    const features = filterOptions(keyfeatureOptionsState, selectedFeatureIds);
    dispatch(setKeyFeatureBack(features));
  }, [selectedFeatureIds, keyfeatureOptionsState, initFromBackend]);

  React.useEffect(() => {
    if (initFromBackend) {
      return;
    }
    const label = findOptionById(selectedLabelId, labelOptionsState);
    dispatch(setLabelBack(label));
  }, [selectedLabelId, labelOptionsState, initFromBackend]);

  React.useEffect(() => {
    if (initFromBackend) {
      return;
    }
    const selectedHightlight = filterOptions(
      hightlightOptionsState,
      selectedHightLightIds
    );
    dispatch(setHightlightsBack(selectedHightlight));
  }, [selectedHightLightIds, hightlightOptionsState, initFromBackend]);

  const content = (
    <div
      className="campaign-builder-sidenav-wrapper"
      style={{
        fontFamily: langTheme.mainFont(),
        boxSizing: "border-box",
      }}
    >
      <div className="campaign-builder-sidenav-main-content">
        {currentStep === CampaignSideNavStep.campaignSetUp && (
          <CampaignSetUpStep setCurrentStep={setCurrentStep} />
        )}

        {currentStep === CampaignSideNavStep.settings && (
          <CampaignSettitngsStep setCurrentStep={setCurrentStep} />
        )}

        {currentStep === CampaignSideNavStep.mainInformation && (
          <MainInformationStep setCurrentStep={setCurrentStep} />
        )}

        {currentStep === CampaignSideNavStep.media && (
          <CampaignMediaStep
            setCurrentStep={setCurrentStep}
            setImgFiles={setImgFiles}
            setBrochureFiles={setBrochureFiles}
            isImgsLoading={isImgsLoading}
            isBrochureLoading={isBrochureLoading}
          />
        )}

        {currentStep === CampaignSideNavStep.labels && (
          <CampaignLabelsStep
            setCurrentStep={setCurrentStep}
            selectedLabelId={selectedLabelId}
            setSelectedLabelId={setSelectedLabelId}
            labelOptionsState={labelOptionsState}
            setFloorplanFiles={setFloorplanFiles}
            setVideoFiles={setVideoFiles}
            isVideoLoading={isVideoLoading}
            isFloorPlanLoading={isFloorPlanLoading}
          />
        )}

        {currentStep === CampaignSideNavStep.addNewALabel && (
          <AddNewLabel
            setCurrentStep={setCurrentStep}
            labelOptionsState={labelOptionsState}
            setLabelOptionsState={setLabelOptionsState}
            addedLabelOptions={addedLabelOptions}
            setAddedLabelOptions={setAddedLabelOptions}
          />
        )}

        {currentStep === CampaignSideNavStep.investmentHighlights && (
          <InvestmentHighlights
            setCurrentStep={setCurrentStep}
            hightlightOptionsState={hightlightOptionsState}
            selectedHightLightIds={selectedHightLightIds}
            setSelectedHightLightIds={setSelectedHightLightIds}
          />
        )}

        {currentStep === CampaignSideNavStep.addaNewHighlight && (
          <AddNewHightlight
            setCurrentStep={setCurrentStep}
            hightlightOptionsState={hightlightOptionsState}
            setHightlightOptionsState={setHightlightOptionsState}
            addedHightlightOptions={addedHightlightOptions}
            setAddedHightlightOptions={setAddedHightlightOptions}
          />
        )}

        {currentStep === CampaignSideNavStep.aboutProperty && (
          <AboutProperty setCurrentStep={setCurrentStep} />
        )}

        {currentStep === CampaignSideNavStep.keyFeatures && (
          <KeyFeature
            setCurrentStep={setCurrentStep}
            keyfeatureOptionsState={keyfeatureOptionsState}
            selectedFeatureIds={selectedFeatureIds}
            setSelectedFeatureIds={setSelectedFeatureIds}
            setKeyfeatureOptionsState={setKeyfeatureOptionsState}
          />
        )}

        {currentStep === CampaignSideNavStep.addaNewFeature && (
          <AddNewFeature
            setCurrentStep={setCurrentStep}
            keyfeatureOptionsState={keyfeatureOptionsState}
            setKeyfeatureOptionsState={setKeyfeatureOptionsState}
            addedKeyFeatureOptions={addedKeyFeatureOptions}
            setAddedKeyFeatureOptions={setAddedKeyFeatureOptions}
          />
        )}

        {currentStep === CampaignSideNavStep.aboutDeveloper && (
          <AboutDeveloper setCurrentStep={setCurrentStep} />
        )}

        {currentStep === CampaignSideNavStep.contactInformation && (
          <ContactInformation
            setCurrentStep={setCurrentStep}
            newAgentAvatar={newAgentAvatar}
            setNewAgentAvatar={setNewAgentAvatar}
            isAvatarLoading={isAvatarLoading}
          />
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Drawer
        variant="persistent"
        anchor={isRTL ? "left" : "right"}
        open={campaignBuilderOpen}
        onClose={() => dispatch(setCampaingBuilderSideBarClose())}
        PaperProps={{
          sx: {
            width: campaignBuilderDrawerWidth,
            backgroundColor: "rgba(5, 57, 87, 1)",
          },
        }}
      >
        {content}
      </Drawer>
    </div>
  );
};
