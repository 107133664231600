import { useTranslation } from "react-i18next";
import SearchIcon from "../../assets/icons/search_icon.svg";
import React from "react";
import { FilterSideBar } from "./FilterSideBar";
import { useAppDispatch } from "../../store/store";
import { setFilterSideBarOpen } from "../../store/features/portfolioSlice";
import { IFilterParams } from "../../pages/Portfolio";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const HomeFilterDownMd = ({
  filters,
  onChangeFilters,
  search,
  onChangeSearch,
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  search: string;
  onChangeSearch: (search: string) => void;
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  return (
    <div
      className="overview-filter-down-md-container"
      style={{
        right: isRTL ? "0" : "auto",
        left: !isRTL ? "20px" : "55px",
        paddingRight: isRTL ? "25px" : "7px",
        paddingLeft: isRTL ? "7px" : "25px",
      }}
    >
      <p
        style={{
          fontFamily: langTheme.mainFont(),
        }}
      >
        {t("filters")}
      </p>

      <div
        className="overview-filter-down-search-btn-container"
        onClick={() => dispatch(setFilterSideBarOpen())}
      >
        <img src={SearchIcon} />
      </div>

      <FilterSideBar
        filters={filters}
        onChangeFilters={onChangeFilters}
        search={search}
        onChangeSearch={onChangeSearch}
      />
    </div>
  );
};
