import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Arrow from "../../../assets/icons/arrow.svg";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";

export const ForBuyersInfo = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
const langTheme = useLanguageTheme()
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div
      className="for-buyers-info-conatiner"
      style={{
        direction: i18n.dir(),
        alignItems: downMd ? "center" : "flex-start",
      }}
    >
      <Typography
        variant="h3"
        style={{
          fontFamily: langTheme.mainFont(),
        }}
      >
        {t("for_buyers_sub_title")}
      </Typography>
      <Typography
        variant="h2"
        sx={{
          lineHeight: downMd ? "28px" : "46px",
          mb: downMd ? "10px" : "15px",
          mt: downMd ? "9px" : "5px",
          textAlign: downMd ? "center" : "",
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
        }}
      >
        {t("for_buyers_title")}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mb: downMd ? "15px" : "45px",
          lineHeight: downMd ? "20px" : "22px",
          textAlign: downMd ? "center" : "",
          letterSpacing: isRTL ? "" : "0.64px",
          fontFamily: langTheme.mainFont(),
        }}
      >
        {t("for_buyers_desc")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          color: "rgba(255, 255, 255, 1)",
          width: "fit-content",
          backgroundColor: "rgba(3, 74, 115, 1)",
          p: "15px 30px 15px 30px",
          cursor: "pointer",
          borderRadius: "24px",
          transition: "background 2s ease-in-out .5s",
          "&:hover": {
            background:
              "linear-gradient(to right, rgba(3, 74, 115, 1), rgba(10, 121, 146, 1))",
          },
          mb: "10px",
        }}
      >
        <Typography
          sx={{
            mr: isRTL ? "0" : "12px",
            ml: isRTL ? "12px" : "0",
            fontWeight: isRTL ? 600 : 500,
            fontFamily: langTheme.mainFont(),
            lineHeight: "16px",
          }}
        >
          {t("consultation_btn_text")}
        </Typography>
        <img
          src={Arrow}
          alt="arrow"
          style={{
            transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
          }}
        />
      </Box>
    </div>
  );
};
