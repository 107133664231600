import { createSelector, createSlice } from '@reduxjs/toolkit';
import {jwtDecode} from 'jwt-decode';
import { RootState } from '../store';

export interface IAuthSliceState {
  jwt: string;
  user: string;
}

const initialState: IAuthSliceState = {
  jwt: '',
  user: '',
};

export function isTokenExpired(jwt: string) {
  if (!jwt) return true;
  const decoded = jwtDecode(jwt);
  const expirationTime = decoded.exp! * 1000; 
  return Date.now() >= expirationTime;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { jwt, user } = action.payload;
      state.jwt = jwt;
      state.user = user;
    },
    logOut: (state) => {
      state.jwt = '';
      state.user = '';
    },
  },
});

const createAuthState = (state: RootState) => state.auth;

export const userLoggedInSelector = createSelector(createAuthState, (s) => {
  return !isTokenExpired(s.jwt) && s.user
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;
