import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  KeyFeatureIconMapBlue,
  KeyFuatureIconKey,
} from "../../constants/campaignBuilderData";
import { Theme, useMediaQuery } from "@mui/material";
import { PreviewMode } from "./PreviewModeSwiter";

export interface IKeyFeatureOption {
  id: string;
  icon_key: KeyFuatureIconKey;
  labelName: string;
  selectedToCard: boolean;
  selectId: string;
}
export const KeyFeturesBlock = () => {
  const { t } = useTranslation(["campaignBuilder"]);

  const { keyFeaturesBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  console.log("log: keyFeaturesBack", keyFeaturesBack);
  return (
    <div>
      <div className="campaign-builder-main-header">{t("key_features")}</div>
      <div>
        <div
          className="campaign-builder-key-feature-items-block"
          style={{
            gridTemplateColumns:
              previewMode === PreviewMode.MOBILE || downMd
                ? "1fr"
                : "repeat(2, 1fr)",
          }}
        >
          {keyFeaturesBack.map((option: IKeyFeatureOption) => {
            const IconComponent = KeyFeatureIconMapBlue[option.icon_key];

            return (
              <div
                key={option.id}
                className="campaign-builder-investment-highlights-item"
              >
                <IconComponent
                  isSelected={false}
                  width={30}
                  height={30}
                  backgroundColor={"#034A73"}
                />
                <div>{t(option.labelName)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
