import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Header } from "../components/_common/Header";
import { Navbar } from "../components/_common/Navbar";
import {
  campaignBuilderDrawerWidth,
  CampaignBuilderSideNav,
  CampaignSideNavStep,
} from "../components/campaignBuilder/CampaignBuilderSideNav";
import { CampainBuilderMainContainer } from "../components/campaignBuilder/CampainBuilderMainContainer";
import { useGetAgentsQuery } from "../store/api/agentApiSlice";
import { useLazyGetPropertyQuery } from "../store/api/propertyApiSlice";
import {
  setAboutDeveloper,
  setAboutPropertyDesc,
  setCountOfTimePeriods,
  setCurrency,
  setHightlightsBack,
  setKeyFeatureBack,
  setLabelBack,
  setPropertyDescription,
  setPropertyLocation,
  setPropertyPrice,
  setPropertyStatus,
  setPropertyTitle,
  setPropertyType,
  setSelectedAgent,
  setSelectedUserId,
  setTimePeriod,
} from "../store/features/campaignBuilderSlice";
import { campaignBuilderOpenSelector } from "../store/features/common";
import { useAppDispatch } from "../store/store";
import { useCreatePropertyMutation } from "../store/api/campaignBuilderApiSlice";
import {
  calculateCampaignCloseDate,
  convertDateToTimePeriods,
} from "../helpers/formatter";
import {
  PreviewMode,
  PreviewModeSwiter,
} from "../components/campaignBuilder/PreviewModeSwiter";
import { keyFeatureOptions } from "../constants/campaignBuilderData";

export enum BuilderMode {
  CREATE = 0,
  EDIT = 1,
}

const propertyData = {
  name: "Park TLV Tower11111",
  description:
    "Luxurious residential tower in Park Tzameret with panoramic city views",
  propertyType: "Luxury Tower",
  propertyStatus: "immediate_availability",
  pricing: {
    roi: 3.8,
    basePrice: 12500000,
    pricePerSqm: 55000,
    estimatedRent: 18000,
    maintenanceFee: 2800,
    currency: "usd",
  },
  details: {
    parking: 2,
    bedrooms: 4,
    bathrooms: 3.5,
    totalArea: 220,
    floorLevel: 25,
    livingArea: 180,
    outdoorArea: 40,
    totalFloors: 40,
  },
  location: {
    city: "Tel Aviv",
    address: "5 Nissim Aloni St",
    coordinates: {
      latitude: 32.1134,
      longitude: 34.7966,
    },
    neighborhood: "Park Tzameret",
  },
  investmentHighlights: {
    location: {
      icon: "map-pin",
      text: "Prime location in Tel Aviv's luxury district",
    },
    potential: {
      icon: "trending-up",
      text: "High appreciation potential",
    },
  },
  labels: {
    luxury: {
      icon: "crown",
      text: "Premium Property",
    },
  },
  keyFeatures: {
    views: {
      icon: "eye",
      text: "Panoramic city views",
    },
    security: {
      icon: "shield",
      text: "24/7 security",
    },
  },
  developer: "Information about developer",
  mediaContent: [85],
  video: [101],
  brochure: [105],
  floorplan: [100],
  campaignCloseDate: "2026-11-04",
  aboutProperty: "text",
  campaignStatus: "draft",
};

export const CampaignBuilder = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const [currentStep, setCurrentStep] = React.useState<CampaignSideNavStep>(
    () => {
      const savedStep = localStorage.getItem("currentStep");
      return savedStep &&
        Object.values(CampaignSideNavStep).includes(
          savedStep as CampaignSideNavStep
        )
        ? (savedStep as CampaignSideNavStep)
        : CampaignSideNavStep.campaignSetUp;
    }
  );

  React.useEffect(() => {
    localStorage.setItem("currentStep", currentStep);
  }, [currentStep]);

  const { documentId } = useParams();
  // const builderMode = useMemo(() => {
  //   return documentId ? BuilderMode.EDIT : BuilderMode.CREATE;
  // }, [documentId]);

  const [triggerGetProperty, { data: propertyData }] =
    useLazyGetPropertyQuery();
  const { data: agentsData } = useGetAgentsQuery();

  React.useEffect(() => {
    if (documentId) {
      triggerGetProperty(documentId);
    } else {
    }
  }, []);

  useEffect(() => {
    if (propertyData) {
      console.log("log:propertyData ", propertyData);
      const { countOfTimePeriodsValue, timePeriodValue } =
        convertDateToTimePeriods(propertyData.campaignCloseDate);

      dispatch(setCountOfTimePeriods(countOfTimePeriodsValue));
      dispatch(setTimePeriod(timePeriodValue));

      dispatch(setPropertyTitle(propertyData.name));
      dispatch(setPropertyDescription(propertyData.description));
      dispatch(setPropertyPrice(String(propertyData.pricing.basePrice)));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setPropertyStatus(propertyData.propertyStatus));
      dispatch(setPropertyType(propertyData.propertyType));
      dispatch(setAboutPropertyDesc(propertyData.aboutProperty));
      dispatch(setAboutDeveloper(propertyData.developer));
      dispatch(setKeyFeatureBack(propertyData.keyFeatures));
      dispatch(setHightlightsBack(propertyData.investmentHighlights));
      dispatch(setSelectedUserId(propertyData.agent.id));
      dispatch(setLabelBack(propertyData.labels));
      dispatch(
        setPropertyLocation({
          city: propertyData.location.city,
          address: propertyData.location.address,
          coordinates: {
            latitude: propertyData.location.coordinates.latitude,
            longitude: propertyData.location.coordinates.longitude,
          },
        })
      );
    }
  }, [propertyData]);

  const { selectedUserId } = useSelector((state: any) => state.campaignBuilder);

  React.useEffect(() => {
    if (agentsData && agentsData.length > 0) {
      const selectedAgent = agentsData.find(
        (agent: any) => agent.id === selectedUserId
      );

      if (selectedAgent) {
        dispatch(setSelectedAgent(selectedAgent));
      } else {
        if (selectedUserId === "create-new") {
          dispatch(setSelectedUserId("create-new"));
        } else {
          dispatch(setSelectedAgent(agentsData[0]));
          dispatch(setSelectedUserId(agentsData[0].id));
        }
      }
    }
  }, [agentsData, selectedUserId, dispatch]);

  const [imgFiles, setImgFiles] = React.useState<File[]>([]);
  const [newAgentAvatar, setNewAgentAvatar] = React.useState<File[]>([]);
  const [brochureFiles, setBrochureFiles] = React.useState<File[]>([]);
  const [floorplanFiles, setFloorplanFiles] = React.useState<File[]>([]);
  const [videoFiles, setVideoFiles] = React.useState<File[]>([]);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          marginRight:
            campaignBuilderOpen && !isRTL ? campaignBuilderDrawerWidth : 0,
          marginLeft:
            campaignBuilderOpen && isRTL ? campaignBuilderDrawerWidth : 0,
          transition: "margin-right 0.3s",
          overflow: "auto",
        }}
      >
        {!campaignBuilderOpen && (
          <div className="portfolio-header-sticky-wrapper">
            <div
              style={{
                paddingTop: "50px",
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            >
              <PreviewModeSwiter />

              {PreviewMode.DESKTOP === previewMode && (
                <Header>
                  <Navbar />
                </Header>
              )}
            </div>
          </div>
        )}

        <CampainBuilderMainContainer
          imgFiles={imgFiles}
          floorplanFiles={floorplanFiles}
          brochureFiles={brochureFiles}
          videoFiles={videoFiles}
          newAgentAvatar={newAgentAvatar}
        />
      </div>

      <CampaignBuilderSideNav
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        imgFiles={imgFiles}
        setImgFiles={setImgFiles}
        newAgentAvatar={newAgentAvatar}
        setNewAgentAvatar={setNewAgentAvatar}
        brochureFiles={brochureFiles}
        setBrochureFiles={setBrochureFiles}
        floorplanFiles={floorplanFiles}
        setFloorplanFiles={setFloorplanFiles}
        videoFiles={videoFiles}
        setVideoFiles={setVideoFiles}
        // builderMode={builderMode}
      />
    </div>
  );
};
