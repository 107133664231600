import { calculateCampaignCloseDate } from "./formatter";
import {
  Currency,
  PropertyStatus,
  PropertyType,
  TimePeriod,
} from "../components/campaignBuilder/CampaignBuilderSideNav";
import { IPropertyLocation } from "../components/campaignBuilder/CampaignBuilderLocationSearch";
import { IKeyFeatureOption } from "../components/campaignBuilder/KeyFeturesBlock";
import { CampaignCreatingStatus } from "../components/campaignBuilder/CampaignSetUpStep";

export const convertDataToCreateProperty = (
  countOfTimePeriods: string,
  timePeriod: TimePeriod,
  propertyTitle: string,
  propertyDescription: string,
  propertyStatus: PropertyStatus,
  currency: Currency,
  propertyPrice: string,
  propertyLocation: IPropertyLocation,
  propertyType: PropertyType,
  aboutPropertyDesc: string,
  aboutDeveloper: string,
  keyFeaturesBack: IKeyFeatureOption[],
  hightlightsBack: any,
  agentId: string,
  labels: any,
  imgIds: number[],
  brochureIds: number[],
  floorplanIds: number[],
  videoIds: number[],
  campaignStatus: CampaignCreatingStatus
) => {
  const propertyData = {
    name: propertyTitle,
    description: propertyDescription,
    propertyStatus: propertyStatus,
    pricing: {
      basePrice: propertyPrice,
      currency: currency,
    },
    location: {
      city: propertyLocation.city,
      address: propertyLocation.address,
      coordinates: {
        latitude: propertyLocation.coordinates.latitude,
        longitude: propertyLocation.coordinates.longitude,
      },
    },
    propertyType: propertyType,
    campaignCloseDate: calculateCampaignCloseDate(
      countOfTimePeriods,
      timePeriod
    ),
    aboutProperty: aboutPropertyDesc,
    developer: aboutDeveloper,
    keyFeatures: keyFeaturesBack,
    investmentHighlights: hightlightsBack,
    labels: labels,
    mediaContent: imgIds,
    brochure: brochureIds,
    floorplan: floorplanIds,
    video: videoIds,
    agent: [Number(agentId)],
    campaignStatus: campaignStatus,
  };

  return propertyData;
};

export function extractIds(responseArray: any) {
  return responseArray.map((item: any) => item.id);
}

export type ImageObject = {
  id: number;
  documentId: string;
  name: string;
  url: string;
  ext: string;
};

export type ExtractedMediaData = {
  id: number;
  documentId: string;
  name: string;
  url: string;
  ext: string;
};

export const extractMediaProperties = (
  objects: ImageObject[]
): ExtractedMediaData[] => {
  return objects.map((obj) => ({
    id: obj.id,
    documentId: obj.documentId,
    name: obj.name,
    url: obj.url,
    ext: obj.ext,
  }));
};

export const handleUploadFiles = (files: File[]) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }
  return formData;
};
