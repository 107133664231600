import React from "react";
import { LabelIconMap, LabelStamp } from "../../constants/campaignBuilderData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
type IconKey = "hot_price" | "premium" | "eco_friendly";

interface Option {
  icon_key: IconKey;
  labelName: string;
  backgroundColor: string;
}

export const LabelStampComponent = ({ option }: { option: Option }) => {
  const { t } = useTranslation(["campaignBuilder"]);

  if (!option) return null;
  const IconComponent = LabelIconMap[option.icon_key];
  if (!IconComponent) return null;
  return (
    <div
      className="label-stamp-container"
      style={{
        left: "-9px",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: "8px",
          gap: "7px",
          marginLeft: "10px",
        }}
      >
        <IconComponent
          isSelected={false}
          width={17}
          height={19}
          backgroundColor={"white"}
          iconSelect={false}
        />
        <div>{t(option?.labelName)}</div>
      </div>
      <LabelStamp
        isSelected={true}
        width={135}
        height={46}
        backgroundColor={option?.backgroundColor}
        iconSelect={false}
      />
    </div>
  );
};
