import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { calculateRemainingTime } from "../../helpers/dataConverts";
import { useSelector } from "react-redux";
import UloadIcon from "../../assets/campaignBuilder/upload.svg";
import HeartIcon from "../../assets/campaignBuilder/heart_black.svg";
import { PreviewMode } from "./PreviewModeSwiter";

export const CampaignBuilderHeader = () => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { countOfTimePeriods, timePeriod, propertyTitle, propertyDescription } =
    useSelector((state: any) => state.campaignBuilder);

  const expireTime = calculateRemainingTime(countOfTimePeriods, timePeriod);
  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  return (
    <div
      className="campaign-builder-main-header-container"
      style={{
        flexDirection: previewMode === PreviewMode.DESKTOP ? "row" : "column",
      }}
    >
      <div>
        <div className="campaign-builder-main-container-expire-time">
          <div>{t("expire_after")}</div>
          <span>
            &nbsp; {expireTime.days} {t("days")}
          </span>
        </div>
        <div
          className="campaign-builder-main-container-header-title"
          style={{
            fontSize: previewMode === PreviewMode.MOBILE || downMd ? "24px" : "26px",
            fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
          }}
        >
          {propertyTitle ? propertyTitle : t("title_of_property_plug")}
        </div>
        <div
          className="campaign-builder-main-container-header-desc"
          style={{
            marginBottom: downMd ? "20px" : "30px",
          }}
        >
          {propertyDescription
            ? propertyDescription
            : t("description_of_property_plug")}
        </div>
      </div>
      <div className="campaign-builder-main-upload-section" style={{
        marginBottom: previewMode === PreviewMode.MOBILE || downMd ? "20px" : "0px",
      }}>
        <div className="campaign-builder-main-upload-item">
          <img src={UloadIcon} />
        </div>
        <div className="campaign-builder-main-upload-item">
          <img src={HeartIcon} />
        </div>
      </div>
    </div>
  );
};
