import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  PropertyStatus,
  propertyStatusToNumber,
} from "./CampaignBuilderSideNav";
import { getStatusNumber } from "../../helpers/dataConverts";
import styled from "styled-components";
import { Theme, useMediaQuery } from "@mui/material";
import { PreviewMode } from "./PreviewModeSwiter";

const StatusBar = styled.div`
  display: flex;
  gap: 20px;
`;

const StatusLine = styled.div<{ $completed: boolean }>`
  width: 27px;
  height: 6px;
  border-radius: 3px;
  background-color: ${(props) => (props.$completed ? "#D7A148" : "#D9D9D9")};
  text-decoration: ${(props) => (props.$completed ? "line-through" : "none")};
`;

interface Props {
  statusNumber: number;
  totalStatuses: number;
}

const PropertyStatusBar: React.FC<Props> = ({
  statusNumber,
  totalStatuses,
}) => {
  return (
    <StatusBar>
      {[...Array(totalStatuses)].map((_, index) => (
        <StatusLine key={index} $completed={index < statusNumber} />
      ))}
    </StatusBar>
  );
};

export const PropertyStatusBlock = () => {
  const { t } = useTranslation(["campaignBuilder"]);
  const { propertyStatus } = useSelector((state: any) => state.campaignBuilder);
  const statusNumber = getStatusNumber(propertyStatus);
  const totalStatuses = Object.keys(PropertyStatus).length;
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  return (
    <div
      className="campaign-builder-main-propertystatus-container"
      style={{
        flexDirection:
          previewMode === PreviewMode.MOBILE || downMd ? "column" : "row",
        alignItems:
          previewMode === PreviewMode.MOBILE || downMd
            ? "flex-start"
            : "center",
      }}
    >
      <div
        className="campaign-builder-main-propertystatus-left-item"
        style={{
          marginBottom:
            previewMode === PreviewMode.MOBILE || downMd ? "20px" : 0,
        }}
      >
        <div className="campaign-builder-main-propertystatus-container-title">
          {t(propertyStatus)}
        </div>
        <div className="campaign-builder-main-propertystatus-step">
          {t("step")}
          &nbsp;
          {statusNumber}
          &nbsp;
          {t("of")}
          &nbsp;
          {totalStatuses}
        </div>
      </div>
      <PropertyStatusBar
        statusNumber={statusNumber}
        totalStatuses={totalStatuses}
      />
    </div>
  );
};
