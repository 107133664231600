import { useState, useEffect } from "react";
import { useGetTestimonialsQuery } from "../store/api/testimonialsApiSlice";

export interface ITestimonial {
  photo: any;
  propertyPhoto: any;
  name: string;
  testimonial: string;
  role: string;
  localizations: any;
}

export const useTestimonials = () => {
  const [testimonialsData, setTestimonialsData] = useState<ITestimonial>({
    photo: "",
    propertyPhoto: "",
    name: "",
    testimonial: "",
    role: "",
    localizations: "",
  });

  const { data: fetchedTestimonials } = useGetTestimonialsQuery({});

  useEffect(() => {
    if (fetchedTestimonials?.data) {
      setTestimonialsData(fetchedTestimonials.data);
    }
  }, [fetchedTestimonials]);

  return Object.values(testimonialsData);
};
