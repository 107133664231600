import React from "react";
import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { HomeFilterUpMd } from "../HomeFilterUpMd";
import { HomeFilterDownMd } from "../HomeFilterDownMd";
import { HeroImgStatisticsUpMd } from "./HeroImgStatisticsUpMd";
import { HeroImgStatisticsDownMd } from "./HeroImgStatisticsDownMd";
import { IFilterParams } from "../../../pages/Portfolio";

export const HeroImg = ({
  filters,
  onChangeFilters,
  search,
  onChangeSearch,
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  search: string;
  onChangeSearch: (search: string) => void;
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <div
      className="overview-hero-img-container"
      style={{
        direction: i18n.dir(),
        height: upLg ? "650px" : downMd ? "317px" : "617px",
        maxWidth: "3000px",
      }}
    >
      {downMd ? (
        <HomeFilterDownMd
          filters={filters}
          onChangeFilters={onChangeFilters}
          search={search}
          onChangeSearch={onChangeSearch}
        />
      ) : (
        <HomeFilterUpMd
          filters={filters}
          onChangeFilters={onChangeFilters}
          search={search}
          onChangeSearch={onChangeSearch}
        />
      )}
      {downMd ? <HeroImgStatisticsDownMd /> : <HeroImgStatisticsUpMd />}
    </div>
  );
};
