import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { renderValueWithTrancateLength } from "../../helpers/dataConverts";
import { IFilter } from "../../pages/Portfolio";
import {
  FilterMap,
  getFiltersByIds,
  getFiltersFromMap,
} from "../../constants/propertyData";
import { useMemo } from "react";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { CustomSelectStyles } from "../_common/CustomFilterSelect";
export const ALL = "all";

export const PortfolioSelect = ({
  filterMap = {},
  selectedFilters = [],
  onChange,
  truncateLength,
  styles,
}: {
  filterMap: FilterMap;
  selectedFilters: IFilter[];
  onChange: (filters: IFilter[]) => void;
  truncateLength: number;
  styles: CustomSelectStyles;
}) => {
  const { t } = useTranslation(["filters"]);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const filters = useMemo(() => getFiltersFromMap(filterMap), [filterMap]);

  const handleChangeOption = (event: SelectChangeEvent<string[]>) => {
    // TODO
    const value = event.target.value as string[];

    if (value.includes(ALL)) {
      let eq = false;

      if (filters.length == selectedFilters.length) {
        eq = true;
        for (let i = 0; i < filters.length; i++) {
          if (filterMap[i].key != selectedFilters[i].key) {
            eq = false;
            break;
          }
        }
      }

      if (eq) {
        onChange([]);
      } else {
        onChange(filters);
      }
    } else {
      onChange(getFiltersByIds(filterMap, value));
    }
  };

  const renderValue = (selected: string[]) => {
    // if (selected.length === Object.keys(filterMap).length) {
    //   return t("all");
    // }
    return renderValueWithTrancateLength(selectedFilters, truncateLength);
  };

  return (
    <Select
      multiple
      value={selectedFilters.map((filter) => filter.key)}
      onChange={handleChangeOption}
      renderValue={renderValue}
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: styles.menuPaperBackground,
            },
          },
        },
      }}
      sx={{
        color: styles.select.color,
        fontFamily: langTheme.mainFont(),
        fontWeight: isRTL ? 400 : 500,
        backgroundColor: "rgba(240, 244, 247, 1)",
        borderRadius: "23px",
        padding: "12px 20px",
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-select": {
          padding: 0,
          display: "flex",
          alignItems: "center",
          paddingRight: isRTL ? "0px" : "24px",
          paddingLeft: isRTL ? "24px" : "0px",
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
          {
            paddingRight: isRTL ? "14px" : "32px",
            paddingLeft: isRTL ? "24px" : "0",
          },
      }}
      IconComponent={styles.select.iconComponent}
    >
      <MenuItem
        value={ALL}
        sx={{
          color: styles.menuItem.color,
          backgroundColor: styles.menuItem.backgroundColor,
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 400 : 500,
          "&:hover": {
            backgroundColor: styles.menuItem.hoverBackground,
          },
        }}
      >
        {t("all")}
      </MenuItem>
      {filters.map((filter) => (
        <MenuItem
          value={filter.key}
          key={filter.key}
          sx={{
            color: styles.menuItem.color,
            backgroundColor: styles.menuItem.backgroundColor,
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 400 : 500,
            "&:hover": {
              backgroundColor: styles.menuItem.hoverBackground,
            },
          }}
        >
          {t(filter.translationKey)}
        </MenuItem>
      ))}
    </Select>
  );
};
