import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
// import { logOut } from "../features/authSlice";
import { BACKEND_HOST } from "../../helpers/api";

const baseQuery = fetchBaseQuery({
  baseUrl: BACKEND_HOST,
  // credentials: "include"

  // prepareHeaders: (headers, { getState }) => {
  //     const token = getState().auth.token;

  //     if (token) {
  //         headers.set("Authorization", `Bearer ${token}`)
  //     }
  //     return headers
  // }
});

const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const res = await baseQuery(args, api, extraOptions);

  // if (res?.error?.status === 401 ) {
  //     api.dispatch(logOut())
  // }
  return res;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({}),
});
