import React from "react";
import { AuthWrapper } from "../components/_common/AuthWrapper";
import { SigninContent } from "../components/_common/SigninContent";

export const SignIn = () => {
  return (
    <AuthWrapper>
      <SigninContent isPopup={false} />
    </AuthWrapper>
  );
};
