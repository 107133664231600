import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export enum Language {
  ENGLISH = "en",
  HEBREW = "he",
}
export const LanguageDetails = {
  [Language.ENGLISH]: { name: "עב", dir: "ltr" },
  [Language.HEBREW]: { name: "En", dir: "rtl" },
} as const;

export const LangToggle = () => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = React.useState<Language>(
    (localStorage.getItem("lang") as Language) || Language.ENGLISH
  );

  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const handleLanguageToggle = () => {
    const newLang =
      selectedLang === Language.ENGLISH ? Language.HEBREW : Language.ENGLISH;
    setSelectedLang(newLang);
    localStorage.setItem("lang", newLang);
    i18n.changeLanguage(newLang);
  };

  React.useEffect(() => {
    const savedLang = localStorage.getItem("lang") as Language;
    if (savedLang && Object.values(Language).includes(savedLang)) {
      setSelectedLang(savedLang);
      i18n.changeLanguage(savedLang);
    }
  }, []);
  return (
    <Box borderRadius={"15px"} display={"flex"} alignItems={"center"}>
      <Typography
        onClick={handleLanguageToggle}
        sx={{
          color: "black",
          cursor: "pointer",
          fontFamily: "Noto Sans Hebrew",
          fontWeight: 500,
          fontSize: downLg ? "14px" : "16px",
        }}
      >
        {LanguageDetails[selectedLang].name}
      </Typography>
    </Box>
  );
};
