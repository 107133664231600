import {
  Collapse,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReplaceCross from "../../assets/campaignBuilder/agent_replace_cross.svg";
import { Media } from "../../helpers/convert";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { authTextFieldStyles } from "../../pages/Signup";
import {
  useCreateAgentMutation,
  useGetAgentsQuery,
  useLazyGetAgentsQuery,
  useUploadImgMutation,
} from "../../store/api/agentApiSlice";
import {
  setAgentPhotoOpen,
  setScheduleVewingOpen,
} from "../../store/features/campaignBuilderSetup";
import {
  setSelectedAgent,
  setSelectedUserId,
} from "../../store/features/campaignBuilderSlice";
import { useAppDispatch } from "../../store/store";
import { PhoneSelect } from "../_common/PhoneSelect";
import { AgentSelect } from "./AgentSelect";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampaignBuilderSwitcher } from "./CampaignBuilderSwitcher";
import {
  FileType,
  MAXIMUM_NUMBER_OF_AGENT_AVATAR,
  propertyImgAcceptFormat,
} from "./CampaignMediaStep";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { FileDropZone } from "./FileDropZone";
import { textFieldStyles } from "./MainInformationStep";

export interface User {
  id: number;
  documentId: string;
  name: string;
  phone: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  photo: Media;
}
export const ContactInformation = ({
  setCurrentStep,
  newAgentAvatar,
  setNewAgentAvatar,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  newAgentAvatar: any;
  setNewAgentAvatar: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();
  const [errors, setErrors] = React.useState<any>({});

  const [newAgentName, setNewAgentName] = React.useState("");
  const [newAgentPhone, setNewAgentPhone] = React.useState("");
  const [newAgentLink, setNewAgentLink] = React.useState("");

  const { scheduleVewingOpen, agentPhotOpen } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  const { selectedAgent, selectedUserId } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const { data: agentsData } = useGetAgentsQuery();

  const handleChangeUser = (value: number | string) => {
    if (value === "create-new") {
      dispatch(setSelectedUserId("create-new"));

      setNewAgentName("");
      setNewAgentPhone("");
      setNewAgentLink("");
      setNewAgentAvatar([]);

      dispatch(
        setSelectedAgent({
          id: null,
          name: "",
          phone: "",
          link: "",
          photo: null,
        })
      );
    } else {
      dispatch(setSelectedUserId(value as number));

      const selectedAgentData =
        agentsData?.find((agent: User) => agent.id === value) || null;

      dispatch(setSelectedAgent(selectedAgentData));
      setNewAgentName("");
      setNewAgentPhone("");
    }
  };

  const handleNewAgentNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setNewAgentName(value);

    const updatedUserData = {
      ...selectedAgent,
      name: event.target.value,
    };
    dispatch(setSelectedAgent(updatedUserData as any));
  };

  const handleChangeScheduleVewingOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setScheduleVewingOpen(event.target.checked));
  };

  const handleChangeAgentPhotoOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setAgentPhotoOpen(event.target.checked));
  };

  const [selectedCode, setSelectedCode] = React.useState("+972");

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewAgentPhone(value);

    const newErrors = { ...errors };
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    const fullPhoneNumber = selectedCode + value;

    if (!value.trim()) {
      newErrors.phoneNumber = t("phone_number_required");
    } else if (!phoneRegex.test(fullPhoneNumber)) {
      newErrors.phoneNumber = t("invalid_phone_number_format");
    } else if (value.length < 7 || fullPhoneNumber.length > 15) {
      newErrors.phoneNumber = t("phone_number_length_invalid");
    } else {
      delete newErrors.phoneNumber;
    }

    const updatedUserData = {
      ...selectedAgent,
      phone: `${selectedCode} ${value}`,
    };
    //TODO set code
    dispatch(setSelectedAgent(updatedUserData as any));

    setErrors(newErrors);
  };

  const handleCodeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCode(event.target.value);
  };

  const handleNewAgentLinkChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setNewAgentLink(value);

    const updatedUserData = {
      ...selectedAgent,
      link: event.target.value,
    };
    dispatch(setSelectedAgent(updatedUserData as any));
  };

  const [createAgent] = useCreateAgentMutation();
  const [uploadImg] = useUploadImgMutation();

  const [triggerAgents] = useLazyGetAgentsQuery();

  const handleCreateAgent = async () => {
    try {
      const formData = new FormData();
      formData.append("files", newAgentAvatar[0]);

      const imgResponse = await uploadImg(formData).unwrap();

      const agentData = {
        name: selectedAgent.name,
        phone: selectedAgent.phone,
        email: "test@example.com",
        photo: imgResponse[0].id,
        link: selectedAgent.link,
      };

      await createAgent({ agentData }).unwrap();
      triggerAgents();
    } catch (error) {
      console.error("Error creating agent: ", error);
    }
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="contact_information"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.keyFeatures}
          />
        }
        rightBtn={<div className="campaign-builder-sidenav-empty-btn"></div>}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("select_agent")}
            </div>
            <AgentSelect
              value={selectedUserId}
              onChangeValue={handleChangeUser}
              options={agentsData || []}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("agent_name")}
            </div>

            <TextField
              fullWidth
              sx={textFieldStyles(langTheme.mainFont(), errors.aboutDeveloper)}
              autoComplete="off"
              value={
                selectedUserId === "create-new"
                  ? newAgentName
                  : selectedAgent?.name || ""
              }
              InputProps={{
                readOnly: selectedUserId !== "create-new",
              }}
              onChange={handleNewAgentNameChange}
            />

            {errors.aboutDeveloper && (
              <div className="campaign-builder-errors">
                {errors.aboutDeveloper}
              </div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("agent_phone")}
            </div>

            <TextField
              type="tel"
              fullWidth
              required
              autoComplete="off"
              aria-readonly={selectedUserId === "create-new" ? false : true}
              value={
                selectedUserId === "create-new"
                  ? newAgentPhone
                  : selectedAgent?.phone || ""
              }
              onChange={(e: any) => handlePhoneNumberChange(e)}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber || " "}
              inputProps={{
                maxLength: 15,
              }}
              FormHelperTextProps={{
                sx: {
                  textAlign: isRTL ? "right" : "left",
                  fontFamily: langTheme.mainFont(),
                },
              }}
              InputProps={{
                readOnly: selectedUserId !== "create-new",
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneSelect
                      selectedCode={selectedCode}
                      handleCodeChange={handleCodeChange}
                    />
                  </InputAdornment>
                ),
              }}
              sx={(theme) => ({
                ...authTextFieldStyles.textField(
                  theme,
                  isRTL,
                  !!errors.phoneNumber
                ),
                input: {
                  textAlign: isRTL ? "right" : "left",
                  direction: i18n.dir(),
                },
              })}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("link_viewing")}
            </div>

            <TextField
              fullWidth
              sx={textFieldStyles(langTheme.mainFont(), errors.aboutDeveloper)}
              autoComplete="off"
              value={
                selectedUserId === "create-new"
                  ? newAgentLink
                  : selectedAgent?.link || ""
              }
              onChange={handleNewAgentLinkChange}
              InputProps={{
                readOnly: selectedUserId !== "create-new",
              }}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div
              className="campaign-builder-sidenav-switcher-container"
              style={{
                marginBottom: selectedAgent === "create-new" ? "20px" : "0",
              }}
            >
              <div className="campaign-builder-sidenav-field-name">
                {t("agent_photo")}
              </div>
              <CampaignBuilderSwitcher
                open={agentPhotOpen}
                handleChangeOpen={handleChangeAgentPhotoOpen}
              />
            </div>

            <Collapse in={agentPhotOpen}>
              {selectedUserId === "create-new" ? (
                <FileDropZone
                  fileAcceptFormat={propertyImgAcceptFormat}
                  limit={MAXIMUM_NUMBER_OF_AGENT_AVATAR}
                  files={newAgentAvatar}
                  setFiles={setNewAgentAvatar}
                  type={FileType.AGET_AVATAR}
                  placeholder={"placeholder_upload_property_img"}
                />
              ) : (
                <div className="campaign-builder-sidenav-agent-photo">
                  <img
                    src={ReplaceCross}
                    style={{
                      right: isRTL ? "auto" : "-17px",
                      left: isRTL ? "-17px" : "auto",
                    }}
                    className="campaign-builder-sidenav-agent-photo-cross "
                  />
                  <div
                    style={{
                      background: `url(${selectedAgent?.photo?.url}) center center / cover no-repeat`,
                    }}
                    className="campaign-builder-sidenav-agent-photo img "
                  ></div>
                </div>
              )}
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-switcher-container">
            <div className="campaign-builder-sidenav-field-name">
              {t("schedule_viewing")}
            </div>
            <CampaignBuilderSwitcher
              open={scheduleVewingOpen}
              handleChangeOpen={handleChangeScheduleVewingOpen}
            />
          </div>

          {selectedUserId === "create-new" && (
            <div className="campaign-builder-sidenav-create-agent-btn-wrapper">
              <div
                onClick={() => handleCreateAgent()}
                className="campaign-builder-sidenav-create-agent-btn"
              >
                {t("create_agent")}
              </div>
            </div>
          )}
        </div>

        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
