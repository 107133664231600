import { ISignInData } from '../../components/_common/SigninContent';
import { IResendSignupData } from '../../components/auth/EmailConfirmationContent';
import { IFinishSignupData } from '../../components/auth/FinishSignupContent';
import { IPasswordResetEnterEmailData } from '../../components/auth/PasswordResetEnterEmailStep';
import { IResetPasswordData } from '../../components/auth/PasswordResetNewPasswordStep';
import { ISignupData } from '../../components/auth/SignupContent';
import { apiSlice } from '../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (credentials: ISignupData) => ({
        url: '/auth/local/register',
        method: 'POST',
        body: credentials,
      }),
    }),
    finishSignup: builder.mutation({
      query: (credentials: IFinishSignupData) => ({
        url: `/users/${JSON.parse(localStorage.getItem('user')!).id}`,
        method: 'PUT',
        body: credentials,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')!}`,
        },
      }),
    }),
    signin: builder.mutation({
      query: (credentials: ISignInData) => ({
        url: '/auth/local',
        method: 'POST',
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (credentials: IPasswordResetEnterEmailData) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials: IResetPasswordData) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    resendSignupEmail: builder.mutation({
      query: (credentials: IResendSignupData) => ({
        url: '/auth/send-email-confirmation',
        method: 'POST',
        body: credentials,
      }),
    }),
    getUserWithPicture: builder.query({
      query: (params) => ({
        url: `/users/${params.id}?populate=profilePhoto`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${params.jwt}`,
        },
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useFinishSignupMutation,
  useSigninMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useResendSignupEmailMutation,
  useGetUserWithPictureQuery,
} = authApiSlice;
