import { apiSlice } from "./apiSlice";

export const campaignBuilderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createProperty: builder.mutation({
      query: ({ propertyData }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/properties`,
        method: "POST",
        body: JSON.stringify({ data: propertyData }),
      }),
    }),
  }),
});

export const { useCreatePropertyMutation } = campaignBuilderApiSlice;
