import React from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

import { TestimonialSlide } from './TestimonialSlide';
import { Property } from '../../../helpers/convert';
import { useTestimonials } from '../../../hooks/useTestimonials';

export const Testimonials = () => {
  const testimonials = useTestimonials();
  const [swiperRef, setSwiperRef] = React.useState<SwiperClass>();
  const [currSlide, setCurrSlide] = React.useState(0);

  const handleSlideChange = (swiper: SwiperClass) => {
    setCurrSlide(swiper.activeIndex);
  };
  const handleSlideChangeManual = (index: number) => {
    if (swiperRef) {
      swiperRef.slideTo(index);
      setCurrSlide(index);
    }
  };

  return (
    <div
      style={{
        width: 'calc(100vw - 26px)',
        boxSizing: 'border-box',
        maxWidth: '2100px',
      }}
      className={'testimonials-properties-swiper-wrapper'}
    >
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        spaceBetween={20}
        modules={[EffectFade]}
        effect="fade"
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
      >
        {testimonials.map((testimonial, index) => {
          return (
            <SwiperSlide key={index}>
              <TestimonialSlide
                testimonial={testimonial}
                testimonials={testimonials}
                currSlide={currSlide}
                onSlideChange={handleSlideChangeManual}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
