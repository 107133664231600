import { Checkbox } from "@mui/material";

type AddToCardCheckBoxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export const AddToCardCheckBox = ({
  checked,
  onChange,
}: AddToCardCheckBoxProps) => {
  return (
    <Checkbox
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      sx={{
        padding: 0,
        margin: 0,
        color: "gray",
        "&.Mui-checked": {
          color: "blue",
        },
        "& .MuiSvgIcon-root": {
          fill: "white",
          borderRadius: "2px",
          width: "24px",
          height: "32px",
          boxSizing: "border-box",
        },
      }}
    />
  );
};
