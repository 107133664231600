import React from "react";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { useTranslation } from "react-i18next";

export const BackToMenuBtn = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);

  return (
    <div
      onClick={() => setCurrentStep(CampaignSideNavStep.campaignSetUp)}
      className="campaign-builder-sidenav-back-to-menu-btn"
      style={{
        marginTop: "20px",
      }}
    >
      {t("menu")}
    </div>
  );
};
