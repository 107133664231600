import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ScheduleAViewingBtn } from "./ScheduleAViewingBtn";
import { SpeakToAnAgentBtn } from "./SpeakToAnAgentBtn";
import { useTranslation } from "react-i18next";

export const AgentInfoBlock = ({
  newAgentAvatar,
}: {
  newAgentAvatar: any[];
}) => {
  const { i18n } = useTranslation(["campaignBuilder"]);

  const { selectedAgent, selectedUserId } = useSelector(
    (state: any) => state.campaignBuilder
  );
  const { scheduleVewingOpen, agentPhotOpen } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  const shouldShowPhoto =
    (agentPhotOpen && selectedAgent?.photo?.url) || newAgentAvatar[0]?.preview;
  const agentInitial = selectedAgent?.name ? selectedAgent?.name.charAt(0) : "";

  return (
    <div
      className="campaign-builder-agent-info-block"
      style={{
        direction: i18n.dir(),
      }}
    >
      <div className="campaign-builder-agent-info-block-name">
        <Avatar
          src={
            shouldShowPhoto && selectedUserId !== "create-new"
              ? selectedAgent?.photo?.url
              : newAgentAvatar[0]?.preview
          }
          sx={{ width: 47, height: 47 }}
        >
          {!shouldShowPhoto && agentInitial}
        </Avatar>

        <div>
          <div className="campaign-builder-agent-info-block-agent-name">
            {selectedAgent?.name}
          </div>
          <div className="campaign-builder-agent-info-block-agent-role">
            Agent
          </div>
        </div>
      </div>
      <div className="campaign-builder-agent-info-block-btns">
        {scheduleVewingOpen && <ScheduleAViewingBtn />}
        <SpeakToAnAgentBtn />
      </div>
    </div>
  );
};
