import React from "react";
import GreyPlus from "../../../assets/icons/grey_plus.svg";
import GreyMinus from "../../../assets/icons/grey_minus.svg";
import { Divider } from "@mui/material";

export const ControlMapPanel = ({
  handleZoomIn,
  handleZoomOut,
}: {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
}) => {
  return (
    <div className="control-map-panel-container">
      <div
        className="control-map-panel-element-plus"
        onClick={() => handleZoomIn()}
      >
        <img src={GreyPlus} />
      </div>
      <Divider
        sx={{
          width: "80%",
          borderColor: "rgba(225, 228, 241, 1)",
        }}
      />

      <div
        onClick={() => handleZoomOut()}
        className="control-map-panel-element-minus"
      >
        <img src={GreyMinus} />
      </div>
    </div>
  );
};
