import React, { useEffect } from 'react';
import { Detail, Property } from '../../helpers/convert';
import { useTranslation } from 'react-i18next';
import { Like } from '../_common/Like';
import { Divider, Theme, useMediaQuery } from '@mui/material';
import { useLanguageTheme } from '../../hooks/useLanguageTheme';

import BathroomIcon from '../../assets/icons/bathroom.svg';
import BedroomIcon from '../../assets/icons/bedBlue.svg';
import SquareIcon from '../../assets/icons/square.svg';

import { PropertyCardStamp } from './PropertyCardStamp';
import { useSelector } from 'react-redux';
import { mapCheckedSelector } from '../../store/features/portfolioSlice';
import { formatPrice } from '../../helpers/dataConverts';
import { useAuth } from '../../hooks/useAuth';

const propertyDetailsInit = {
  bedrooms: {
    name: 'bedrooms',
    icon: <img src={BedroomIcon} />,
  },
  bathrooms: {
    name: 'bathrooms',
    icon: <img src={BathroomIcon} />,
  },

  totalArea: {
    name: 'livingArea',
    icon: <img src={SquareIcon} />,
  },
};

interface ConvertedDetail {
  [key: string]: { name: string; icon: any; quantity?: number };
}

export const PropertyCard = ({ property }: { property: Property }) => {
  const { t } = useTranslation(['portfolio']);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'he';
  const langTheme = useLanguageTheme();
  const isAuthenticated = useAuth();

  const [propertyDetails, setDetails] = React.useState<ConvertedDetail>({});

  const convertDetails = (input: Detail, details: ConvertedDetail) => {
    const result: ConvertedDetail = {};
    for (const key in input) {
      if (details[key]) {
        result[key] = { ...details[key], quantity: input[key] as number };
      }
    }

    return result;
  };

  useEffect(() => {
    const detailedPropertys = convertDetails(
      property.details,
      propertyDetailsInit,
    );

    setDetails(detailedPropertys);
  }, [property]);

  return (
    <div
      className="property-card-container"
      style={{
        height: '100%',
      }}
    >
      <div
        className="property-card-container-card"
        style={{
          background: `url(${property.mainImage.url}) center center / cover no-repeat`,
          transform: isRTL ? 'scaleX(-1)' : 'scaleX(1)',
        }}
      ></div>

      <div
        className="property-card-additional-info"
        style={{
          position: 'relative',
        }}
      >
        <PropertyCardStamp />
        <div
          className={
            isAuthenticated
              ? 'property-card-price-and-heart-container'
              : 'property-card-price-and-heart-container-anonymous'
          }
          style={{
            direction: i18n.dir(),
          }}
        >
          {isAuthenticated ? (
            <div
              className="property-card-price"
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 600 : 700,
                direction: 'ltr',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontWeight: isRTL ? 600 : 700,
                  fontFamily: langTheme.mainFont(),
                }}
              >
                {isRTL ? '₪' : '$'}
              </div>
              <div
                style={{
                  fontFamily: 'DM Sans',
                }}
              >
                {formatPrice(property.pricing.basePrice)}
              </div>
            </div>
          ) : (
            ''
          )}

          <Like />
        </div>

        <div className="property-card-titles">
          <div
            style={{
              minHeight: '89px',
            }}
          >
            <div
              style={{
                fontFamily: isRTL ? 'Noto Sans Hebrew' : 'Frank Ruhl Libre',
                fontSize: '22px',
                fontWeight: isRTL ? 700 : 600,
                lineHeight: '26px',
                textTransform: 'uppercase',
                marginBottom: '11px',
                marginTop: '2px',
              }}
            >
              {property.name}
            </div>

            <div
              className="property-card-titles-location"
              style={{
                fontFamily: langTheme.mainFont(),
                marginBottom: '10px',
              }}
            >
              {isAuthenticated
                ? property.location.address + ',' + property.location.city
                : property.location.city}
            </div>
          </div>

          <Divider
            sx={{
              borderColor: 'rgba(0, 0, 0, 0.09)',
            }}
          />

          <div
            className="property-card-details"
            style={{
              direction: i18n.dir(),
            }}
          >
            {Object.entries(propertyDetails).map(([key, detail]) => (
              <div
                key={key}
                className="property-card-detail"
                style={{
                  fontFamily: langTheme.mainFont(),
                }}
              >
                <div>{detail.icon}</div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3px',
                  }}
                >
                  <div>{detail.quantity}</div>
                  <div>{t(detail.name)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
