import { Collapse, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReplaceBlack from "../../assets/campaignBuilder/replace.svg";
import { ExtractedMediaData } from "../../helpers/campaignConvert";
import { truncateString } from "../../helpers/formatter";
import {
  setFloorPlanSectionOpen,
  setMainLabelOpen,
  setMapSectionOpen,
  setVideoSectionOpen,
} from "../../store/features/campaignBuilderSetup";
import {
  removeFloorPlanFileById,
  removeVideoFileById,
} from "../../store/features/campaignBuilderSlice";
import { useAppDispatch } from "../../store/store";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampaignBuilderSwitcher } from "./CampaignBuilderSwitcher";
import {
  MAXIMUM_NUMBER_OF_FLOOR_PLAN,
  propertyImgAcceptFormat,
  videoAcceptFormat,
} from "./CampaignMediaStep";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import { FileDropZone } from "./FileDropZone";
import { FilesLoader } from "./FilesLoader";
import { LabelSelect } from "./LabelSelect";

export const CampaignLabelsStep = ({
  setCurrentStep,
  selectedLabelId,
  setSelectedLabelId,
  labelOptionsState,
  setFloorplanFiles,
  setVideoFiles,
  isVideoLoading,
  isFloorPlanLoading,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  selectedLabelId: any;
  setSelectedLabelId: any;
  labelOptionsState: any;
  setFloorplanFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setVideoFiles: any;
  isVideoLoading: boolean;
  isFloorPlanLoading: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const {
    mainLabelOpen,
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
  } = useSelector((state: any) => state.campaignBuilderSetup);

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
  };

  const handleChangeLabelOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setMainLabelOpen(event.target.checked));
  };

  const handleChangeFloorPlanOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setFloorPlanSectionOpen(event.target.checked));
  };

  const handleChangeVideoOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setVideoSectionOpen(event.target.checked));
  };

  const handleChangeMapOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMapSectionOpen(event.target.checked));
  };

  const handleChangeLableState = (labelId: string) => {
    setSelectedLabelId(labelId);
  };

  const { floorPlanFilesSlice, videoFilesSlice } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const handleRemoveFloorPlanFile = (fileId: number) => {
    dispatch(removeFloorPlanFileById(fileId));
  };

  const handleRemoveVideoFile = (fileId: number) => {
    dispatch(removeVideoFileById(fileId));
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="labels"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.media}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.investmentHighlights}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("property_main_label")}
              </div>
              <CampaignBuilderSwitcher
                open={mainLabelOpen}
                handleChangeOpen={handleChangeLabelOpen}
              />
            </div>

            <Collapse in={mainLabelOpen}>
              <div>
                <div className="campaign-builder-sidenav-field-name ">
                  {t("select_label")}
                </div>

                <LabelSelect
                  value={selectedLabelId}
                  onChangeValue={handleChangeLableState}
                  options={labelOptionsState}
                />

                <div
                  className="campaign-builder-sidenav-add-new-btn"
                  onClick={() =>
                    setCurrentStep(CampaignSideNavStep.addNewALabel)
                  }
                >
                  {t("add_new")}
                </div>
              </div>
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("floorplan")}
              </div>
              <CampaignBuilderSwitcher
                open={floorPlanSectionOpen}
                handleChangeOpen={handleChangeFloorPlanOpen}
              />
            </div>

            <Collapse in={floorPlanSectionOpen}>
              <FileDropZone
                fileAcceptFormat={propertyImgAcceptFormat}
                limit={MAXIMUM_NUMBER_OF_FLOOR_PLAN}
                setFiles={setFloorplanFiles}
                placeholder={"placeholder_upload_property_floor_plan"}
              />

              {floorPlanFilesSlice.length > 0 && (
                <div
                  style={{
                    paddingTop: "20px",
                  }}
                >
                  {floorPlanFilesSlice.map(
                    (item: ExtractedMediaData, index: number) => (
                      <div
                        key={item.name + index}
                        className="drag-and-drop-file-container"
                      >
                        <div className="drag-and-drop-file-container-img-container">
                          <div
                            style={{
                              background: `url(${item.url}) center center / cover no-repeat`,
                              width: "190px",
                              height: "110px",
                            }}
                          ></div>
                          <div
                            style={{
                              right: isRTL ? "auto" : "-8px",
                              left: isRTL ? "-8px" : "auto",
                            }}
                            className="replace-cross-wrapper"
                            onClick={() => handleRemoveFloorPlanFile(item.id)}
                          >
                            <img src={ReplaceBlack} />
                          </div>
                        </div>
                        <div className="drag-and-drop-file-name">
                          {truncateString(item.name, 25)}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              <FilesLoader isLoading={isFloorPlanLoading} />
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("video")}
              </div>
              <CampaignBuilderSwitcher
                open={videoSectionOpen}
                handleChangeOpen={handleChangeVideoOpen}
              />
            </div>

            <Collapse in={videoSectionOpen}>
              <FileDropZone
                fileAcceptFormat={videoAcceptFormat}
                limit={MAXIMUM_NUMBER_OF_FLOOR_PLAN}
                setFiles={setVideoFiles}
                placeholder={"placeholder_upload_property_video"}
              />

              {videoFilesSlice.length > 0 && (
                <div
                  style={{
                    paddingTop: "20px",
                  }}
                >
                  {videoFilesSlice.map((file: any, index: number) => (
                    <div
                      key={file.name + index}
                      className="drag-and-drop-file-container"
                    >
                      <div className="drag-and-drop-file-container-img-container">
                        <video
                          src={file.url}
                          controls
                          style={{
                            width: "190px",
                            height: "110px",
                          }}
                        />

                        <div
                          style={{
                            right: isRTL ? "auto" : "-8px",
                            left: isRTL ? "-8px" : "auto",
                          }}
                          className="replace-cross-wrapper"
                          onClick={() => handleRemoveVideoFile(file.id)}
                        >
                          <img src={ReplaceBlack} />
                        </div>
                      </div>
                      <div className="drag-and-drop-file-name">
                        {truncateString(file.name, 25)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <FilesLoader isLoading={isVideoLoading} />
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("map")}
              </div>
              <CampaignBuilderSwitcher
                open={mapSectionOpen}
                handleChangeOpen={handleChangeMapOpen}
              />
            </div>
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
