import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const ORBlock = () => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  return (
    <div className="signin-form-or-container">
      <div className="signin-horizontal-line"></div>
      <div
        className="signin-form-or-text"
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 400 : 500,
        }}
      >
        {t("or")}
      </div>
      <div className="signin-horizontal-line"></div>
    </div>
  );
};
