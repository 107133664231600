import React from "react";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import {
  Collapse,
  SelectChangeEvent,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDropZone } from "./FileDropZone";
import {
  FileType,
  MAXIMUM_NUMBER_OF_FLOOR_PLAN,
  propertyImgAcceptFormat,
  videoAcceptFormat,
} from "./CampaignMediaStep";
import { CampaignBuilderSwitcher } from "./CampaignBuilderSwitcher";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import {
  setFloorPlanSectionOpen,
  setMainLabelOpen,
  setMapSectionOpen,
  setVideoSectionOpen,
} from "../../store/features/campaignBuilderSetup";
import { CampaingBuilderSelect } from "./CampaingBuilderSelect";
import { LabelSelect } from "./LabelSelect";
import { BackToMenuBtn } from "./BackToMenuBtn";

export const CampaignLabelsStep = ({
  setCurrentStep,
  selectedLabelId,
  setSelectedLabelId,
  labelOptionsState,
  setLabelOptionsState,
  floorplanFiles,
  setFloorplanFiles,
  videoFiles,
  setVideoFiles,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  selectedLabelId: any;
  setSelectedLabelId: any;
  labelOptionsState: any;
  setLabelOptionsState: any;
  floorplanFiles: File[];
  setFloorplanFiles: React.Dispatch<React.SetStateAction<File[]>>;
  videoFiles: any;
  setVideoFiles: any;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const {
    mainLabelOpen,
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
  } = useSelector((state: any) => state.campaignBuilderSetup);

  const handleNext = (step: CampaignSideNavStep) => {
    //TODO add validations
    setCurrentStep(step);
  };

  const handleChangeLabelOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setMainLabelOpen(event.target.checked));
  };

  const handleChangeFloorPlanOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setFloorPlanSectionOpen(event.target.checked));
  };

  const handleChangeVideoOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setVideoSectionOpen(event.target.checked));
  };

  const handleChangeMapOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMapSectionOpen(event.target.checked));
  };

  const handleChangeLableState = (labelId: string) => {
    setSelectedLabelId(labelId);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="labels"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.media}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.investmentHighlights}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("property_main_label")}
              </div>
              <CampaignBuilderSwitcher
                open={mainLabelOpen}
                handleChangeOpen={handleChangeLabelOpen}
              />
            </div>

            <Collapse in={mainLabelOpen}>
              <div>
                <div className="campaign-builder-sidenav-field-name ">
                  {t("select_label")}
                </div>

                <LabelSelect
                  value={selectedLabelId}
                  onChangeValue={handleChangeLableState}
                  options={labelOptionsState}
                />

                <div
                  className="campaign-builder-sidenav-add-new-btn"
                  onClick={() =>
                    setCurrentStep(CampaignSideNavStep.addNewALabel)
                  }
                >
                  {t("add_new")}
                </div>
              </div>
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("floorplan")}
              </div>
              <CampaignBuilderSwitcher
                open={floorPlanSectionOpen}
                handleChangeOpen={handleChangeFloorPlanOpen}
              />
            </div>

            <Collapse in={floorPlanSectionOpen}>
              <FileDropZone
                fileAcceptFormat={propertyImgAcceptFormat}
                limit={MAXIMUM_NUMBER_OF_FLOOR_PLAN}
                files={floorplanFiles}
                setFiles={setFloorplanFiles}
                type={FileType.IMG}
                placeholder={"placeholder_upload_property_floor_plan"}
              />
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("video")}
              </div>
              <CampaignBuilderSwitcher
                open={videoSectionOpen}
                handleChangeOpen={handleChangeVideoOpen}
              />
            </div>

            <Collapse in={videoSectionOpen}>
              <FileDropZone
                fileAcceptFormat={videoAcceptFormat}
                limit={MAXIMUM_NUMBER_OF_FLOOR_PLAN}
                files={videoFiles}
                setFiles={setVideoFiles}
                type={FileType.VIDEO}
                placeholder={"placeholder_upload_property_video"}
              />
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("map")}
              </div>
              <CampaignBuilderSwitcher
                open={mapSectionOpen}
                handleChangeOpen={handleChangeMapOpen}
              />
            </div>
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
