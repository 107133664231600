import React from "react";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Location from "../../assets/icons/location.svg";
import Price from "../../assets/icons/price.svg";
import CloseInfo from "../../assets/icons/close_info.svg";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { Property } from "../../helpers/convert";
import { Like } from "../_common/Like";

export const PictureInfo = ({
  property,
  openSeeInfo,
  setCloseInfo,
}: {
  property: Property;
  openSeeInfo: boolean;
  setCloseInfo?: () => void;
}) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
const langTheme = useLanguageTheme()

  return (
    <div
      className="featured-properties-main-picture-info-container"
      style={{
        maxWidth: "382px",
        left: isRTL ? "auto" : "18px",
        right: isRTL ? "18px" : "auto",
      }}
    >
      <div
        className="featured-properties-main-picture-location"
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {property.name}, {property.location.city}
        </Typography>
        {openSeeInfo && (
          <img
            src={CloseInfo}
            onClick={setCloseInfo}
            style={{
              cursor: "pointer",
              transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
            }}
          />
        )}
      </div>
      <div className="featured-properties-additional-info">
        <div className="featured-properties-additional-info-container">
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <img
              src={Location}
              style={{
                marginRight: isRTL ? "0" : "8px",
                marginLeft: isRTL ? "8px" : 0,
              }}
            />
            <span
              style={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              {property.location.city}
            </span>
          </div>
          <div>
            <img
              src={Price}
              style={{
                marginRight: isRTL ? "0" : "8px",
                marginLeft: isRTL ? "8px" : 0,
              }}
            />
            <span
              style={{
                fontFamily: isRTL ? "DM Sans" : "DM Sans",
                direction: "ltr",
              }}
            >
              {isRTL ? "₪" : "$"} {property.pricing.basePrice}
            </span>
          </div>
        </div>
        <Like />
      </div>
    </div>
  );
};
