import React from "react";
import {
  KeyFeatureIconMapSelected,
  KeyFuatureIconKey,
} from "../../constants/campaignBuilderData";

export const KeyFeatureIconSelect = ({
  selectedIcon,
  setSelectedIcon,
  options,
  iconMap,
  selectedIconMap
}: {
  selectedIcon: any;
  setSelectedIcon: (icon: KeyFuatureIconKey) => void;
  options: any;
  iconMap: any;
  selectedIconMap: any
}) => {
  return (
    <div className="campaign-builder-side-nav-icon-select">
      {options.map((option: any) => {
        let IconComponent;
        if (selectedIcon === option.icon_key) {
          IconComponent = selectedIconMap[option.icon_key];
        } else {
          IconComponent = iconMap[option.icon_key];
        }
        return (
          <div
            key={option.labelName}
            onClick={() => setSelectedIcon(option.icon_key)}
            style={{
              cursor: "pointer",
            }}
          >
            <IconComponent
              isSelected={selectedIcon === option.icon_key}
              width={32}
              height={32}
              backgroundColor={option.backgroundColor}
            />
          </div>
        );
      })}
    </div>
  );
};
