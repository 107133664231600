import { InputAdornment, TextField, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { textFieldStyles } from "./MainInformationStep";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import React, { useState } from "react";
import { PreviouslyAdded } from "./PreviouslyAdded";
import {
  KeyFeatureIconMap,
  KeyFeatureIconMapBlue,
  KeyFeatureIconMapSelected,
  keyFeatureOptions,
} from "../../constants/campaignBuilderData";
import { KeyFeatureIconSelect } from "./KeyFeatureIconSelect";

export const MAX_FEATURE_LENGTH = 60;

export const AddNewFeature = ({
  setCurrentStep,
  keyfeatureOptionsState,
  setKeyfeatureOptionsState,
  addedKeyFeatureOptions,
  setAddedKeyFeatureOptions,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  keyfeatureOptionsState: any;
  setKeyfeatureOptionsState: any;
  addedKeyFeatureOptions: any;
  setAddedKeyFeatureOptions: any;
}) => {
  const langTheme = useLanguageTheme();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [featureName, setFeatureName] = React.useState("");
  const [selectedIconKey, setSelectedIconKey] =
    React.useState<keyof typeof KeyFeatureIconMap>("stair");

  const handleChangeFeatureName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setFeatureName(value);
  };

  const handleAddOption = (event: any) => {
    const newOption = {
      id: crypto.randomUUID(),
      labelName: featureName,
      icon_key: selectedIconKey,
      selectedToCard: false,
      selectId: crypto.randomUUID(),
    };

    setAddedKeyFeatureOptions([...addedKeyFeatureOptions, newOption]);
    setKeyfeatureOptionsState([...keyfeatureOptionsState, newOption]);
  };

  const handleDeleteOption = (id: string) => {
    const updatedAddedOptions = addedKeyFeatureOptions.filter(
      (option: any) => option.id !== id
    );
    setAddedKeyFeatureOptions(updatedAddedOptions);

    const updatedLabelOptionsState = keyfeatureOptionsState.filter(
      (option: any) => option.id !== id
    );
    setKeyfeatureOptionsState(updatedLabelOptionsState);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="add_a_new_feature"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.keyFeatures}
          />
        }
        rightBtn={<div className="campaign-builder-sidenav-empty-btn"></div>}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("message")}
              </div>

              <TextField
                fullWidth
                placeholder={t("message_placeholder")}
                value={featureName}
                onChange={handleChangeFeatureName}
                sx={textFieldStyles(langTheme.mainFont())}
                autoComplete="off"
                inputProps={{
                  maxLength: MAX_FEATURE_LENGTH,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        height: "100%",
                        ml: 0,
                        maxHeight: "none",
                        marginRight: isRTL ? "-14px" : "0",
                        pl: isRTL ? "20px" : "0px",
                        pr: isRTL ? "-14px" : "-14px",
                        color: "rgba(0, 0, 0, 1)",
                      }}
                      position="start"
                    >
                      <div
                        className="campaign-builder-sidenav-number-of-char"
                        style={{
                          fontFamily: langTheme.mainFont(),
                        }}
                      >
                        {featureName.length}/{MAX_FEATURE_LENGTH}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("choose_icon")}
              </div>

              <KeyFeatureIconSelect
                selectedIcon={selectedIconKey}
                setSelectedIcon={setSelectedIconKey}
                options={keyFeatureOptions}
                iconMap={KeyFeatureIconMap}
                selectedIconMap={KeyFeatureIconMapSelected}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-line"></div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div
                className="campaign-builder-sidenav-field-name"
                style={{
                  fontSize: "16px",
                }}
              >
                {t("previously_added")}
              </div>
            </div>
          </div>

          {addedKeyFeatureOptions.map((option: any, index: any) => (
            <div key={index}>
              <PreviouslyAdded
                option={option}
                handleDelete={handleDeleteOption}
                iconMap={KeyFeatureIconMapBlue}
              />
            </div>
          ))}
        </div>

        <div
          className="campaign-builder-sidenav-add-label-btn-big"
          onClick={handleAddOption}
        >
          {t("add_this_feature")}
        </div>
      </div>
    </div>
  );
};
