import React from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { ReactComponent as SelectArrow } from "../../assets/campaignBuilder/select_arror.svg";
import { selectStyles } from "./CampaingBuilderSelect";
import { HighlightIconMap } from "../../constants/campaignBuilderData";

export type HightlightIconKey = keyof typeof HighlightIconMap;

export interface Option {
  id: string;
  icon_key: HightlightIconKey;
  labelName: string;
}

export const HighlightSelect = ({
  value,
  onChangeValue,
  options,
  iconMap,
}: {
  value: any;
  onChangeValue: any;
  options: any;
  iconMap: any;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <Select
      value={value}
      onChange={(event) => onChangeValue(event.target.value as string)}
      sx={selectStyles(langTheme.mainFont(), isRTL)}
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(232, 239, 242, 1)",
            },
          },
        },
      }}
      IconComponent={SelectArrow}
      renderValue={(id) => {
        const option = options.find((opt: any) => opt.id === id);
        if (!option) return null;

        const IconComponent = iconMap[option.icon_key];

        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "7px" }}>
            <IconComponent
              isSelected={false}
              width={17}
              height={19}
              backgroundColor={"#034A73"}
            />
            <div>{t(option.labelName)}</div>
          </Box>
        );
      }}
    >
      {options.map((option: Option) => {
        const IconComponent = iconMap[option.icon_key];

        return (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              color: "rgba(0, 0, 0, 1)",
              fontFamily: langTheme.mainFont(),
              fontWeight: 500,
              fontSize: "14px",
              gap: "7px",
            }}
          >
            <IconComponent
              isSelected={false}
              width={17}
              height={19}
              backgroundColor={"#034A73"}
            />
            <div>{t(option.labelName)}</div>
          </MenuItem>
        );
      })}
    </Select>
  );
};
