import { Box, Dialog, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftBlue from "../../assets/icons/arrow_left_blue.svg";
import ArrowRightWhite from "../../assets/icons/arrow_right_white.svg";
import Oasis from "../../assets/oasis_img.png";
import { PictureInfo } from "./PictureInfo";
import InfoIcon from "../../assets/icons/info.svg";
import { Property } from "../../helpers/convert";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import TrianglePlay from "../../assets/icons/triangle.svg";

import { Swiper, SwiperClass, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ExporeBlock } from "./ExploreBlock/ExporeBlock";

export const FeaturedPropertiesSlide = ({
  property,
}: {
  property: Property;
}) => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
const langTheme = useLanguageTheme()

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const [openSeeInfo, setOpenSeeInfo] = React.useState(false);

  const [openVideoDialog, setOpenVideoDialog] = React.useState(false);
  const handleClickOpenVideo = () => {
    setOpenVideoDialog(true);
  };
  const handleCloseVideo = () => {
    setOpenVideoDialog(false);
  };

  const [swiperKey, setSwiperKey] = React.useState(0);
  React.useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [property.otherMedia, i18n.language]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "1fr",
          md: "1fr",
          lg: "0.9fr 1fr",
        },
        gap: 2,
        direction: i18n.dir(),
      }}
    >
      <Box className={"featured-properties-main-picture-container "}>
        <div
          style={{
            borderRadius: "20px",
            minHeight: downMd ? "350px" : "542px",
            height: downMd ? "350px" : "100%",

            background: `url(${property.mainImage.url}) center center / cover no-repeat`,
          }}
        ></div>

        {downMd ? (
          <div
            onClick={() => setOpenSeeInfo(true)}
            className="featured-properties-see-info"
            style={{
              left: isRTL ? "auto" : "18px",
              right: isRTL ? "18px" : "auto",
              direction: i18n.dir(),
              gap: "7px",
              cursor: "pointer",
              fontFamily: langTheme.mainFont(),
            }}
          >
            <img src={InfoIcon} />
            <div>{t("see_info")}</div>
          </div>
        ) : (
          <PictureInfo property={property} openSeeInfo={false} />
        )}

        {openSeeInfo && downMd && (
          <PictureInfo
            property={property}
            openSeeInfo={openSeeInfo}
            setCloseInfo={() => setOpenSeeInfo(false)}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateAreas: isRTL
            ? {
                xs: `
                  "video slider"
                  "explore explore"
                `,
                sm: `
                  "video slider"
                  "explore explore"
                `,
                md: `
                  "video slider"
                  "explore explore"
                `,
                lg: `
                  "slider slider"
                  "explore video"
                `,
              }
            : {
                xs: `
                  "slider video"
                  "explore explore"
                `,
                sm: `
                  "slider video"
                  "explore explore"
                `,
                md: `
                  "slider video"
                  "explore explore"
                `,
                lg: `
                  "slider slider"
                  "video explore"
                `,
              },
          gridTemplateColumns: {
            xs: "1fr 1fr",
            sm: "1fr 1fr",
            lg: "1fr 1fr",
          },
          gridTemplateRows: {
            xs: "1fr",
            sm: "1fr",
            md: "2fr 1fr",
            lg: "2fr 1fr",
          },
          gap: 2,
        }}
      >
        <Box
          className="fp-inner-slider"
          sx={{
            gridArea: "slider",
          }}
        >
          <Swiper
            key={swiperKey}
            style={{
              height: "100%",
              minHeight: "200px",
              borderRadius: "20px",
              width: "50vw",
              maxWidth: "100%",
            }}
            pagination={{
              clickable: true,
            }}
            speed={1000}
            modules={[Pagination]}
          >
            {property.otherMedia.map((media, i) => {
              return (
                <SwiperSlide
                  key={i}
                  style={{ height: "100%", objectFit: "cover" }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      background: `url(${media.url}) center center / cover no-repeat`,
                    }}
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>

        <Box
          sx={{
            gridArea: "video",
            width: "100%",
            height: "100%",
            position: "relative",
            cursor: "pointer",
          }}
          onClick={handleClickOpenVideo}
        >
          <div
            style={{
              borderRadius: "20px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              background: `url(${property.videoPreview.url}) center center / cover no-repeat`,
            }}
          ></div>
          <div
            style={{
              width: "100%",
              borderRadius: "20px",
              height: "100%",
              objectFit: "cover",
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "58px",
                height: "58px",
                borderRadius: "58px",
                backgroundColor: "rgba(104, 191, 210, 0.26)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "44px",
                  height: "44px",
                  borderRadius: "44px",
                  backgroundColor: "rgba(104, 191, 210, 1)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "14px",
                    left: "16px",
                  }}
                >
                  <img src={TrianglePlay} />
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Dialog
          open={openVideoDialog}
          onClose={handleCloseVideo}
          maxWidth={"lg"}
          PaperProps={{
            sx: { borderRadius: "20px", backgroundColor: "rgb(0 0 0 / 72%)" },
          }}
          BackdropProps={{ sx: { backgroundColor: "rgb(0 0 0 / 72%)" } }}
          sx={{
            borderRadius: "20px",
          }}
        >
          <video width={"100%"} src={property.video.url} controls></video>
        </Dialog>

        <Box
          sx={{
            gridArea: "explore",
            display: downLg ? "" : "flex",
          }}
        >
          <ExporeBlock />
        </Box>
      </Box>
    </Box>
  );
};
