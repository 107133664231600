import { IPriceRanges } from "../types/properties";
import main1 from "../assets/fake/main/1.jpg";
import main3 from "../assets/fake/main/3.jpg";
import main4 from "../assets/fake/main/4.jpg";
import main5 from "../assets/fake/main/5.jpg";
import main6 from "../assets/fake/main/6.jpg";
import { IFilter } from "../pages/Portfolio";

type FilterKey = string;
export type FilterMap = { [key: FilterKey]: IFilter };

export function getFiltersFromMap(filterMap: FilterMap): IFilter[] {
  return Object.values(filterMap);
}

export function getFiltersByIds(
  filterMap: FilterMap,
  ids: FilterKey[]
): IFilter[] {
  const filters: IFilter[] = [];

  for (const id of ids) {
    const filter = filterMap[id];
    if (filter) {
      filters.push(filter);
    }
  }

  return filters;
}

export const locationFilterMap: FilterMap = {
  "0": {
    key: "0",
    name: { en: "Tel Aviv", he: "תל אביב" },
    translationKey: "tel_aviv",
  },
  "1": {
    key: "1",
    name: { en: "Jerusalem", he: "ירושלים" },
    translationKey: "jerusalem",
  },
  "2": {
    key: "2",
    name: { en: "Haifa", he: "חיפה" },
    translationKey: "haifa",
  },
  "3": {
    key: "3",
    name: { en: "Eilat", he: "אילת" },
    translationKey: "eilat",
  },
  "4": {
    key: "4",
    name: { en: "Rishon LeZion", he: "ראשון לציון" },
    translationKey: "rishon_lezion",
  },
  "5": {
    key: "5",
    name: { en: "Herzliya", he: "הרצליה" },
    translationKey: "herzliya",
  },
  "6": {
    key: "6",
    name: { en: "Ramat Gan", he: "רמת גן" },
    translationKey: "ramat_gan",
  },
};

export const typeFilterMap: FilterMap = {
  "0": {
    key: "0",
    name: { en: "Penthouse", he: "פנטהאוז" },
    translationKey: "penthouse",
  },
  "1": {
    key: "1",
    name: { en: "Garden Apartment", he: "דירת גן" },
    translationKey: "garden_apartment",
  },
  "2": {
    key: "2",
    name: { en: "Duplex", he: "דופלקס" },
    translationKey: "duplex",
  },
  "3": {
    key: "3",
    name: { en: "Triplex", he: "טריפלקס" },
    translationKey: "triplex",
  },
  "4": {
    key: "4",
    name: { en: "Villa/House", he: "וילה/בית" },
    translationKey: "villa_house",
  },
  "5": {
    key: "5",
    name: { en: "Townhouse", he: "בית עירוני" },
    translationKey: "townhouse",
  },
  "6": {
    key: "6",
    name: { en: "Rooftop Apartment", he: "דירת גג" },
    translationKey: "rooftop_apartment",
  },
  "7": {
    key: "7",
    name: { en: "Studio Apartment (Premium)", he: "דירת סטודיו (פרימיום)" },
    translationKey: "studio_apartment_premium",
  },
  "8": {
    key: "8",
    name: { en: "Office Space", he: "שטח משרדים" },
    translationKey: "office_space",
  },
  "9": {
    key: "9",
    name: { en: "Retail Space", he: "שטח מסחרי" },
    translationKey: "retail_space",
  },
  "10": {
    key: "10",
    name: { en: "Mixed-Use Development", he: "פיתוח משולב" },
    translationKey: "mixed_use_development",
  },
  "11": {
    key: "11",
    name: { en: "Commercial Building", he: "בניין מסחרי" },
    translationKey: "commercial_building",
  },
  "12": {
    key: "12",
    name: { en: "Business Center", he: "מרכז עסקים" },
    translationKey: "business_center",
  },
  "13": {
    key: "13",
    name: { en: "Luxury Apartment", he: "דירת יוקרה" },
    translationKey: "luxury_apartment",
  },
};

export const statusFilterMap: FilterMap = {
  "0": {
    key: "0",
    name: { en: `Pre-Sale ("On Paper")`, he: `לפני מכירה (״על הנייר״)` },
    translationKey: "pre_sale_on_paper",
  },
  "1": {
    key: "1",
    name: { en: "Under Construction", he: "בקרוב בבנייה" },
    translationKey: "under_construction",
  },
  "2": {
    key: "2",
    name: { en: "Ready for Occupancy", he: "מוכן לאכלוס" },
    translationKey: "ready_for_occupancy",
  },
  "3": {
    key: "3",
    name: { en: "Immediate Availability", he: "זמינות מיידית" },
    translationKey: "immediate_availability",
  },
  "4": {
    key: "4",
    name: { en: "Coming Soon", he: "בקרוב" },
    translationKey: "coming_soon",
  },
};

export const locationFilters = getFiltersFromMap(locationFilterMap);
export const typeFilters = getFiltersFromMap(typeFilterMap);
export const statusFilters = getFiltersFromMap(statusFilterMap);

export const priceRanges: { [key: number]: IPriceRanges } = {};

export const sortOptions = [
  {
    id: 0,
    valueEn: "the newest",
    valueHe: "החדש ביותר",
    sortDirection: "asc",
    nameSortParam: "created_at",
    addParam: "",
    translationKey: "sort_newest",
  },
  {
    id: 1,
    valueEn: "by price (low to high)",
    valueHe: "לפי מחיר (מהנמוך לגבוה)",
    sortDirection: "asc",
    nameSortParam: "pricing",
    addParam: "basePrice",
    translationKey: "sort_price_low_high",
  },
  {
    id: 2,
    valueEn: "by price (high to low)",
    valueHe: "לפי מחיר (מהגבוה לנמוך)",
    sortDirection: "desc",
    nameSortParam: "pricing",
    addParam: "basePrice",
    translationKey: "sort_price_high_low",
  },
  {
    id: 3,
    valueEn: "by area (low to high)",
    valueHe: "לפי שטח (מהנמוך לגבוה)",
    sortDirection: "asc",
    nameSortParam: "details",
    addParam: "totalArea",
    translationKey: "sort_area_low_high",
  },
  {
    id: 4,
    valueEn: "by area (high to low)",
    valueHe: "לפי שטח (מהגבוה לנמוך)",
    sortDirection: "desc",
    nameSortParam: "details",
    addParam: "totalArea",
    translationKey: "sort_area_high_low",
  },
];

export const testimonials = [
  {
    img: main1,
    nameEn: "Melanie Grossman",
    occupationEn: "Buyer",
    testimonialEn:
      "From the first acquaintance with the object to the registration of the transaction, everything was at the highest level. Now we are enjoying a new home that perfectly meets our expectations!",
    nameHe: "מלאני גרוסמן",
    occupationHe: "קונה",
    testimonialHe:
      "מההיכרות הראשונה עם הנכס ועד לרישום העסקה, הכל היה ברמה הגבוהה ביותר. כעת אנו נהנים מבית חדש שעונה בצורה מושלמת על הציפיות שלנו!",
  },
  {
    img: main3,

    nameEn: "David Cohen",
    occupationEn: "Investor",
    testimonialEn:
      "The process was smooth and efficient. The team understood our needs perfectly and helped us find the ideal investment property.",
    nameHe: "דוד כהן",
    occupationHe: "משקיע",
    testimonialHe:
      "התהליך היה חלק ויעיל. הצוות הבין את הצרכים שלנו בצורה מושלמת ועזר לנו למצוא את הנכס להשקעה האידאלי.",
  },
  {
    img: main4,

    nameEn: "Sara Levi",
    occupationEn: "Homeowner",
    testimonialEn:
      "Excellent service from start to finish. The team was very professional and guided us through the entire process with ease.",
    nameHe: "שרה לוי",
    occupationHe: "בעלת בית",
    testimonialHe:
      "שירות מצוין מהתחלה ועד הסוף. הצוות היה מאוד מקצועי והנחה אותנו בכל התהליך בקלות.",
  },
  {
    img: main5,

    nameEn: "Michael Rosen",
    occupationEn: "Renter",
    testimonialEn:
      "I was impressed by the level of dedication and attention to detail. They found the perfect rental property for us!",
    nameHe: "מיכאל רוזן",
    occupationHe: "שוכר",
    testimonialHe:
      "התרשמתי מרמת ההשקעה ותשומת הלב לפרטים. הם מצאו את הנכס להשכרה המושלם עבורנו!",
  },
  {
    img: main6,

    nameEn: "Lea Abramov",
    occupationEn: "Seller",
    testimonialEn:
      "The sale process was handled with great expertise and professionalism. I highly recommend their services.",
    nameHe: "לאה אברמוב",
    occupationHe: "מוכרת",
    testimonialHe:
      "תהליך המכירה טופל במיומנות ומקצועיות רבה. אני ממליצה בחום על השירותים שלהם.",
  },
];
