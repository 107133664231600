import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ISideNavState {
  sideNavOpen: boolean;
  authPopupOpen: boolean;
  isLoginPopupContent: boolean;
  campaignBuilderSideBarOpen: boolean;
}

const initialState: ISideNavState = {
  sideNavOpen: false,
  authPopupOpen: false,
  isLoginPopupContent: false,
  campaignBuilderSideBarOpen: true,
};

export const sideNavOpenSlice = createSlice({
  name: "sideNav",
  initialState,
  reducers: {
    setOpenSideNav(state) {
      state.sideNavOpen = true;
    },
    setCloseSideNav(state) {
      state.sideNavOpen = false;
    },
    setSignupPopupOpen(state) {
      state.authPopupOpen = true;
    },
    setSignupPopupClose(state) {
      state.authPopupOpen = false;
    },
    setIsLoginPopupContent(state, actions) {
      state.isLoginPopupContent = actions.payload.isLoginPopupContent;
    },
    setCampaingBuilderSideBarOpen(state) {
      state.campaignBuilderSideBarOpen = true;
    },
    setCampaingBuilderSideBarClose(state) {
      state.campaignBuilderSideBarOpen = false;
    },
  },
});

const sideNavOpenState = (state: RootState) => state.sideNav;

export const sideNavOpenSelector = createSelector(
  sideNavOpenState,
  (s) => s.sideNavOpen
);

export const authPopupOpenSelector = createSelector(
  sideNavOpenState,
  (s) => s.authPopupOpen
);

export const isLoginPopupContentSelector = createSelector(
  sideNavOpenState,
  (s) => s.isLoginPopupContent
);

export const campaignBuilderOpenSelector = createSelector(
  sideNavOpenState,
  (s) => s.campaignBuilderSideBarOpen
);

export const {
  setOpenSideNav,
  setCloseSideNav,
  setSignupPopupOpen,
  setSignupPopupClose,
  setIsLoginPopupContent,
  setCampaingBuilderSideBarOpen,
  setCampaingBuilderSideBarClose,
} = sideNavOpenSlice.actions;

export default sideNavOpenSlice.reducer;
