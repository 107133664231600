import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KeyFeatureIconMapBlue } from "../../constants/campaignBuilderData";
import { AddToCardCheckBox } from "./AddToCardCheckBox";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import { HighlightSelect } from "./HighlightSelect";

export interface IKeyFeature {
  id: string;
  selectId: string;
  labelName: string;
  icon_key: string;
  selectedToCard: boolean;
}
export const KeyFeature = ({
  setCurrentStep,
  keyfeatureOptionsState,
  selectedFeatureIds,
  setSelectedFeatureIds,
  setKeyfeatureOptionsState,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  keyfeatureOptionsState: any;
  selectedFeatureIds: any;
  setSelectedFeatureIds: any;
  setKeyfeatureOptionsState: any;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
  };

  const handleChangeKeyFeatureState = (index: number, keyFeatureId: string) => {
    setSelectedFeatureIds((prev: any) => {
      const newIds = [...prev];
      newIds[index] = keyFeatureId;
      return newIds;
    });
  };

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setKeyfeatureOptionsState((prevState: any) =>
      prevState.map((feature: any) =>
        feature.id === id ? { ...feature, selectedToCard: checked } : feature
      )
    );
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="key_features"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.aboutProperty}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.aboutDeveloper}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          {selectedFeatureIds.map((selectedId: string, index: number) => {
            const item = keyfeatureOptionsState.find(
              (opt: any) => opt.id === selectedId
            );

            return (
              <div
                key={index}
                className="campaign-builder-sidenav-settings-fileds-item"
              >
                <div className="select-highlight-container">
                  <div className="campaign-builder-sidenav-field-name">
                    {t("select_feature")}
                  </div>
                </div>

                <HighlightSelect
                  value={selectedId}
                  onChangeValue={(newId: any) =>
                    handleChangeKeyFeatureState(index, newId)
                  }
                  options={keyfeatureOptionsState}
                  iconMap={KeyFeatureIconMapBlue}
                />

                <div className="campaign-builder-sidenav-add-to-card-container">
                  <AddToCardCheckBox
                    checked={item?.selectedToCard ?? false}
                    onChange={(checked: boolean) =>
                      handleCheckboxChange(selectedId, checked)
                    }
                  />
                  <div className="campaign-builder-sidenav-add-to-card">
                    {t("add_to_property_card")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <div
            className="campaign-builder-sidenav-add-new-btn"
            onClick={() => setCurrentStep(CampaignSideNavStep.addaNewFeature)}
          >
            {t("add_new")}
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
