import React from "react";
import { useTranslation } from "react-i18next";

export const CampaignBuilderSideNavHeader = ({
  header,
  leftBtn,
  rightBtn,
}: {
  header: string;
  leftBtn: React.ReactNode;
  rightBtn: React.ReactNode;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <div
      className="campaign-builder-sidenav-header"
      // style={{
      //   direction: i18n.dir(),
      // }}
    >
      {leftBtn}
      <div
        className="campaign-builder-sidenav-header-title"
        style={{
          letterSpacing: isRTL ? "" : "-0.32px",
        }}
      >
        {t(header)}
      </div>

      {rightBtn}
    </div>
  );
};
