import { apiSlice } from "../api/apiSlice";

export const agentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgents: builder.query<any, void>({
      query: () => {
        return `/agents?populate=photo`;
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
    createAgent: builder.mutation({
      query: ({ agentData }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/agents`,
        method: "POST",
        body: JSON.stringify({ data: agentData }),
      }),
    }),
    uploadImg: builder.mutation({
      query: (data) => ({
        url: `/upload`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetAgentsQuery,
  useLazyGetAgentsQuery,
  useCreateAgentMutation,
  useUploadImgMutation,
} = agentsApiSlice;
