import React from "react";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import { TextField, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDropZone } from "./FileDropZone";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { Errors, textFieldStyles } from "./MainInformationStep";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { setAboutDeveloper } from "../../store/features/campaignBuilderSlice";

export const AboutDeveloper = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();
  const [errors, setErrors] = React.useState<any>({});

  const { aboutDeveloper } = useSelector((state: any) => state.campaignBuilder);

  const handleChangePropertyDesc = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newErrors = { ...errors };

    const value = event.target.value;
    dispatch(setAboutDeveloper(value));

    if (!value.trim()) {
      newErrors.aboutDeveloper = t("errors.desc_is_required");
    } else {
      delete newErrors.aboutDeveloper;
    }
    setErrors(newErrors);
  };

  const validateFields = () => {
    let errors: Partial<Errors> = {};

    if (aboutDeveloper.trim() === "") {
      errors.aboutDeveloper = t("errors.desc_is_required");
    } else if (aboutDeveloper.trim().length < 100) {
      errors.aboutDeveloper = t("errors.min_100_chars_required");
    }

    return errors;
  };

  const handleNext = (step: CampaignSideNavStep) => {
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setCurrentStep(step);
    }
  };
  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="about_developer"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.keyFeatures}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.contactInformation}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("message")}
            </div>

            <TextField
              fullWidth
              multiline
              rows={6.4}
              placeholder={t("about_developer_placeholder")}
              sx={textFieldStyles(langTheme.mainFont(), errors.aboutDeveloper)}
              autoComplete="off"
              value={t(aboutDeveloper)}
              onChange={handleChangePropertyDesc}
              inputProps={{ maxLength: 2000 }}
            />

            {errors.aboutDeveloper && (
              <div className="campaign-builder-errors">
                {errors.aboutDeveloper}
              </div>
            )}
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
