import React from "react";
import GetInTouch1 from "../../assets/get_in_touch1.png";
import GetInTouch2 from "../../assets/get_in_touch2.png";
import { useTranslation } from "react-i18next";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import Arrow from "../../assets/icons/arrow.svg";

export const GetInTouchBlock = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downXl = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return (
    <div
      className="get-in-touch-wrapper"
      style={{
        paddingTop: downMd ? "38px" : "50px",
        paddingBottom: downMd ? "45px" : "50px",
      }}
    >
      <div
        className="xxl-width"
        style={{
          marginRight: "20px",
          marginLeft: "20px",
          display: "flex",
          gap: downXl ? "35px" : "50px",
          flexDirection: downLg ? "column-reverse" : "row",
          // justifyContent: isRTL ? "space-between" : "flex-start",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: isRTL ? "flex-end" : "flex-start",
            width: downLg ? "100%" : "auto",
            gap: downLg ? "10px" : "15px",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "70%",
              maxWidth: downXl ? "500px" : "444px",
              paddingLeft: downMd ? "11px" : "20px",
            }}
          >
            <div
              style={{
                position: "relative",

                zIndex: 3,
                width: "100%",
              }}
            >
              <img
                src={GetInTouch1}
                style={{
                  position: "relative",
                  width: "100%",
                  zIndex: 3,
                }}
              />
              <div
                style={{
                  backgroundColor: "rgba(215, 161, 72, 1)",
                  width: "164px",
                  height: "146px",
                  position: "absolute",
                  bottom: downMd ? "-11px" : "-20px",
                  zIndex: 1,
                  left: downMd ? "-11px" : "-20px",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              width: "30%",
              maxWidth: downLg ? "300px" : downXl ? "160px" : "190px",
            }}
          >
            <img
              src={GetInTouch2}
              style={{
                marginTop: downLg ? "0" : "44px",
                width: "100%",
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isRTL ? "flex-end" : "flex-start",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: langTheme.mainFont(),
              color: "rgba(252, 188, 92, 1)",
              mb: downMd ? "9px" : "0",
              fontWeight: 600,
            }}
          >
            {t("get_in_touch")}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              textTransform: "uppercase",
              mb: downMd ? "15px" : "25px",
              color: "rgba(255, 255, 255, 1)",
              maxWidth: "444px",
              textAlign: isRTL ? "end" : "start",
            }}
          >
            {t("get_in_touch_main_title")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              color: "#fff",
              width: "fit-content",
              backgroundColor: "rgba(215, 161, 72, 1)",
              p: "15px 30px 15px 30px",
              cursor: "pointer",
              borderRadius: "24px",
              direction: i18n.dir(),
              transition: "background 2s ease-in-out .5s",
              "&:hover": {
                background:
                  "linear-gradient(to right, rgba(215, 161, 72, 1), rgba(255, 225, 78, 1))",
              },
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                mr: isRTL ? "0" : "12px",
                ml: isRTL ? "12px" : "0",
                fontWeight: isRTL ? 600 : 500,
                fontFamily: langTheme.mainFont(),
                lineHeight: "16px",
              }}
            >
              {t("contact_us")}
            </Typography>
            <img
              src={Arrow}
              alt="arrow"
              style={{
                transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
              }}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};
