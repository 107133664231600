import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const ResetPasswordBtn = ({ onSubmit }: { onSubmit: any }) => {
  const { t, i18n } = useTranslation(["signin"]);
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();

  return (
    <button
      type="submit"
      onClick={onSubmit}
      className="signin-form-inputs-signin-btn"
      style={{
        fontFamily: langTheme.mainFont(),
        fontWeight: isRTL ? 700 : 500,
        marginTop: "15px",
      }}
    >
      {t("reset_password")}
    </button>
  );
};
