import React, { useEffect } from "react";
import FilledHeartIcon from "../../assets/icons/filled_heart.svg";
import OutlinedHeartIcon from "../../assets/icons/outline_heart.svg";
import { setSignupPopupOpen } from "../../store/features/common";
import { useAppDispatch } from "../../store/store";

export const Like = () => {
  const dispatch = useAppDispatch();
  const [clickedLike, setClickedLike] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const isAuthenticated = false;

  const handleChangeClicked = () => {
    if (isAuthenticated) {
      setClickedLike(true);
    } else {
      dispatch(setSignupPopupOpen());
    }
  };

  return (
    <div
      className="featured-properties-like"
      onClick={handleChangeClicked}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={clickedLike || isHovered ? FilledHeartIcon : OutlinedHeartIcon}
        alt="Like Button"
        className={`heart-icon ${
          clickedLike || isHovered ? "fade-in" : "fade-out"
        }`}
      />
    </div>
  );
};
