import React from "react";
import CalendarIcon from "../../assets/campaignBuilder/calendar.svg";
import { useTranslation } from "react-i18next";

export const ScheduleAViewingBtn = () => {
  const { t } = useTranslation(["campaignBuilder"]);

  return (
    <div className="schedule-a-viewing-btn">
      <img src={CalendarIcon} />
      <div>{t("schedule_a_viewing")}</div>
    </div>
  );
};
