import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../store/store";
import { setPreviewMode } from "../../store/features/campaignBuilderSetup";
import { useSelector } from "react-redux";

export enum PreviewMode {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

const Container = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
});

const Slider = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "48%",
  backgroundColor: "#034A73",
  borderRadius: "40px",
  transition: "transform 0.5s ease",
  zIndex: 1,
  top: "6px",
  left: "6px",
  height: "calc(100% - 12px)",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  zIndex: 2,
  backgroundColor: "#8F8F9E33",
  borderRadius: "40px",
  border: "none",
  textTransform: "none",
  fontSize: "20px",
  lineHeight: "20px",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#FFFFFF",
  },
}));

export const PreviewModeSwiter = () => {
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  const handleModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: PreviewMode | null
  ) => {
    if (newMode !== null) {
      dispatch(setPreviewMode(newMode));
    }
  };

  return (
    <div style={{ fontFamily: langTheme.mainFont() }}>
      <Container>
        <ToggleButtonGroup
          value={previewMode}
          exclusive
          onChange={handleModeChange}
          sx={{
            height: "60px",
            width: "412px",
            display: "flex",
            position: "relative",
            borderRadius: "40px",
            overflow: "hidden",
            marginBottom: PreviewMode.MOBILE === previewMode ? "50px" : 0,
            "& .MuiToggleButtonGroup-lastButton, & .MuiToggleButtonGroup-middleButton":
              {
                marginLeft: 0,
              },
          }}
        >
          <Slider
            style={{
              transform:
                previewMode === PreviewMode.DESKTOP
                  ? "translateX(0)"
                  : "translateX(100%)",
            }}
          />
          <StyledToggleButton
            value={PreviewMode.DESKTOP}
            sx={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            Desktop
          </StyledToggleButton>
          <StyledToggleButton
            value={PreviewMode.MOBILE}
            sx={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            Mobile
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Container>
    </div>
  );
};
