import { useTranslation } from "react-i18next";
import { PAGE_SIZE } from "../../helpers/config";
import {
  DEFAULT_SORT_FIELD,
  getTranslation,
  getTranslationOrI18N,
  IFilter,
  IFilterParams,
  IGetPropertiesParams,
  IPriceRange,
  ISortParams,
} from "../../pages/Portfolio";
import { apiSlice } from "../api/apiSlice";
import i18n from "../../i18n";
import { Property } from "../../helpers/convert";

const SORT_PARAMS_MAP: { [key: string]: string } = {
  created_at: "created_at",
  pricing: "[pricing][basePrice]",
  details: "[details][totalArea]",
};

function buildSortParams(params: URLSearchParams, sortParams: ISortParams) {
  let field = SORT_PARAMS_MAP[sortParams.field];
  if (!field) {
    field = SORT_PARAMS_MAP[DEFAULT_SORT_FIELD];
  }
  params.set("sort", `${field}:${sortParams.direction}`);
}

function buildPaginationParams(params: URLSearchParams, page: number) {
  params.set("pagination[page]", page.toString());
  params.set("pagination[pageSize]", PAGE_SIZE);
}

// function buildSearchParams(params: URLSearchParams, search: string) {
//   if (search) {
//     params.append("filters[name][$contains]", search);
//   }
// }

function buildSearchParams(params: URLSearchParams, search: string) {
  const splitedSearch = search.split(" ");

  if (search) {
    for (let i = 0; i < splitedSearch.length; i++) {
      const term = splitedSearch[i];
      const baseIndex = i * 3;

      params.append(`filters[$or][${baseIndex}][name][$contains]`, term);
      params.append(
        `filters[$or][${baseIndex + 1}][description][$contains]`,
        term
      );
      params.append(
        `filters[$or][${baseIndex + 2}][location][city][$contains]`,
        term
      );
    }
  }
}

function buildPricingParams(params: URLSearchParams, pricing: IPriceRange) {
  params.set("filters[pricing][basePrice][$gte]", pricing.start.toString());
  params.set("filters[pricing][basePrice][$lte]", pricing.end.toString());
}

function buildFilterList(
  params: URLSearchParams,
  filters: IFilter[],
  andIndex: number,
  fieldName: string,
  currentLanguage: string
) {
  filters.forEach((filter, index) => {
    params.append(
      `filters[$and][${andIndex}][$or][${index}]${fieldName}`,
      getTranslationOrI18N(filter.name, filter.translationKey, currentLanguage)
    );
  });
}

function buildFilterListPopertyStatus(
  params: URLSearchParams,
  filters: IFilter[],
  andIndex: number,
  fieldName: string,
  currentLanguage: string
) {
  filters.forEach((filter, index) => {
    params.append(
      `filters[$and][${andIndex}][$or][${index}]${fieldName}`,
      filter.translationKey
    );
  });
}

function buildFilterParams(
  params: URLSearchParams,
  filters: IFilterParams,
  lang: string
) {
  buildPricingParams(params, filters.priceRange);

  let andIndex = 0;
  if (filters.locations) {
    buildFilterList(
      params,
      filters.locations,
      andIndex,
      "[location][city]",
      lang
    );
    andIndex++;
  }

  if (filters.statuses) {
    buildFilterListPopertyStatus(
      params,
      filters.statuses,
      andIndex,
      "[propertyStatus]",
      lang
    );
    andIndex++;
  }

  if (filters.types) {
    buildFilterListPopertyStatus(
      params,
      filters.types,
      andIndex,
      "[propertyType]",
      lang
    );
    andIndex++;
  }
}

function CreateGetPropertiesWithParamsURLQuery(
  params: IGetPropertiesParamsWithLang
): string {
  const urlParams = new URLSearchParams();
  urlParams.append("populate", "mediaContent");
  urlParams.append("locale", params.lang);

  buildPaginationParams(urlParams, params.params.page);
  buildSortParams(urlParams, params.params.sort);
  if (params.params.search) {
    buildSearchParams(urlParams, params.params.search);
  } else {
    buildFilterParams(urlParams, params.params.filters, params.lang);
  }

  return `/properties?${urlParams.toString()}`;
}

export interface IGetPropertiesParamsWithLang {
  params: IGetPropertiesParams;
  lang: string;
}

export const propertyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: ({ locale }: { locale: string }) => {
        return `/properties?populate=mediaContent&locale=${locale}`;
      },
    }),

    getHomepageProperties: builder.query({
      query: ({ locale }: { locale: string }) => {
        return `/properties/homepage?locale=${locale}`;
      },
    }),

    getPropertiesWithParams: builder.query({
      query: (params: IGetPropertiesParamsWithLang) => {
        return CreateGetPropertiesWithParamsURLQuery(params);
      },
    }),

    getProperty: builder.query<any, string>({
      query: (documentId: string) => {
        return `/properties/${documentId}?populate=*`;
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useGetPropertiesWithParamsQuery,
  useGetPropertyQuery,
  useLazyGetPropertyQuery,
  useGetHomepagePropertiesQuery,
} = propertyApiSlice;
