import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import storageSession from "redux-persist/lib/storage/session";
import { apiSlice } from "./api/apiSlice";
import sideNav from "./features/common";
import portfolio from "./features/portfolioSlice";
import campaignBuilder from "./features/campaignBuilderSlice";
import campaignBuilderSetup from "./features/campaignBuilderSetup";
import auth from "./features/authSlice";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  sideNav,
  portfolio,
  campaignBuilder,
  auth,
  campaignBuilderSetup,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["campaignBuilder"],
  blacklist: ["portfolio", "sideNav", "campaignBuilderSetup"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
