import React, { useEffect, useMemo, useState } from "react";
import { Header } from "../components/_common/Header";
import { Navbar } from "../components/_common/Navbar";
import { Theme, useMediaQuery } from "@mui/material";
import { PortfolioHeroImg } from "../components/portfolio/PortfolioHeroImg";
import { useTranslation } from "react-i18next";
import { PropertiesContainer } from "../components/portfolio/PropertiesContainer";
import { convertToProperties, Property } from "../helpers/convert";
import {
  IGetPropertiesParamsWithLang,
  useGetPropertiesWithParamsQuery,
} from "../store/api/propertyApiSlice";
import { PREFERED_PROPERTY_NUM } from "./Home";
import { getRandomProperty } from "../helpers/fakeProperties";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { Footer } from "../components/_common/Footer";
import { FooterSocialMediaLine } from "../components/_common/FooterSocialMediaLine";
import { useAppDispatch } from "../store/store";
import { FixedMenu } from "../components/portfolio/FixedMenu";
import { MapSideBar } from "../components/portfolio/map/MapSideBar";
import {
  activePropertyFiltersSelector,
  fetchedPropertiesSelector,
  propertiesPaginationParamnsSelector,
  setFetchedProperties,
  setPropertyError,
  setPropertiesPaginationParams,
  setActivePropertiesFilter,
  IPropertiesPaginationParams,
} from "../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  convertLocations,
  getAddParamByNameSortParam,
  getPriceRangeBounds,
  mapIdsToValues,
} from "../helpers/dataConverts";
import {
  locationFilters,
  typeFilters,
  statusFilters,
  locationFilterMap,
  priceRanges,
  typeFilterMap,
  statusFilterMap,
  getFiltersByIds,
} from "../constants/propertyData";
import { IPropertySearchParamObj } from "../types/properties";
import { randomMediaGenerator } from "../helpers/fakeMedia";
import { types } from "util";
import { skipToken } from "@reduxjs/toolkit/query";
import { PortfolioDesktopFilterContainer } from "../components/portfolio/PortfolioDesktopFilterContainer";
import { FilterSideBar } from "../components/home/FilterSideBar";
import { PortfolioMobileFilterContainer } from "../components/portfolio/PortfolioMobileFilterContainer";
import { AuthPopup } from "../components/_common/AuthPopup";
import { REACT_APP_MAP_ACCESS_TOKEN } from "../helpers/config";
import i18n from "../i18n";

export const DEFAULT_PAGE = 1;

export const DEFAULT_SORT_FIELD = "created_at";
export const DEFAULT_SORT_DIRECTION = "asc";
export const DEFAULT_SORT = {
  direction: DEFAULT_SORT_DIRECTION,
  field: DEFAULT_SORT_FIELD,
};

export const DEFAULT_PRICE_RANGE_START = 0;
export const DEFAULT_PRICE_RANGE_END = 100_000_000;
export const DEFAULT_PRICE_RANGE: IPriceRange = {
  start: DEFAULT_PRICE_RANGE_START,
  end: DEFAULT_PRICE_RANGE_END,
};

export const DEFAULT_FILTER = {
  locations: [],
  statuses: [],
  types: [],
  priceRange: DEFAULT_PRICE_RANGE,
};

const ALL_FILTERS = {
  locations: locationFilters,
  statuses: statusFilters,
  types: typeFilters,
  priceRange: DEFAULT_PRICE_RANGE,
};

const QUERY_PARAM_PAGE = "page";
const QUERY_PARAM_SEARCH = "search";
const QUERY_PARAM_SORT_FIELD = "sortField";
const QUERY_PARAM_SORT_DIRECTION = "sortDir";
const QUERY_PARAM_FILTER_LOCATION = "location";
const QUERY_PARAM_FILTER_PROPERTY_TYPES = "propertyTypes";
const QUERY_PARAM_FILTER_PRICE_RANGE_START = "rangeStart";
const QUERY_PARAM_FILTER_PRICE_RANGE_END = "rangeEnd";
const QUERY_PARAM_FILTER_STATUSES = "statuses";

export interface ISortParams {
  field: string;
  direction: string;
}

export interface IPriceRange {
  start: number;
  end: number;
}

interface ITranslation {
  en: string;
  he: string;
}

export function getTranslation(t: ITranslation, key: string): string {
  switch (key) {
    case "he":
      return t.he;
    case "en":
      return t.en;
    default:
      return t.en;
  }
}

export function getTranslationOrI18N(
  t: ITranslation,
  i18nKey: string,
  langKey: string
): string {
  if (i18nKey) {
    return i18n.t(i18nKey, { ns: "filters" });
  }

  return getTranslation(t, langKey);
}

export interface IFilter {
  key: string;
  name: ITranslation;
  translationKey: string;
}

export interface IFilterParams {
  locations: IFilter[];
  statuses: IFilter[];
  types: IFilter[];
  priceRange: IPriceRange;
}

export interface IGetPropertiesParams {
  page: number;
  sort: ISortParams;
  filters: IFilterParams;
  search: string;
}

export interface IPropertiesPaginationMeta {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

function convertToPaginationMeta(
  pagination: IPropertiesPaginationMeta | undefined
): IPropertiesPaginationMeta | null {
  if (!pagination) {
    return null;
  }
  return pagination as IPropertiesPaginationMeta;
}

const PARAM_ENCODER_SEP = ",";

function encodeParamArray(keys: string[]) {
  return keys.join(PARAM_ENCODER_SEP);
}

function decodeParamArray(param: string | null): string[] {
  if (param === null) {
    return [];
  }

  return param.split(PARAM_ENCODER_SEP);
}

async function fetchLocation(placeId: string): Promise<IFilter | null> {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
    placeId
  )}.json?country=il&limit=5&types=place&language=en%2Che&access_token=${REACT_APP_MAP_ACCESS_TOKEN}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // console.log("Fetched data:", data);
    // TODO
    return convertLocation(data.features[0]);
  } catch {
    return null;
  }
}

export const convertLocation = (locationObj: any): IFilter => {
  return {
    key: locationObj.id,
    name: {
      en: locationObj.text_en,
      he: locationObj.text_he,
    },
    translationKey: "",
  };
};

function parseSearchParams(
  searchParams: URLSearchParams
): IGetPropertiesParams {
  const pageParam = searchParams.get(QUERY_PARAM_PAGE);
  const searchParam = searchParams.get(QUERY_PARAM_SEARCH);
  const sortFieldParam = searchParams.get(QUERY_PARAM_SORT_FIELD);
  const sortDirectionParam = searchParams.get(QUERY_PARAM_SORT_DIRECTION);
  const locationParams = searchParams.get(QUERY_PARAM_FILTER_LOCATION);
  const propertyTypesParams = searchParams.get(
    QUERY_PARAM_FILTER_PROPERTY_TYPES
  );
  const priceRangeStartParam = searchParams.get(
    QUERY_PARAM_FILTER_PRICE_RANGE_START
  );
  const priceRangeEndParam = searchParams.get(
    QUERY_PARAM_FILTER_PRICE_RANGE_END
  );
  const statusesParams = searchParams.get(QUERY_PARAM_FILTER_STATUSES);

  let filters: IFilterParams = {
    //new format
    // locations: locationParams ? [{key: locationParams, name: {en: "", he: ""}, translationKey: ""}] : [],
    //new format

    locations: getFiltersByIds(
      locationFilterMap,
      decodeParamArray(locationParams)
    ),
    statuses: getFiltersByIds(
      statusFilterMap,
      decodeParamArray(statusesParams)
    ),
    types: getFiltersByIds(
      typeFilterMap,
      decodeParamArray(propertyTypesParams)
    ),
    priceRange: {
      start: Number(priceRangeStartParam) || DEFAULT_PRICE_RANGE_START,
      end: Number(priceRangeEndParam) || DEFAULT_PRICE_RANGE_END,
    },
  };

  return {
    page: Number(pageParam) || DEFAULT_PAGE,
    sort: {
      direction: sortDirectionParam || DEFAULT_SORT_DIRECTION,
      field: sortFieldParam || DEFAULT_SORT_FIELD,
    },
    filters: filters,
    search: searchParam || "",
  };
}

export function buildSearchParamsFromGetPropertiesParams(
  params: IGetPropertiesParams
): URLSearchParams {
  const queryParams = new URLSearchParams();

  queryParams.set(QUERY_PARAM_PAGE, params.page.toString());
  queryParams.set(QUERY_PARAM_SORT_DIRECTION, params.sort.direction);
  queryParams.set(QUERY_PARAM_SORT_FIELD, params.sort.field);

  if (params.search) {
    queryParams.set(QUERY_PARAM_SEARCH, params.search);
  } else {
    queryParams.delete(QUERY_PARAM_SEARCH);
  }

  let l = encodeParamArray(params.filters.locations.map((item) => item.key));
  if (l) {
    queryParams.set(QUERY_PARAM_FILTER_LOCATION, l);
  } else {
    queryParams.delete(QUERY_PARAM_FILTER_LOCATION);
  }

  let s = encodeParamArray(params.filters.statuses.map((item) => item.key));
  if (s) {
    queryParams.set(QUERY_PARAM_FILTER_STATUSES, s);
  } else {
    queryParams.delete(QUERY_PARAM_FILTER_STATUSES);
  }

  let t = encodeParamArray(params.filters.types.map((item) => item.key));
  if (t) {
    queryParams.set(QUERY_PARAM_FILTER_PROPERTY_TYPES, t);
  } else {
    queryParams.delete(QUERY_PARAM_FILTER_PROPERTY_TYPES);
  }

  queryParams.set(
    QUERY_PARAM_FILTER_PRICE_RANGE_START,
    params.filters.priceRange.start.toString()
  );
  queryParams.set(
    QUERY_PARAM_FILTER_PRICE_RANGE_END,
    params.filters.priceRange.end.toString()
  );

  return queryParams;
}

export const Portfolio = () => {
  const { i18n } = useTranslation();
  const [_, setSearchParams] = useSearchParams();
  const isFilterDesktop = useMediaQuery("(max-width: 1090px)");

  // Query state
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<ISortParams>(DEFAULT_SORT);
  const [filters, setFilters] = useState<IFilterParams>(DEFAULT_FILTER);
  const [search, setSearch] = useState<string>("");
  // Workaround for not setting state into URL before parsing
  const [initComplete, setInitComplete] = useState(false);

  // Sync/persist state back to Search Params
  useEffect(() => {
    if (!initComplete) return;

    setSearchParams(
      buildSearchParamsFromGetPropertiesParams({
        filters,
        page,
        search,
        sort,
      })
    );
  }, [initComplete, filters, page, search, sort]);

  const enrichParams = async (
    params: IGetPropertiesParams
  ): Promise<IGetPropertiesParams> => {
    let location: IFilter | null = null;
    if (params.filters.locations.length > 0) {
      location = await fetchLocation(params.filters.locations[0].key);
    }

    if (location == null) {
      params.filters.locations = [];
    } else {
      params.filters.locations = [location];
    }

    return params;
  };

  // Init page state from Search Params
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = parseSearchParams(searchParams);

    //new format
    // enrichParams(params).then((params) => {
    //   setPage(params.page);
    //   setSearch(params.search);
    //   setFilters(params.filters);
    //   setSort(params.sort);
    //   setInitComplete(true);
    // });
    //new format

    setPage(params.page);
    setSearch(params.search);
    setFilters(params.filters);
    setSort(params.sort);
    setInitComplete(true);
  }, []);

  // Query data
  const getPropertiesParams =
    useMemo<IGetPropertiesParamsWithLang | null>(() => {
      return {
        params: {
          page: page,
          sort: sort,
          filters: filters,
          search: search,
        },
        lang: i18n.language,
      };
    }, [page, sort, filters, search, i18n.language]);

  const { data, isLoading } = useGetPropertiesWithParamsQuery(
    getPropertiesParams ?? skipToken
  );

  const fetchedProperties = useMemo(() => {
    return convertToProperties(randomMediaGenerator, data?.data);
  }, [data]);

  const paginationMeta = useMemo(() => {
    return convertToPaginationMeta(data?.meta?.pagination);
  }, [data]);

  const contentRef = React.useRef<any>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [openSearchPanel, setOpenSearchPanel] = React.useState(false);

  //Height calculation for sticky header
  useEffect(() => {
    const calculateHeight = () => {
      if (contentRef.current) {
        setHeaderHeight(contentRef.current.offsetHeight);
      }
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, [openSearchPanel]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <>
        <div className="portfolio-header-sticky-wrapper" ref={contentRef}>
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <Header>
              <Navbar />
            </Header>
          </div>

          {isFilterDesktop ? (
            <PortfolioMobileFilterContainer
              filters={filters}
              onChangeFilters={setFilters}
              search={search}
              onChangeSearch={setSearch}
            />
          ) : (
            <PortfolioDesktopFilterContainer
              filters={filters}
              onChangeFilters={setFilters}
              search={search}
              onChangeSearch={setSearch}
              openSearchPanel={openSearchPanel}
              setOpenSearchPanel={setOpenSearchPanel}
            />
          )}
        </div>

        {/* OLD FILTER */}
        {/* <PortfolioHeroImg /> */}
        {isFilterDesktop && <FixedMenu />}

        <FilterSideBar
          filters={filters}
          onChangeFilters={setFilters}
          search={search}
          onChangeSearch={setSearch}
        />

        <PropertiesContainer
          allProperties={fetchedProperties}
          onPageChange={setPage}
          initSort={sort}
          onSortChange={setSort}
          paginationMeta={paginationMeta}
          isLoading={isLoading}
          headerHeight={headerHeight}
          search={search}
        />

        <AuthPopup />
        <GetInTouchBlock />
        <Footer />
        <FooterSocialMediaLine />
      </>
    </div>
  );
};
