import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import { ITestimonial } from "../../../hooks/useTestimonials";
import Quotes from "../../../assets/quotes.png";

const SliderControl = ({
  currentSlide,
  testimonials,
  onSlideChange,
}: {
  currentSlide: number;
  testimonials: ITestimonial[];
  onSlideChange: (index: number) => void;
}) => {
  return (
    <div style={{ display: "flex", gap: "8px" }}>
      {testimonials.map((slide: ITestimonial, index: number) => (
        <div
          key={index}
          onClick={() => onSlideChange(index)}
          style={{
            width: currentSlide === index ? "68px" : "22px",
            height: "5px",
            borderRadius: "5px",
            backgroundColor:
              currentSlide === index
                ? "rgba(3, 74, 115, 1)"
                : "rgba(3, 74, 115, 0.5)",
            cursor: "pointer",
            transition: "width 0.3s ease",
          }}
        ></div>
      ))}
    </div>
  );
};

export const TestimonialSlide = ({
  testimonial,
  testimonials,
  currSlide,
  onSlideChange,
}: {
  testimonial: ITestimonial;
  testimonials: ITestimonial[];
  currSlide: number;
  onSlideChange: (index: number) => void;
}) => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const downXl = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  if (!testimonial) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        paddingBottom: downSm
          ? "0px"
          : downMd
          ? "50px"
          : downLg
          ? "20px"
          : downXl
          ? "20px"
          : "70px",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: downMd ? "95%" : downLg ? "80%" : downXl ? "60%" : "50%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          height: upLg ? "100%" : "",
          borderTopRightRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <img
          src={testimonial.propertyPhoto?.url}
          style={{
            width: "100%",
            height: "100%",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
          alt="property"
        />
      </div>

      <div
        style={{
          zIndex: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          paddingBottom: "20px",
        }}
      >
        <div
          className="xl-width"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            paddingRight: "20px",
            paddingLeft: downLg ? "20px" : "0",
            paddingTop: "100px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              padding: downMd ? "25px 20px " : "35px",
              borderRadius: "20px",
              maxWidth: "720px",
              width: "100%",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.09)",
            }}
          >
            <div
              style={{
                position: "relative",
                zIndex: "1",
                direction: i18n.dir(),
              }}
            >
              <div
                style={{
                  paddingRight: isRTL ? 0 : downLg ? "20px" : "170px",
                  paddingLeft: !isRTL ? 0 : downLg ? "20px" : "200px",
                  zIndex: 2,
                  position: "relative",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: langTheme.mainFont(),
                    color: "rgba(79, 129, 157, 1)",
                    mb: downMd ? "5px" : "9px",
                    fontWeight: 600,
                  }}
                >
                  {t("testimonials")}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                    textTransform: "uppercase",
                    mb: "15px",
                  }}
                >
                  {t("our_honored_customers")}
                </Typography>

                <Typography
                  variant="h4"
                  sx={{
                    maxWidth: "550px",
                    fontFamily: langTheme.mainFont(),
                    fontWeight: 400,
                    color: "rgba(97, 97, 97, 1)",
                    height: downSm ? "155px" : "88px",
                    letterSpacing: "0.64px",
                  }}
                >
                  {isRTL
                    ? testimonial.localizations[0].testimonial
                    : testimonial.testimonial}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: downMd ? "flex-start" : "center",
                  justifyContent: "space-between",
                  marginTop: downMd ? "25px" : "38px",
                  flexDirection: downMd ? "column" : "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <img
                      src={testimonial.photo?.formats.thumbnail.url}
                      alt="person"
                      style={{
                        borderRadius: "50%",
                        width: "67px",
                        height: "67px",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontFamily: langTheme.mainFont(),
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {isRTL
                        ? testimonial.localizations[0].name
                        : testimonial.name}
                    </div>

                    <div
                      style={{
                        fontSize: "14px",
                        fontFamily: langTheme.mainFont(),
                        fontWeight: 400,
                      }}
                    >
                      {isRTL
                        ? testimonial.localizations[0].role
                        : testimonial.role}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: downMd ? "center" : "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      marginTop: downMd ? "32px" : 0,
                      width: "200px",
                    }}
                  >
                    <SliderControl
                      testimonials={testimonials}
                      currentSlide={currSlide}
                      onSlideChange={onSlideChange}
                    />
                  </div>
                </div>
              </div>
              <img
                src={Quotes}
                className={
                  isRTL
                    ? "testimonials-quotes-left"
                    : "testimonials-quotes-right"
                }
                style={{
                  position: "absolute",
                  top: "0",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
