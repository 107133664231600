import { InputAdornment, TextField, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { textFieldStyles } from "./MainInformationStep";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import React, { useState } from "react";
import { ColorDropDown, colors } from "./ColorDropDown";
import { IconSelect } from "./IconSelect";
import { PreviouslyAdded } from "./PreviouslyAdded";
import {
  LabelIconMap,
  labelOptions,
} from "../../constants/campaignBuilderData";

export const MAX_LABEL_LENGTH = 20;

export const AddNewLabel = ({
  setCurrentStep,
  labelOptionsState,
  setLabelOptionsState,
  addedLabelOptions,
  setAddedLabelOptions,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  labelOptionsState: any;
  setLabelOptionsState: any;
  addedLabelOptions: any;
  setAddedLabelOptions: any;
}) => {
  const langTheme = useLanguageTheme();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [labelName, setLabelName] = React.useState("");
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [selectedIconKey, setSelectedIconKey] =
    React.useState<keyof typeof LabelIconMap>("hot_price");

  const handleChangeLabelName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setLabelName(value);
  };

  const handleAddOption = (event: any) => {
    const newOption = {
      id: crypto.randomUUID(),
      labelName: labelName,
      icon_key: selectedIconKey,
      backgroundColor: selectedColor.value,
    };

    setAddedLabelOptions([...addedLabelOptions, newOption]);
    setLabelOptionsState([...labelOptionsState, newOption]);
  };

  const handleDeleteOption = (id: string) => {
    const updatedAddedOptions = addedLabelOptions.filter(
      (option: any) => option.id !== id
    );
    setAddedLabelOptions(updatedAddedOptions);
    setLabelOptionsState(updatedAddedOptions);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="add_a_new_label"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.labels}
          />
        }
        rightBtn={<div className="campaign-builder-sidenav-empty-btn"></div>}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("message")}
              </div>

              <TextField
                fullWidth
                placeholder={t("message_placeholder")}
                value={labelName}
                onChange={handleChangeLabelName}
                sx={textFieldStyles(langTheme.mainFont())}
                autoComplete="off"
                inputProps={{
                  maxLength: MAX_LABEL_LENGTH,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        height: "100%",
                        ml: 0,
                        maxHeight: "none",
                        marginRight: isRTL ? "-14px" : "0",
                        pl: isRTL ? "20px" : "0px",
                        pr: isRTL ? "-14px" : "-14px",
                        color: "rgba(0, 0, 0, 1)",
                      }}
                      position="start"
                    >
                      <div
                        className="campaign-builder-sidenav-number-of-char"
                        style={{
                          fontFamily: langTheme.mainFont(),
                        }}
                      >
                        {labelName.length}/{MAX_LABEL_LENGTH}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("color")}
              </div>

              <ColorDropDown
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("choose_icon")}
              </div>

              <IconSelect
                selectedIcon={selectedIconKey}
                setSelectedIcon={setSelectedIconKey}
                options={labelOptions}
                iconMap={LabelIconMap}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-line"></div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div
                className="campaign-builder-sidenav-field-name"
                style={{
                  fontSize: "16px",
                }}
              >
                {t("previously_added")}
              </div>
            </div>
          </div>

          {addedLabelOptions.map((option: any, index: any) => (
            <div key={index}>
              <PreviouslyAdded
                option={option}
                handleDelete={handleDeleteOption}
                iconMap={LabelIconMap}
              />
            </div>
          ))}
        </div>

        <div
          className="campaign-builder-sidenav-add-label-btn-big"
          onClick={handleAddOption}
        >
          {t("add_this_label")}
        </div>
      </div>
    </div>
  );
};
