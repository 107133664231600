import { Checkbox } from "@mui/material";
import React from "react";

export const CustomBlueCheckbox = ({
  checked,
  setChecked,
}: {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={(e) => setChecked(e.target.checked)}
      sx={{
        "& .MuiSvgIcon-root": {
          fill: "rgba(43, 54, 116, 1)",
        },

        "&.Mui-checked .MuiSvgIcon-root": {
          fill: "rgba(3, 74, 115, 1)",
        },
        "&.Mui-disabled .MuiSvgIcon-root": {
          fill: "rgba(200, 200, 200, 1)",
        },
        "&.MuiButtonBase-root": {
          padding: 0,
        },
      }}
    />
  );
};
