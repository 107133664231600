import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    const user = localStorage.getItem('user') || sessionStorage.getItem('user');
    if (jwt && user) {
      const decoded = jwtDecode(jwt);
      const expirationTime = decoded.exp! * 1000;
      setIsAuthenticated(Date.now() <= expirationTime);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return isAuthenticated;
}
