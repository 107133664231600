import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BlueCross from "../../assets/icons/cross_blue.svg";
import { renderValueWithTrancateLength } from "../../helpers/dataConverts";
import { FilterMap } from "../../constants/propertyData";
import { IFilter } from "../../pages/Portfolio";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const PortfilioMobileFilterItem = ({
  filterMap = {},
  selectedFilters = [],
  onChange,
  truncateLength,
}: {
  filterMap: FilterMap;
  selectedFilters: IFilter[];
  onChange: (filters: IFilter[]) => void;
  truncateLength: number;
}) => {
  const { t, i18n } = useTranslation(["portfolio"]);
  const isRTL = i18n.dir() === "rtl";
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();

  if (selectedFilters.length === 0) {
    return null;
  }

  const handleClearFilter = () => {
    onChange([]);
  };

  return (
    <div className="portfolio-mobile-filter-item">
      <div
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 400 : 500,
        }}
      >
        {renderValueWithTrancateLength(selectedFilters, truncateLength)}
      </div>
      <div
        className="portfolio-mobile-filter-item-cross-container"
        onClick={() => handleClearFilter()}
      >
        <img src={BlueCross} />
      </div>
    </div>
  );
};
