import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Currency,
  Language,
  PropertyStatus,
  PropertyType,
  TimePeriod,
} from "../../components/campaignBuilder/CampaignBuilderSideNav";
import { DAYS } from "../../components/campaignBuilder/CampaignBuilderDurationSelect";
import { IKeyFeature } from "../../components/campaignBuilder/KeyFeature";
import { User } from "../../components/campaignBuilder/ContactInformation";
import {
  highlightOptions,
  keyFeatureOptions,
  labelOptions,
} from "../../constants/campaignBuilderData";
import { IPropertyLocation } from "../../components/campaignBuilder/CampaignBuilderLocationSearch";
import { ExtractedMediaData } from "../../helpers/campaignConvert";

export interface ICampaingBuilderSliceState {
  language: Language;
  currency: Currency;
  countOfTimePeriods: string;
  timePeriod: TimePeriod;
  propertyTitle: string;
  propertyDescription: string;
  propertyStatus: PropertyStatus;
  propertyType: PropertyType;
  propertyPrice: string;
  propertyLocation: IPropertyLocation;
  propertyImgs: any;
  aboutPropertyDesc: string;
  aboutDeveloper: string;
  keyFeaturesBack: IKeyFeature[];
  labelBack: any;
  selectedAgent: User | {};
  hightlightsBack: any;
  selectedUserId: string | number;
  imgFilesSlice: ExtractedMediaData[] | [];
  floorPlanFilesSlice: ExtractedMediaData[] | [];
  brochureFilesSlice: ExtractedMediaData[] | [];
  videoFilesSlice: ExtractedMediaData[] | [];
  agentAvatarSlice: ExtractedMediaData[] | [];
}

const initialState: ICampaingBuilderSliceState = {
  language: Language.ENGLISH,
  currency: Currency.NIS,
  countOfTimePeriods: DAYS[89],
  timePeriod: TimePeriod.DAYS,
  propertyTitle: "",
  propertyDescription: "",
  propertyStatus: PropertyStatus.UNDER_CONSTRUCTION,
  propertyType: PropertyType.PENTHOUSE,
  propertyPrice: "0",
  propertyLocation: {
    city: "",
    address: "",
    coordinates: {
      latitude: null,
      longitude: null,
    },
  },
  propertyImgs: [],
  aboutPropertyDesc: "",
  aboutDeveloper: "",
  keyFeaturesBack: keyFeatureOptions,
  labelBack: labelOptions[0],
  // labelBack: {},
  selectedAgent: {},
  hightlightsBack: highlightOptions,
  selectedUserId: "",
  imgFilesSlice: [],
  floorPlanFilesSlice: [],
  brochureFilesSlice: [],
  videoFilesSlice: [],
  agentAvatarSlice: [],
};

const campaignBuilderSlice = createSlice({
  name: "campaignBuilder",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = action.payload;
    },
    setCountOfTimePeriods: (state, action: PayloadAction<string>) => {
      state.countOfTimePeriods = action.payload;
    },
    setTimePeriod: (state, action: PayloadAction<TimePeriod>) => {
      state.timePeriod = action.payload;
    },
    setPropertyTitle: (state, action: PayloadAction<string>) => {
      state.propertyTitle = action.payload;
    },
    setPropertyDescription: (state, action: PayloadAction<string>) => {
      state.propertyDescription = action.payload;
    },
    setPropertyStatus: (state, action: PayloadAction<PropertyStatus>) => {
      state.propertyStatus = action.payload;
    },
    setPropertyPrice: (state, action: PayloadAction<string>) => {
      state.propertyPrice = action.payload;
    },
    setPropertyLocation: (state, action: PayloadAction<any>) => {
      state.propertyLocation = action.payload;
    },
    resetCampaignBuilder: (state) => {
      return initialState;
    },
    setImgPropertiesUrls: (state, action: PayloadAction<any>) => {
      state.propertyImgs = action.payload;
    },
    setAboutPropertyDesc: (state, action: PayloadAction<string>) => {
      state.aboutPropertyDesc = action.payload;
    },
    setAboutDeveloper: (state, action: PayloadAction<string>) => {
      state.aboutDeveloper = action.payload;
    },
    setKeyFeatureBack: (state, action: PayloadAction<IKeyFeature[]>) => {
      state.keyFeaturesBack = action.payload;
    },
    setLabelBack: (state, action: PayloadAction<any[]>) => {
      state.labelBack = action.payload;
    },
    setSelectedAgent: (state, action: PayloadAction<any>) => {
      state.selectedAgent = action.payload;
    },
    setHightlightsBack: (state, action: PayloadAction<any[]>) => {
      state.hightlightsBack = action.payload;
    },
    setSelectedUserId: (state, action: PayloadAction<any>) => {
      state.selectedUserId = action.payload;
    },
    setPropertyType: (state, action: PayloadAction<PropertyType>) => {
      state.propertyType = action.payload;
    },
    setImgFilesSlice: (state, action: PayloadAction<any>) => {
      const existingIds = new Set(state.imgFilesSlice.map((item) => item.id));
      const newItems = action.payload.filter(
        (item: any) => !existingIds.has(item.id)
      );

      state.imgFilesSlice = [...state.imgFilesSlice, ...newItems];
    },
    removeImgFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.imgFilesSlice = state.imgFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setFloorPlanSlice: (state, action: PayloadAction<any>) => {
      state.floorPlanFilesSlice = action.payload;
    },
    removeFloorPlanFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.floorPlanFilesSlice = state.floorPlanFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setBrochureFileSlice: (state, action: PayloadAction<any>) => {
      state.brochureFilesSlice = action.payload;
    },
    removeBrochureFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.brochureFilesSlice = state.brochureFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setVideoFileSlice: (state, action: PayloadAction<any>) => {
      state.videoFilesSlice = action.payload;
    },
    removeVideoFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.videoFilesSlice = state.videoFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setAvatarFileSlice: (state, action: PayloadAction<any>) => {
      state.agentAvatarSlice = action.payload;
    },
    removeAgentFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.agentAvatarSlice = state.agentAvatarSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
  },
});

export const {
  setLanguage,
  setCurrency,
  setCountOfTimePeriods,
  setTimePeriod,
  setPropertyTitle,
  setPropertyDescription,
  setPropertyStatus,
  setPropertyPrice,
  setPropertyLocation,
  resetCampaignBuilder,
  setImgPropertiesUrls,
  setAboutPropertyDesc,
  setAboutDeveloper,
  setKeyFeatureBack,
  setLabelBack,
  setSelectedAgent,
  setHightlightsBack,
  setSelectedUserId,
  setPropertyType,
  setImgFilesSlice,
  removeImgFileById,
  setFloorPlanSlice,
  removeFloorPlanFileById,
  setBrochureFileSlice,
  removeBrochureFileById,
  setVideoFileSlice,
  removeVideoFileById,
  setAvatarFileSlice,
  removeAgentFileById,
} = campaignBuilderSlice.actions;

export default campaignBuilderSlice.reducer;
