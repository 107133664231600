import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_HOST } from '../helpers/api';
import { setCredentials } from '../store/features/authSlice';
import { useAppDispatch } from '../store/store';

export const RedirectWithGoogle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const accessToken = params.get('access_token');

      if (accessToken) {
        try {
          const response = await axios.get(
            `${BACKEND_HOST}/auth/google/callback?access_token=${accessToken}`,
          );

          const data = response.data;

          if (data.jwt && data.user) {
            localStorage.setItem('jwt', data.jwt);
            localStorage.setItem('user', JSON.stringify(data.user));
            dispatch(
              setCredentials({
                jwt: data.jwt,
                user: data.user,
              }),
            );

            const userDataFromLS = JSON.parse(
              localStorage.getItem('user') || '{}',
            );

            if (
              userDataFromLS.userType &&
              userDataFromLS.fullName &&
              userDataFromLS.phoneNumber
            ) {
              navigate('/home');
            } else {
              navigate('/connect/google/finish');
            }
          } else {
            navigate('/signup');
          }
        } catch (err) {
          console.error('Error during Google OAuth callback:', err);
          navigate('/signup');
        }
      } else {
        navigate('/signup');
      }
    };

    fetchData();
  }, [location, navigate]);

  return <div>Redirecting...</div>;
};
