import { useState, useEffect } from "react";
import { useGetSocialProofQuery } from "../store/api/socialProofApiSlice";

interface ISocialProof {
  activeInvestors: number;
  successfulProjects: number;
  salesAmount: number;
}

const formatNumber = (num: number): string => {
  if (num >= 1000000) {
    return Math.round(num / 1000000) + "M";
  } else if (num >= 1000) {
    return Math.round(num / 1000) + "K";
  } else {
    return num.toString();
  }
};

export const useFormattedSocialProof = () => {
  const [socialProofData, setSocialProofData] = useState<ISocialProof>({
    activeInvestors: 0,
    successfulProjects: 0,
    salesAmount: 0,
  });

  const { data: fetchedSocialProof } = useGetSocialProofQuery({});

  useEffect(() => {
    if (fetchedSocialProof?.data) {
      setSocialProofData(fetchedSocialProof.data);
    }
  }, [fetchedSocialProof]);

  const formattedSalesAmount = formatNumber(socialProofData.salesAmount);

  return {
    ...socialProofData,
    formattedSalesAmount,
  };
};
