import { Box, Theme, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { LOCAL_TOOLS_ON } from "../../helpers/config";

export const Navbar = () => {
  const { t } = useTranslation(["navbar"]);
  const { i18n } = useTranslation();
  const langTheme = useLanguageTheme();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const navItems = [
    { title: t("home"), path: "/home" },
    { title: t("portfolio"), path: "/portfolio" },
    { title: t("sell_with_us"), path: "/sell-together" },
    { title: t("learning_center"), path: "/learn" },
  ];

  if (LOCAL_TOOLS_ON) {
    navItems.push({
      title: "Campaign Page Builder",
      path: "/campain-page-builder",
    });
  }
  return (
    <div
      style={{
        gap: downLg ? "20px" : "32px",
        display: "flex",
        direction: i18n.dir(),
      }}
    >
      {navItems.map((item, index) => (
        <Box key={index} component="span">
          <NavLink
            to={item.path}
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#000" : "#00000087",
              fontWeight: isRTL ? 400 : 500,
              fontFamily: langTheme.mainFont(),
              cursor: "pointer",
              fontSize: downLg ? "14px" : "16px",
            })}
          >
            {item.title}
          </NavLink>
        </Box>
      ))}
    </div>
  );
};
