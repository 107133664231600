import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatPrice, getCurrencyIcon } from "../../helpers/dataConverts";
import { PropertyStatusBlock } from "./PropertyStatusBlock";
import Download from "../../assets/campaignBuilder/download.svg";
import { AgentInfoBlock } from "./AgentInfoBlock";
import { InvestmentHighlightsBlock } from "./InvestmentHighlightsBlock";
import { KeyFeturesBlock } from "./KeyFeturesBlock";
import { AboutPropertyBlock } from "./AboutPropertyBlock";
import { LocationBlock } from "./LocationBlock";
import { AboutDeveloperBlock } from "./AboutDeveloperBlock";
import { PreviewMode } from "./PreviewModeSwiter";
import { Theme, useMediaQuery } from "@mui/material";
import { MediaDialog } from "./MediaDialog";
import React from "react";

export const CampaignBuilderMainInfoSection = ({
  newAgentAvatar,
  brochureFiles,
}: {
  newAgentAvatar: any[];
  brochureFiles: any;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { propertyTitle, currency, propertyPrice, propertyLocation } =
    useSelector((state: any) => state.campaignBuilder);

  const [open, setOpen] = React.useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpen = (e:any) => {
    e.stopPropagation()
    if (brochureFiles && brochureFiles.length > 0) {
      setOpen(true);
    }
  };

  return (
    <div className="campaign-builder-main-info-wrapper">
      <MediaDialog
        files={brochureFiles}
        open={open}
        setOpen={handleCloseDialog}
        mediaType={"photos"}
      />
      <div
        className="campaign-builder-main-info"
        style={{
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-main-info-header">
          <div
            className="campaign-builder-main-info-title"
            style={{
              fontSize:
                previewMode === PreviewMode.MOBILE || downMd ? "24px" : "22px",
            }}
          >
            {propertyTitle ? propertyTitle : t("title_of_property_plug")}
          </div>
          <div
            className="campaign-builder-main-info-brochure-container"
            onClick={handleOpen}
          >
            <img src={Download} />
            <div>{t("brochure")}</div>
          </div>
        </div>
        <div className="campaign-builder-main-info-adress">
          {propertyLocation?.address ? propertyLocation?.address : t("adress")}
        </div>
        <div className="campaign-builder-main-info-price">
          {getCurrencyIcon(currency)} {formatPrice(propertyPrice)}
        </div>
        <PropertyStatusBlock />

        <div className="campaign-builder-main-info-divider"></div>

        <InvestmentHighlightsBlock />
        <div className="campaign-builder-main-info-divider"></div>

        <AboutPropertyBlock />
        <div className="campaign-builder-main-info-divider"></div>

        <KeyFeturesBlock />
        <div className="campaign-builder-main-info-divider"></div>

        <LocationBlock />
        <div className="campaign-builder-main-info-divider"></div>

        <AboutDeveloperBlock />
      </div>
      {previewMode === PreviewMode.DESKTOP && (
        <AgentInfoBlock newAgentAvatar={newAgentAvatar} />
      )}
    </div>
  );
};
