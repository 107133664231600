import { apiSlice } from "./apiSlice";

export const testimonialsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTestimonials: builder.query({
      query: () => "/testimonials?populate=*",
    }),
  }),
});

export const { useGetTestimonialsQuery } = testimonialsApiSlice;
