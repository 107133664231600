import React from "react";
import BinIcon from "../../assets/campaignBuilder/bin.svg";
import { useTranslation } from "react-i18next";
import { Option } from "../../components/campaignBuilder/LabelSelect";

export const PreviouslyAdded = ({
  option,
  handleDelete,
  iconMap,
  backgroundColor = "#053957",
}: {
  option: Option;
  handleDelete: any;
  iconMap: any;
  backgroundColor?: any;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const IconComponent = iconMap[option.icon_key];

  return (
    <div className="campaign-builder-side-nav-prev-added">
      <div className="campaign-builder-side-nav-prev-added-left">
        <IconComponent
          isSelected={false}
          width={17}
          height={19}
          backgroundColor={
            option.backgroundColor ? option.backgroundColor : backgroundColor
          }
        />
        <div>{t(option.labelName)}</div>
      </div>
      <img
        src={BinIcon}
        className="campaign-builder-side-nav-bin"
        onClick={() => handleDelete(option.id)}
      />
    </div>
  );
};
