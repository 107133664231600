import { Theme, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/icons/search_icon.svg";
import Sparkles from "../../assets/sparkles.png";
import {
  activePropertyFiltersSelector,
  defaultActiveFilters,
  setActivePropertiesFilter,
} from "../../store/features/portfolioSlice";
import { useAppDispatch } from "../../store/store";

import SelectArrow from "../../assets/icons/select_arrow.svg";
import { formatPrice } from "../../helpers/formatter";
import { IPropertyActiveFilters } from "../../types/properties";
import { PriceRangeSlider } from "../_common/PriceRangeSlider";
import { SearchTab } from "../portfolio/PortfolioFilterUpMd";
import { AskAIPopoverBlack } from "./AskAIPopover";
import {
  DEFAULT_FILTER,
  DEFAULT_PRICE_RANGE,
  IFilterParams,
} from "../../pages/Portfolio";
import { CustomFilterSelect, homeStyles } from "../_common/CustomFilterSelect";
import {
  locationFilterMap,
  statusFilterMap,
  typeFilterMap,
} from "../../constants/propertyData";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { CustomLocationInput } from "../_common/CustomLocationInput";

export const HomeFilterUpMd = ({
  filters,
  onChangeFilters,
  search,
  onChangeSearch,
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  search: string;
  onChangeSearch: (search: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const [searchValue, setSearchValue] = React.useState(search);
  const [priceRangeValue, setPriceRangeValue] = React.useState(
    filters.priceRange
  );
  const [locationValues, setLocationValues] = React.useState(filters.locations);
  const [statusValues, setStatusValues] = React.useState(filters.statuses);
  const [typeValues, setTypeValues] = React.useState(filters.types);

  const mainContainerRef = React.useRef<HTMLDivElement | null>(null);

  const handleOpenAskAIPopover = (event: React.MouseEvent<HTMLElement>) => {
    setSearchTab(SearchTab.search);
  };

  React.useEffect(() => {
    dispatch(
      setActivePropertiesFilter({
        // location: [locations[0].id.toString()],
        // propertyTypes: [propertyTypes[0].id.toString()],
        // priceRange: [0, 100_000_000],
        // statuses: [statuses[0].id.toString()],
      })
    );
  }, [currentLanguage]);

  const handleClearFilters = () => {
    setSearchValue("");
    setLocationValues([]);
    setStatusValues([]);
    setTypeValues([]);
    setPriceRangeValue(DEFAULT_PRICE_RANGE);
  };

  const submitFilters = useCallback(() => {
    onChangeFilters({
      locations: locationValues,
      statuses: statusValues,
      types: typeValues,
      priceRange: priceRangeValue,
    });
  }, [locationValues, statusValues, typeValues, priceRangeValue]);

  const [searchTab, setSearchTab] = React.useState(SearchTab.filter);

  const handleOpenFilter = () => {
    setSearchTab(SearchTab.filter);
  };

  const [openPriceRangePanel, setOpenPriceRangePanel] = React.useState(false);
  const panelRef = React.useRef<HTMLDivElement>(null);

  const handleOpenPriceRangePanel = () => {
    setOpenPriceRangePanel(!openPriceRangePanel);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setOpenPriceRangePanel(false);
    }
  };

  useEffect(() => {
    if (openPriceRangePanel) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPriceRangePanel]);

  return (
    <div className="xl-width" style={{ position: "relative" }}>
      <div
        className="overview-filter-container"
        style={{
          right: isRTL ? "auto" : "50px",
          left: !isRTL ? "auto" : "35px",
        }}
      >
        <div
          className="overview-filter-main-filter-container"
          style={{ position: "relative" }}
          ref={mainContainerRef}
        >
          {searchTab === SearchTab.filter && (
            <div
              className="filter-ai-sparkles-container"
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                handleOpenAskAIPopover(e)
              }
            >
              <img
                src={Sparkles}
                alt="sparkles"
                style={{ width: "22px", height: "22px" }}
              />
              <p
                style={{
                  marginRight: isRTL ? "6px" : "0px",
                  marginLeft: isRTL ? "0" : "6px",
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: langTheme.mainFont(),
                }}
              >
                {t("search")}
              </p>
            </div>
          )}

          {searchTab === SearchTab.search && (
            <div
              className="filter-ai-sparkles-container"
              onClick={handleOpenFilter}
            >
              <p
                style={{
                  marginRight: isRTL ? "6px" : "0px",
                  marginLeft: isRTL ? "0" : "6px",
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: langTheme.mainFont(),
                }}
              >
                {t("filters")}
              </p>
            </div>
          )}
        </div>

        <AskAIPopoverBlack
          searchTab={searchTab}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearchChange={onChangeSearch}
        />

        <div
          className="overview-filter-second-filter-container"
          style={{
            paddingRight: isRTL ? "40px" : "14px",
            paddingLeft: isRTL ? "14px" : "40px",
            display: searchTab === SearchTab.filter ? "flex" : "none",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={`filter-location-container ${
                isRTL ? "borderLeft" : "borderRight"
              } `}
              style={{
                borderRight: isRTL ? "none" : "1px solid #FFFFFF85",
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "120px" : "105px",
              }}
            >
              <span>{t("location")}</span>
              <CustomFilterSelect
                filterMap={locationFilterMap}
                selectedFilters={locationValues}
                onChange={setLocationValues}
                truncateLength={downLg ? 10 : 12}
                styles={homeStyles}
              />
            </div>
            {/* //new format  */}
            {/* <CustomLocationInput
              selectedLocation={locationValues}
              onChange={setLocationValues}
            /> */}
            {/* //new format  */}

            <div
              className="filter-property-type-container"
              style={{
                borderRight: "1px solid #FFFFFF85",
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "138px" : "109px",
              }}
            >
              <span>{t("property_type")}</span>
              <CustomFilterSelect
                filterMap={typeFilterMap}
                selectedFilters={typeValues}
                onChange={setTypeValues}
                truncateLength={downLg ? 11 : 14}
                styles={homeStyles}
              />
            </div>

            <div
              className="price-range-panel-wrapper"
              ref={panelRef}
              style={{
                marginRight: "15px",
                width: "120px",
              }}
            >
              <div
                className="price-range-panel-container"
                onClick={handleOpenPriceRangePanel}
                style={{
                  borderRight: "1px solid rgba(176, 155, 155, 0.52)",
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  alignItems: isRTL ? "flex-start" : "baseline",
                  gap: priceRangeValue ? "0" : "14px",
                }}
              >
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.77)",
                    fontSize: "14px",
                    marginRight: "15px",
                  }}
                >
                  {t("price_range")}
                </span>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    color: "rgba(255, 255, 255, 1)",
                    width: "100%",
                    justifyContent: priceRangeValue ? "left" : "center",
                    gap: "3px",
                  }}
                >
                  {priceRangeValue && (
                    <>
                      {isRTL ? "₪" : "$"}
                      <div>{`${formatPrice(
                        priceRangeValue.start
                      )}-${formatPrice(priceRangeValue.end)}`}</div>
                    </>
                  )}

                  <img src={SelectArrow} />
                </div>
              </div>

              <div
                className="portfolio-filter-price-range-container-test"
                style={{
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  backgroundColor: "rgba(0, 0, 0, 0.84)",
                  display: openPriceRangePanel ? "block" : "none",
                }}
              >
                <PriceRangeSlider
                  priceRangeValue={priceRangeValue}
                  setPriceRangeValue={setPriceRangeValue}
                  styles={{ color: "white" }}
                />
              </div>
            </div>

            <div
              className="filter-status-container"
              style={{
                borderRight: isRTL ? "1px solid #FFFFFF85" : "none",
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "100px" : "95px",
              }}
            >
              <span>{t("status")}</span>
              <CustomFilterSelect
                filterMap={statusFilterMap}
                selectedFilters={statusValues}
                truncateLength={downLg ? 10 : 13}
                onChange={setStatusValues}
                styles={homeStyles}
              />
            </div>

            <div
              onClick={handleClearFilters}
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                cursor: "pointer",
                color: "white",
                margin: "0 10px 0 15px",
                textWrap: "nowrap",
              }}
            >
              {t("clean_all")}
            </div>
          </div>

          <div
            className="overview-filter-second-search-btn-container"
            onClick={submitFilters}
          >
            <img src={SearchIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
