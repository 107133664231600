import React from "react";
import { CampaignSetupItem } from "./CamaignSetupItem";
import SettingsIcon from "../../assets/campaignBuilder/settings.svg";
import MainInfoIcon from "../../assets/campaignBuilder/main_info.svg";
import MediaIcon from "../../assets/campaignBuilder/media.svg";
import LabelIcon from "../../assets/campaignBuilder/label.svg";
import InvestmentHighlightsIcon from "../../assets/campaignBuilder/investment_highlights.svg";
import AboutPropertyIcon from "../../assets/campaignBuilder/about_property.svg";
import KeyFeatureIcon from "../../assets/campaignBuilder/key_features.svg";
import AboutDeveloperIcon from "../../assets/campaignBuilder/about_developer.svg";
import ContactIcon from "../../assets/campaignBuilder/contact.svg";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { Theme, useMediaQuery } from "@mui/material";
import { CampaingBuilderGoToPrevPageBtn } from "./CampaingBuilderGoToPrevPageBtn";
import { CampaignBuilderSideNavCloseBtn } from "./CampaignBuilderSideNavCloseBtn";
import { useTranslation } from "react-i18next";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { useCreatePropertyMutation } from "../../store/api/campaignBuilderApiSlice";
import { useSelector } from "react-redux";
import { calculateCampaignCloseDate } from "../../helpers/formatter";
import {
  convertDataToCreateProperty,
  extractIds,
  handleUploadFiles,
} from "../../helpers/campaignConvert";
import { useUploadImgMutation } from "../../store/api/agentApiSlice";

export enum CampaignCreatingStatus {
  PUBLISH = "publish",
  DRAFT = "draft",
}
export const CampaignSetUpStep = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const {
    countOfTimePeriods,
    timePeriod,
    propertyTitle,
    propertyDescription,
    propertyStatus,
    currency,
    propertyPrice,
    propertyLocation,
    propertyType,
    aboutPropertyDesc,
    aboutDeveloper,
    keyFeaturesBack,
    hightlightsBack,
    selectedUserId,
    labelBack,
    imgFilesSlice,
    brochureFilesSlice,
    floorPlanFilesSlice,
    videoFilesSlice,
  } = useSelector((state: any) => state.campaignBuilder);

  const [createProperty] = useCreatePropertyMutation();
  const [uploadImg] = useUploadImgMutation();

  const handlePublishCampaign = async () => {
    try {
      let imgIds = extractIds(imgFilesSlice);
      let brochureIds = extractIds(brochureFilesSlice);
      let floorplanIds = extractIds(floorPlanFilesSlice);
      let videoIds = extractIds(videoFilesSlice);

      const propertyData = convertDataToCreateProperty(
        countOfTimePeriods,
        timePeriod,
        propertyTitle,
        propertyDescription,
        propertyStatus,
        currency,
        propertyPrice,
        propertyLocation,
        propertyType,
        aboutPropertyDesc,
        aboutDeveloper,
        keyFeaturesBack,
        hightlightsBack,
        selectedUserId,
        labelBack,
        imgIds,
        brochureIds,
        floorplanIds,
        videoIds,
        CampaignCreatingStatus.PUBLISH
      );

      const response = await createProperty({ propertyData }).unwrap();
      console.log("log: response", response);
    } catch (e) {
      console.log("log: ", e);
    }
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="campaign_set_up"
        leftBtn={<CampaingBuilderGoToPrevPageBtn />}
        rightBtn={<CampaignBuilderSideNavCloseBtn />}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          height: "100%",
        }}
      >
        <div
          className="campaign-builder-sidenav-main-content-set-up"
          style={{
            paddingTop: downMd ? "30px" : "40px",
            height: "100%",
          }}
        >
          <CampaignSetupItem
            title="settings"
            icon={SettingsIcon}
            handleOpenStep={() => setCurrentStep(CampaignSideNavStep.settings)}
          />
          <CampaignSetupItem
            title="main_information"
            icon={MainInfoIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.mainInformation)
            }
          />
          <CampaignSetupItem
            title="media"
            icon={MediaIcon}
            handleOpenStep={() => setCurrentStep(CampaignSideNavStep.media)}
          />
          <CampaignSetupItem
            title="labels"
            icon={LabelIcon}
            handleOpenStep={() => setCurrentStep(CampaignSideNavStep.labels)}
          />
          <CampaignSetupItem
            title="investment_highlights"
            icon={InvestmentHighlightsIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.investmentHighlights)
            }
          />
          <CampaignSetupItem
            title="about_property"
            icon={AboutPropertyIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.aboutProperty)
            }
          />
          <CampaignSetupItem
            title="key_features"
            icon={KeyFeatureIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.keyFeatures)
            }
          />
          <CampaignSetupItem
            title="about_developer"
            icon={AboutDeveloperIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.aboutDeveloper)
            }
          />
          <CampaignSetupItem
            title="contact_information"
            icon={ContactIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.contactInformation)
            }
          />
        </div>

        <div className="campaign-builder-sidenav-footer-btns-container">
          <div
            className="campaign-builder-sidenav-white-btn"
            onClick={() => handlePublishCampaign()}
          >
            <div>{t("publish_the_campaign")}</div>
          </div>

          <div className="campaign-builder-sidenav-blue-btn">
            <div>{t("save_as_draft")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
