import React from "react";
import FeceboonkIcon from "../../assets/socialMedia/facebook.svg";
import InstagramIcon from "../../assets/socialMedia/inst.svg";
import Linkedin from "../../assets/socialMedia/linkedin.svg";
import Youtube from "../../assets/socialMedia/youtube.svg";
import PinterstIcon from "../../assets/socialMedia/pinterest.svg";
import TTIcon from "../../assets/socialMedia/tt.svg";
import WhatupIcon from "../../assets/socialMedia/whatup.svg";
import { useTranslation } from "react-i18next";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const FooterSocialMediaLine = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const langTheme = useLanguageTheme();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const isXXL = useMediaQuery("(min-width: 2360px)");

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  if (downMd) return null;
  return (
    <div
      style={{
        backgroundColor: "rgba(232, 239, 242, 1)",
        width: "100%",
        paddingLeft: isXXL ? "100px" : "20px",
        paddingRight: isXXL ? "100px" : "20px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="xxl-width"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          height: "52px",
          alignItems: "center",
          direction: i18n.dir(),
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          {isRTL ? (
            ""
          ) : (
            <Typography
              sx={{
                color: "rgba(3, 74, 115, 1)",
                fontWeight: 700,
                fontFamily: langTheme.mainFont(),
                fontSize: "12px",
              }}
            >
              {t("footer_coop")}
            </Typography>
          )}

          <Typography
            sx={{
              color: "rgba(3, 74, 115, 1)",
              fontWeight: 400,
              fontFamily: langTheme.mainFont(),
              fontSize: "12px",
            }}
          >
            {t("all_rights")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "19px",
            direction: "ltr",
          }}
        >
          <img src={FeceboonkIcon} />
          <img src={InstagramIcon} />
          <img src={Linkedin} />
          <img src={Youtube} />
          <img src={PinterstIcon} />
          <img src={TTIcon} />
          <img src={WhatupIcon} />
        </div>
      </div>
    </div>
  );
};
