export const countryCodes = [
  {
    name: "Israel",
    code: "+972",
    flag: "il",
  },
  {
    name: "United States",
    code: "+1",
    flag: "us",
  },
  {
    name: "Canada",
    code: "+1",
    flag: "ca",
  },
  {
    name: "Mexico",
    code: "+52",
    flag: "mx",
  },
  {
    name: "Brazil",
    code: "+55",
    flag: "br",
  },
  {
    name: "Argentina",
    code: "+54",
    flag: "ar",
  },
  {
    name: "Germany",
    code: "+49",
    flag: "de",
  },
  {
    name: "United Kingdom",
    code: "+44",
    flag: "gb",
  },
  {
    name: "France",
    code: "+33",
    flag: "fr",
  },
  {
    name: "Italy",
    code: "+39",
    flag: "it",
  },
  {
    name: "Spain",
    code: "+34",
    flag: "es",
  },

  {
    name: "China",
    code: "+86",
    flag: "cn",
  },
  {
    name: "Japan",
    code: "+81",
    flag: "jp",
  },
  {
    name: "India",
    code: "+91",
    flag: "in",
  },
  {
    name: "South Korea",
    code: "+82",
    flag: "kr",
  },
  {
    name: "Australia",
    code: "+61",
    flag: "au",
  },
  {
    name: "Russia",
    code: "+7",
    flag: "ru",
  },
  {
    name: "Turkey",
    code: "+90",
    flag: "tr",
  },
  {
    name: "South Africa",
    code: "+27",
    flag: "za",
  },
  {
    name: "Netherlands",
    code: "+31",
    flag: "nl",
  },
  {
    name: "Switzerland",
    code: "+41",
    flag: "ch",
  },
  {
    name: "Sweden",
    code: "+46",
    flag: "se",
  },
  {
    name: "Poland",
    code: "+48",
    flag: "pl",
  },
  {
    name: "Norway",
    code: "+47",
    flag: "no",
  },
  {
    name: "Austria",
    code: "+43",
    flag: "at",
  },
  {
    name: "Belgium",
    code: "+32",
    flag: "be",
  },
  {
    name: "Denmark",
    code: "+45",
    flag: "dk",
  },
  {
    name: "Ireland",
    code: "+353",
    flag: "ie",
  },
  {
    name: "Finland",
    code: "+358",
    flag: "fi",
  },
  {
    name: "Portugal",
    code: "+351",
    flag: "pt",
  },
  {
    name: "Czech Republic",
    code: "+420",
    flag: "cz",
  },
  {
    name: "Greece",
    code: "+30",
    flag: "gr",
  },
  {
    name: "Hungary",
    code: "+36",
    flag: "hu",
  },
  {
    name: "Malaysia",
    code: "+60",
    flag: "my",
  },
  {
    name: "Indonesia",
    code: "+62",
    flag: "id",
  },
  {
    name: "Philippines",
    code: "+63",
    flag: "ph",
  },
  {
    name: "Thailand",
    code: "+66",
    flag: "th",
  },
  {
    name: "Vietnam",
    code: "+84",
    flag: "vn",
  },
  {
    name: "Saudi Arabia",
    code: "+966",
    flag: "sa",
  },
  {
    name: "United Arab Emirates",
    code: "+971",
    flag: "ae",
  },
  {
    name: "Chile",
    code: "+56",
    flag: "cl",
  },
  {
    name: "Colombia",
    code: "+57",
    flag: "co",
  },
  {
    name: "Peru",
    code: "+51",
    flag: "pe",
  },
  {
    name: "New Zealand",
    code: "+64",
    flag: "nz",
  },
  {
    name: "Singapore",
    code: "+65",
    flag: "sg",
  },
  {
    name: "Pakistan",
    code: "+92",
    flag: "pk",
  },
  {
    name: "Bangladesh",
    code: "+880",
    flag: "bd",
  },
];
