import { CircularProgress } from "@mui/material";
import React from "react";

export const FilesLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CircularProgress sx={{ color: "#094f77d1" }} />
        </div>
      )}
    </>
  );
};
