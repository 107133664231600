import React, { Dispatch, SetStateAction } from "react";
import {
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ReactComponent as SelectArrow } from "../../assets/icons/select_arrow_black.svg";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export enum UserType {
  HomeBuyer = "HomeBuyer",
  PropertyInvestor = "PropertyInvestor",
  SalesAgent = "SalesAgent",
}

export const UserTypeSelect = ({
  userType,
  setUserType,
}: {
  userType: UserType;
  setUserType: Dispatch<SetStateAction<UserType>>;
}) => {
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const { t } = useTranslation(["signin"]);
  const langTheme = useLanguageTheme();

  const handleChange = (event: SelectChangeEvent<UserType>) => {
    setUserType(event.target.value as UserType);
  };

  return (
    <Select
      fullWidth
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(255, 255, 255, 1)",
            },
          },
        },
      }}
      sx={{
        direction: i18n.dir(),
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "16px",
        fontWeight: 500,
        color: "rgba(43, 54, 116, 1)",
        fontFamily: langTheme.mainFont(),

        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid rgba(224, 229, 242, 1)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid rgba(224, 229, 242, 1)",
        },
        "& .MuiOutlinedInput-input": {
          padding: "13.5px 17px",
        },
        mb: "15px",
        fontSize: "14px",
      }}
      value={userType}
      onChange={handleChange}
      IconComponent={SelectArrow}
    >
      {Object.keys(UserType).map((type) => (
        <MenuItem
          key={type}
          value={type}
          sx={{
            color: "rgba(43, 54, 116, 1)",
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {t(type)}
        </MenuItem>
      ))}
    </Select>
  );
};
