import React from "react";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDropZone } from "./FileDropZone";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { useAppDispatch } from "../../store/store";
import { setImgFilesTest } from "../../store/features/campaignBuilderSlice";
import { useSelector } from "react-redux";

export type ImageFormatMap = {
  [key: string]: string[];
};
export const propertyImgAcceptFormat = {
  "image/png": [],
  "image/jpeg": [],
};

const brochureAcceptFormat = {
  "application/pdf": [],
  "image/png": [],
  "image/jpeg": [],
};

export const videoAcceptFormat = {
  "video/mp4": [],
  "video/quicktime": [],
};

export enum FileType {
  IMG = "img",
  PDF = "pdf",
  VIDEO = "video",
  FLOOR_PLAN = "floor_plan",
  AGET_AVATAR = "agent_avatar",
}

export const MAXIMUM_NUMBER_OF_PROPERTY_IMG = 10;
export const MAXIMUM_NUMBER_OF_BROCHURE = 1;
export const MAXIMUM_NUMBER_OF_FLOOR_PLAN = 1;
export const MAXIMUM_NUMBER_OF_AGENT_AVATAR = 1;

export const CampaignMediaStep = ({
  setCurrentStep,
  imgFiles,
  setImgFiles,
  brochureFiles,
  setBrochureFiles,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  imgFiles: File[];
  setImgFiles: React.Dispatch<React.SetStateAction<File[]>>;
  brochureFiles: File[];
  setBrochureFiles: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleNext = (step: CampaignSideNavStep) => {
    //TODO add validations
    setCurrentStep(step);
  };

  // React.useEffect(() => {
  //   dispatch(setImgFilesTest(imgFiles));
  // }, [imgFiles]);

  // const { imgFilesTest } = useSelector((state: any) => state.campaignBuilder);

  // console.log("log: imgFilesTest", imgFilesTest);

  // dispatch()se
  return (
    <div className="campaign-builder-side-nav-wrapper">
      {/* {imgFilesTest.map((item: any, index: any) => (
        <img
          src={imgFilesTest[index]?.preview}
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      ))} */}

      <CampaignBuilderSideNavHeader
        header="media"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.mainInformation}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.labels}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name-main">
              {t("property_images")}
            </div>

            <FileDropZone
              fileAcceptFormat={propertyImgAcceptFormat}
              limit={MAXIMUM_NUMBER_OF_PROPERTY_IMG}
              files={imgFiles}
              setFiles={setImgFiles}
              type={FileType.IMG}
              placeholder={"placeholder_upload_property_img"}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name-main">
              {t("attachments")}
            </div>

            <FileDropZone
              fileAcceptFormat={brochureAcceptFormat}
              limit={MAXIMUM_NUMBER_OF_BROCHURE}
              files={brochureFiles}
              setFiles={setBrochureFiles}
              type={FileType.PDF}
              placeholder={"placeholder_upload_property_brochure"}
            />
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
