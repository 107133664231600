import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSwitch = styled(Switch)(() => ({
  width: "46px",
  height: "25px",
  padding: 3,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "rgba(255, 255, 255, 1)",
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(255, 255, 255, 1)",
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: "12px",
    height: "14px",
    width: "34px",
    backgroundColor: "#bdbdbd",
    opacity: 1,
  },
  "& .MuiSwitch-thumb": {
    width: "20px",
    height: "20px",
  },
}));

export const CampaignBuilderSwitcher = ({
  open,
  handleChangeOpen,
}: {
  open: boolean;
  handleChangeOpen: any;
}) => {
  return (
    <div>
      <CustomSwitch checked={open} onChange={handleChangeOpen} />
    </div>
  );
};
