import { Property } from "./convert";

import GreenStampLtr from "../assets/greenStamp.png";
import GreenStampRtl from "../assets/GreenStampRtl.png";

import BlueStampLtr from "../assets/blueStamp.png";
import BlueStampRtl from "../assets/BlueStampRtl.png";

import OrangeStampLtr from "../assets/orangeStamp.png";
import OrangeStampRtl from "../assets/OrangeStampRtl.png";

import EcoIcon from "../assets/icons/eco.svg";
import HotIcon from "../assets/icons/hot.svg";
import PriceIcon from "../assets/icons/priceStamp.svg";
import { TimePeriod } from "../components/campaignBuilder/CampaignBuilderSideNav";
import { MediaType } from "../components/campaignBuilder/MediaUploadPanel";

export const convertToPoints = (properties: Property[]) => {
  return properties.map((item) => ({
    id: item.id,
    latitude: item.location.coordinates.latitude,
    longitude: item.location.coordinates.longitude,
    title: item.name,
    basePrice: item.pricing.basePrice,
    location: {
      city: item.location.city,
      address: item.location.address,
      coordinates: {
        latitude: item.location.coordinates.latitude,
        longitude: item.location.coordinates.longitude,
      },
    },
    mainImg: { url: item.mainImage.url },
  }));
};

export function formatPrice(price: number) {
  const priceInMillions = Math.floor(price / 1000000);
  return `${priceInMillions}M`;
}

export interface IStamp {
  name: string;
  imgLtr: string;
  imgRtl: string;
  icon: string;
}

const stamps = [
  {
    name: "eco_friendly",
    imgLtr: GreenStampLtr,
    imgRtl: GreenStampRtl,
    icon: EcoIcon,
  },
  {
    name: "hot_price",
    imgLtr: BlueStampLtr,
    imgRtl: BlueStampRtl,
    icon: HotIcon,
  },
  {
    name: "sold",
    imgLtr: OrangeStampLtr,
    imgRtl: OrangeStampRtl,

    icon: PriceIcon,
  },
];

export const getRandomStamp = () => {
  const randomIndex = Math.floor(Math.random() * stamps.length);
  return stamps[randomIndex];
};

export function calculateCampaignCloseDate(
  number: string,
  periodType: TimePeriod
): string {
  const currentDate = new Date();

  switch (periodType) {
    case TimePeriod.DAYS:
      currentDate.setDate(currentDate.getDate() + Number(number));
      break;
    case TimePeriod.WEEKS:
      currentDate.setDate(currentDate.getDate() + Number(number) * 7);
      break;
    case TimePeriod.MONTHS:
      currentDate.setMonth(currentDate.getMonth() + Number(number));
      break;
    default:
      throw new Error("Invalid period type");
  }

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const findOptionById = (selectedId: string, options: any[]) => {
  const option = options.find((label: any) => label.id === selectedId);

  return option;
};

function diffInDays(from: Date, to: Date): number {
  const diffMs = to.getTime() - from.getTime();
  return Math.ceil(diffMs / (1000 * 60 * 60 * 24));
}
export interface TimePeriodResult {
  countOfTimePeriodsValue: string;
  timePeriodValue: TimePeriod;
}

export function convertDateToTimePeriods(dateStr: string): TimePeriodResult {
  const today = new Date();
  const targetDate = new Date(dateStr);

  const daysDifference = diffInDays(today, targetDate);

  if (daysDifference < 0) {
    return {
      countOfTimePeriodsValue: "0",
      timePeriodValue: TimePeriod.DAYS,
    };
  }

  if (daysDifference < 7) {
    return {
      countOfTimePeriodsValue: daysDifference.toString(),
      timePeriodValue: TimePeriod.DAYS,
    };
  } else if (daysDifference < 30) {
    const weeks = Math.ceil(daysDifference / 7);
    return {
      countOfTimePeriodsValue: weeks.toString(),
      timePeriodValue: TimePeriod.WEEKS,
    };
  } else {
    const months = Math.ceil(daysDifference / 30);
    return {
      countOfTimePeriodsValue: months.toString(),
      timePeriodValue: TimePeriod.MONTHS,
    };
  }
}

export function truncateString(str: string, maxLength: number) {
  const ellipsis = "...";
  if (str.length > maxLength) {
    return str.slice(0, maxLength - ellipsis.length) + ellipsis;
  }
  return str;
}
