import { useState, useEffect } from 'react';
import { useGetUserWithPictureQuery } from '../store/api/authApiSlice';

export function useUserProfile() {
  const [profile, setProfile] = useState(null);
  const jwt = (
    localStorage.getItem('jwt') || sessionStorage.getItem('jwt')
  )?.replace(/"/g, '');
  const userString =
    localStorage.getItem('user') || sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  const {
    data: userWithPicture,
    isLoading,
    error,
  } = useGetUserWithPictureQuery(
    {
      id: user?.id,
      jwt,
    },
    {
      skip: !(jwt && user?.id),
    },
  );

  useEffect(() => {
    if (userWithPicture) {
      setProfile(userWithPicture);
    } else {
      setProfile(null);
    }
  }, [userWithPicture]);

  if (isLoading) return { profile: null, isLoading: true, error: null };
  if (error) return { profile: null, isLoading: false, error: error };
  return { profile, isLoading: false, error: null };
}
