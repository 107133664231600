import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import ForBuyersImg from "../../../assets/for_buyers.png";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";

export const ForBuyersUpMdImgBlock = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
const langTheme = useLanguageTheme()

  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <>
      {isRTL ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-start",
              fontFamily: langTheme.mainFont(),
            }}
          >
            <div
              className="text-for-buyer-rtl"
              style={{
                maxWidth: "249px",
                paddingLeft: upLg ? "20px" : "15px",
              }}
            >
              <Typography variant="h4" className="for-buyers-black-text">
                {t("for_buyers_text1")}
              </Typography>{" "}
            </div>

            <div
              className="text-for-buyer-rtl"
              style={{
                maxWidth: "289px",
                paddingLeft: upLg ? "20px" : "15px",
              }}
            >
              <Typography variant="h4" className="for-buyers-black-text">
                {t("for_buyers_text2")}
              </Typography>{" "}
            </div>

            <div
              className="text-for-buyer-rtl"
              style={{
                maxWidth: "252px",
                paddingLeft: upLg ? "20px" : "15px",
              }}
            >
              <Typography variant="h4" className="for-buyers-black-text">
                {t("for_buyers_text3")}
              </Typography>{" "}
            </div>

            <div
              className="text-for-buyer-rtl"
              style={{
                maxWidth: "278px",
                paddingLeft: upLg ? "25px" : "15px",
              }}
            >
              <Typography variant="h4" className="for-buyers-black-text">
                {t("for_buyers_text4")}
              </Typography>{" "}
            </div>
          </div>

          <div
            className="for-buyers-img"
            style={{
              zIndex: 1,
              background: `url(${ForBuyersImg}) center center / cover no-repeat`,
              maxWidth: "332px",
              width: "100%",
              height: "592px",
              borderRadius: "20px",
            }}
          ></div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-end",
            }}
          >
            <div
              className="text-for-buyer-ltr"
              style={{
                maxWidth: "249px",
                paddingRight: upLg ? "20px" : "10px",
                fontFamily: langTheme.mainFont(),
              }}
            >
              <Typography variant="h4" className="for-buyers-black-text">
                {t("curated_selection_of")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-blue-text">
                {t("luxury")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-blue-text">
                {t("properties")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-black-text">
                {t("in_prime_locations")}
              </Typography>
            </div>

            <div
              className="text-for-buyer-ltr"
              style={{
                maxWidth: "289px",
                fontFamily: langTheme.mainFont(),
              }}
            >
              <Typography variant="h4" className="for-buyers-blue-text">
                {t("personalized_property")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-black-text">
                {t("matching_based_on")}
              </Typography>{" "}
            </div>

            <div
              className="text-for-buyer-ltr"
              style={{
                maxWidth: "252px",
                fontFamily: langTheme.mainFont(),
              }}
            >
              <Typography variant="h4" className="for-buyers-blue-text">
                {t("expert_guidance")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-black-text">
                {t("throughout_your_investment_journey")}
              </Typography>{" "}
            </div>

            <div
              className="text-for-buyer-ltr"
              style={{
                maxWidth: "278px",
                fontFamily: langTheme.mainFont(),
              }}
            >
              <Typography variant="h4" className="for-buyers-black-text">
                {t("exclusive_access_to")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-blue-text">
                {t("pre_launch_projects")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-black-text">
                {t("and")}
              </Typography>{" "}
              <Typography variant="h4" className="for-buyers-blue-text">
                {t("VIP_events")}
              </Typography>{" "}
            </div>
          </div>

          <div
            className="for-buyers-img"
            style={{
              zIndex: 1,
              background: `url(${ForBuyersImg}) center center / cover no-repeat`,
              maxWidth: "332px",
              width: "100%",
              height: "592px",
              borderRadius: "20px",
            }}
          ></div>
        </div>
      )}
    </>
  );
};
