import { SelectChangeEvent, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  setCurrency,
  setLanguage,
} from "../../store/features/campaignBuilderSlice";
import { useAppDispatch } from "../../store/store";
import { BackToMenuBtn } from "./BackToMenuBtn";
import { CampaignBuilderDurationSelect } from "./CampaignBuilderDurationSelect";
import {
  CampaignSideNavStep,
  Currency,
  Language
} from "./CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "./CampaignBuilderSideNavHeader";
import { CampainBuilderBackBtn } from "./CampainBuilderBackBtn";
import { CampainBuilderNextBtn } from "./CampainBuilderNextBtn";
import { CampaingBuilderSelect } from "./CampaingBuilderSelect";

export const CampaignSettitngsStep = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["campaignBuilder"]);
  const { i18n } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { language, currency } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const handleChangeLanguage = (event: SelectChangeEvent<Language>) => {
    dispatch(setLanguage(event.target.value as Language));
  };

  const handleChangeCurrency = (event: SelectChangeEvent<Currency>) => {
    dispatch(setCurrency(event.target.value as Currency));
  };

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="settings"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.campaignSetUp}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.mainInformation}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("language")}
            </div>
            <CampaingBuilderSelect
              value={language}
              onChangeValue={handleChangeLanguage}
              options={Object.values(Language)}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("currency")}
            </div>
            <CampaingBuilderSelect
              value={currency}
              onChangeValue={handleChangeCurrency}
              options={Object.values(Currency)}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("campaign_duration")}
            </div>
            <CampaignBuilderDurationSelect />
          </div>
        </div>

        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
