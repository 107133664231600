import React from "react";
import { CampaignSideNavStep } from "./CampaignBuilderSideNav";
import { useTranslation } from "react-i18next";
import NextIcon from "../../assets/campaignBuilder/next_btn.svg";

export const CampainBuilderNextBtn = ({
  handleNext,
  step,
}: {
  handleNext: (step: CampaignSideNavStep) => void;
  step: CampaignSideNavStep;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);

  return (
    <div
      className="campaign-builder-sidenav-back"
      onClick={() => handleNext(step)}
    >
      <div className="campaign-builder-sidenav-back-text">{t("next")}</div>
      <div>
        <img src={NextIcon} />
      </div>
    </div>
  );
};
