import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  HighlightIconMap,
  HightlightIconKey,
} from "../../constants/campaignBuilderData";

export interface Option {
  id: string;
  icon_key: HightlightIconKey;
  labelName: string;
}
export const InvestmentHighlightsBlock = () => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const { hightlightsBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  return (
    <div className="campaign-builder-investment-highlights-block">
      <div className="campaign-builder-main-header ">
        {t("investment_highlights")}
      </div>
      <div className="campaign-builder-investment-highlights-items-block">
        {hightlightsBack.map((option: Option) => {
          const IconComponent = HighlightIconMap[option.icon_key];

          return (
            <div
              key={option.id}
              className="campaign-builder-investment-highlights-item"
            >
              <IconComponent
                isSelected={false}
                width={30}
                height={30}
                backgroundColor={"#034A73"}
              />
              <div>{t(option.labelName)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
