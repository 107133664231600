import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";

import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { useFormattedSocialProof } from "../../../hooks/useFormattedSocialProof";

export const HeroImgStatisticsDownMd = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();
  const { activeInvestors, successfulProjects, formattedSalesAmount } =
    useFormattedSocialProof();

  const statisticsData = [
    { text: t("active") + " " + t("investors"), value: activeInvestors + "+" },
    {
      text: t("successful") + " " + t("projects"),
      value: successfulProjects + "+",
    },
    {
      text: t("in_re") + " " + t("sales"),
      value: `${isRTL ? "₪" : "$"} ${formattedSalesAmount}`,
    },
  ];

  return (
    <div
      className="hero-img-statistics-container-down-md"
      style={{
        direction: i18n.dir(),
      }}
    >
      <Swiper
        allowTouchMove={true}
        loop={true}
        slidesPerView={1}
        speed={1000}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: false,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {statisticsData.map((el, i) => (
          <SwiperSlide key={i}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "rgba(255, 255, 255, 1)",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 1)",
                  backdropFilter: "blur(30px)",
                  borderRadius: "44.5px",
                  backgroundColor: "rgba(255, 255, 255, 0.21)",
                  width: "54px",
                  height: "54px",
                  fontWeight: 700,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                }}
              >
                {el.value}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "44.5px",
                  fontSize: "18px",
                  fontFamily: langTheme.mainFont(),
                  fontWeight: 600,
                }}
              >
                {el.text}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
