import React, { useEffect, useRef } from "react";
import "./App.scss";
import { Navigate, Route, Routes, Outlet, useLocation } from "react-router-dom";
import { Layout } from "./components/_common/Layout";
import { ThemeProvider } from "@emotion/react";
import { Home } from "./pages/Home";
import { Portfolio } from "./pages/Portfolio";
import { muiTheme } from "./theme/create-components";
import { DemoLogin } from "./pages/DemoLogin";
import { SignIn } from "./pages/SignIn";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Signup } from "./pages/Signup";
import { RedirectWithGoogle } from "./pages/RedirectWithGoogle";
import { EmailConfirmation } from "./pages/EmailConfirmation";
import { CampaignBuilder } from "./pages/CampaignBuilder";
import { FinishGoogle } from "./pages/FinishGoogle";
import { persistor, useAppDispatch } from "./store/store";
import { resetCampaignBuilder } from "./store/features/campaignBuilderSlice";

function PrivateRoute() {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  if (!isAuthenticated) {
    localStorage.removeItem("isAuthenticated");

    return <Navigate to="/demo-login" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const prevPathname = sessionStorage.getItem("prevPathname");

    if (prevPathname && prevPathname !== location.pathname) {
      persistor.purge();
      dispatch(resetCampaignBuilder());
      localStorage.removeItem("currentStep");
    }

    sessionStorage.setItem("prevPathname", location.pathname);
  }, [location.pathname]);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className="App">
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              <Route path="/home" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route
                path="/campain-page-builder/:documentId?"
                element={<CampaignBuilder />}
              />
            </Route>
          </Route>

          <Route>
            <Route path="/demo-login" element={<DemoLogin />} />
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/confirm-email/:email"
              element={<EmailConfirmation />}
            />

            <Route
              path="/connect/google/redirect"
              element={<RedirectWithGoogle />}
            />
            <Route path="/connect/google/finish" element={<FinishGoogle />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
